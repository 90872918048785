.type-d {
	font-family: var(--font-nib);
	font-size: 1.25rem;
	font-weight: var(--font-weight-semibold);
	line-height: 1.4;
}

@media (min-width: 1024px) {
	.type-d {
		font-size: 1.375rem;
		line-height: 1.45;
	}
}

@media (min-width: 1440px) {
	.type-d {
		font-size: 1.5rem;
		line-height: 1.5;
	}
}

.type-d b,
.type-d strong {
	font-family: var(--font-sans);
	font-weight: var(--font-weight-medium);
}

.type-d a:not([class]) {
	padding-bottom: 1px;
}

@media (min-width: 1024px) {
	.type-d a:not([class]) {
		border-bottom-width: 2px;
	}
}

@media (min-width: 1440px) {
	.type-d a:not([class]) {
		padding-bottom: 2px;
	}
}
