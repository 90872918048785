.l-text-lists {
	overflow: hidden;
	position: relative;
	width: 100%;
}

@media (min-width: 1024px) {
	.l-text-lists {
		display: flex;
		align-items: flex-start;
	}
}

@media (min-width: 1024px) {
	.l-text-lists__main {
		flex-basis: calc(66.6666% - var(--space-x-small));
		flex-grow: 0;
		flex-shrink: 0;
		margin-right: var(--space-x-small);
	}
}

@media (max-width: 767px) {
	.l-text-lists__main:not(:last-child) {
		border-bottom: 1px solid var(--color-alpha-foreground-25);
		margin-bottom: var(--space-small);
		padding-bottom: var(--space-small);
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.l-text-lists__main:not(:last-child) {
		margin-bottom: var(--space-medium);
	}
}

@media (min-width: 1024px) {
	.l-text-lists__aside {
		flex-basis: auto;
		flex-grow: 1;
		flex-shrink: 1;
	}
}
