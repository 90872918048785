.blocked-content {
	animation-delay: 128ms;
	animation-direction: normal;
	animation-duration: 128ms;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-name: FADE_IN;
	animation-timing-function: linear;
	display: block;
	opacity: 0;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.blocked-content *[hidden] {
	display: none;
}

.blocked-content[data-aspect-ratio="16:9"] {
	padding-bottom: 56.25%;
}

@media (max-width: 380px) {
	.blocked-content[data-aspect-ratio="16:9"][data-state="blocked"] {
		min-height: 440px;
	}
}

@media (min-width: 380px) and (max-width: 440px) {
	.blocked-content[data-aspect-ratio="16:9"][data-state="blocked"] {
		min-height: 380px;
	}
}

@media (min-width: 440px) and (max-width: 767px) {
	.blocked-content[data-aspect-ratio="16:9"][data-state="blocked"] {
		min-height: 260px;
	}
}

.blocked-content__notice {
	background-color: var(--color-foreground-x-light);
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	padding: var(--space-small) var(--space-x-small);
	justify-content: center;
	align-items: center;
	text-align: center;
	min-height: 100%;
}

@media (min-width: 768px) {
	.blocked-content__notice {
		padding-right: var(--space-small);
		padding-left: var(--space-small);
	}
}

@media (min-width: 1024px) {
	.blocked-content__notice {
		padding: var(--space-medium);
	}
}

/* If no JS is available, only the fallback should show */

.no-js *:not(noscript) > .blocked-content__notice {
	display: none; /* will hide from screen readers too */
}

*[class*="theme-"] .blocked-content__notice {
	background-color: inherit;
	color: inherit;
}

.blocked-content[data-aspect-ratio]:not([data-aspect-ratio="auto"]) .blocked-content__notice {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.blocked-content__notice__icon {
	display: inline-block;
}

.blocked-content__notice__icon .icon {
	fill: currentColor;
	width: 1.75rem;
	height: 1.75rem;
}

@keyframes FADE_IN {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
