.chapter-header {
	appearance: none;
	background: none;
	border: 0;
	box-shadow: none;
	cursor: pointer;
	display: flex;
	padding: 0;
	justify-content: flex-start;
	align-items: flex-start;
	text-align: left;
	width: 100%;
}

@media (max-width: 1279px) {
	.chapter-header {
		flex-wrap: wrap;
	}
}

.chapter-header:focus:not(:focus-visible) {
	outline: 0;
}

.chapter-header > * {
	pointer-events: none;
}

.chapter-header__title-container {
	flex-basis: 100%;
	flex-shrink: 0;
	margin-bottom: 0;
}

@media (min-width: 768px) and (max-width: 1279px) {
	.chapter-header__title-container {
		padding-right: var(--space-x-large);
	}
}

@media (max-width: 1279px) {
	.chapter-header__title-container {
		margin-bottom: 0.5rem;
	}
}

@media (min-width: 1280px) {
	.chapter-header__title-container {
		flex-basis: auto;
		flex-grow: 1;
		margin-right: var(--space-small);
	}
}

.chapter-header__title__box {
	display: inline-block;
}

.chapter-header__title {
	text-transform: none;
	width: 100%;
	max-width: 562px;
}

@media (min-width: 1280px) {
	.chapter-header__progress,
	.chapter-header__toggle-icon-container {
		transform: translateY(0.375rem);
	}
}

.chapter-header__progress {
	flex-basis: 13.1875rem;
	flex-shrink: 0;
	margin-right: var(--space-xx-small);
}

@media (min-width: 1280px) {
	.chapter-header__progress {
		flex-basis: 19.5rem;
		margin-right: 7.5rem;
	}
}

@media (max-width: 1279px) {
	.chapter-header__toggle-icon-container {
		flex-grow: 1;
		text-align: right;
	}
}

.chapter-header__toggle-icon {
	background-color: var(--color-foreground);
	border-radius: 100%;
	color: var(--color-background);
	display: inline-block;
	position: relative;
	transition: transform 320ms cubic-bezier(0.65, 0, 0.1, 1);
	width: 2.25rem;
	height: 2.25rem;
}

@media (min-width: 1440px) {
	.chapter-header__toggle-icon {
		width: 3.125rem;
		height: 3.125rem;
	}
}

.checklist__chapter[data-toggled] .chapter-header__toggle-icon {
	transform: rotate(180deg);
}

.chapter-header__toggle-icon > span {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.chapter-header__toggle-icon .icon {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) translateY(1px);
	width: 33.3333%;
	height: 33.3333%;
}
