@media (min-width: 768px) {
	.entity-contact {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
	}
}

@media (min-width: 768px) {
	.entity-contact__content {
		flex-basis: auto;
		flex-grow: 1;
		flex-shrink: 1;
	}
}

@media (max-width: 767px) {
	.entity-contact__content:not(:last-child) {
		margin-bottom: var(--space-x-small);
	}
}

.entity-contact__content__title__box {
	margin-bottom: var(--space-xx-small);
}

@media (min-width: 1024px) {
	.entity-contact__content__title__box {
		margin-bottom: var(--space-x-small);
	}
}

.entity-contact__content__title {
	font-family: var(--font-sans);
	font-size: 1.25rem;
	font-weight: var(--font-weight-medium);
	line-height: 1.5;
	text-transform: none;
}

.entity-contact__content__item {
	font-family: var(--font-sans);
	font-weight: 400;
	line-height: 1.78;
	margin-bottom: 0;
}

.entity-contact__thumbnail {
	display: block;
	padding-bottom: 0.125rem;
	width: 100%;
	height: auto;
}

@media (min-width: 768px) {
	.entity-contact__thumbnail {
		flex-basis: 160px;
		flex-grow: 0;
		flex-shrink: 0;
		margin-left: var(--space-x-small);
		width: 160px;
	}
}
