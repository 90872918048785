.spotlight-row {
	overflow: hidden;
	position: relative;
	width: 100%;
}

.spotlight-row__background-container {
	background-color: var(--color-alpha-foreground-25);
	padding-bottom: 56.25%; /* 16:9 */
	position: relative;
	width: 100%;
	z-index: 1;
}

@media (min-width: 768px) {
	.spotlight-row__background-container {
		padding-bottom: 42.8571%; /* 21:9 */
	}
}

.spotlight-row__background {
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
}

@supports (object-fit: cover) {
	.spotlight-row__background {
		object-fit: cover;
		height: 100%;
	}
}

.spotlight-row__link {
	background-color: var(--color-background);
	box-sizing: border-box;
	color: var(--color-foreground);
	display: block;
	margin-top: calc(var(--space-medium) * -1);
	margin-right: auto;
	margin-left: auto;
	padding: var(--space-medium) 0;
	position: relative;
	text-align: center;
	text-decoration: none;
	width: calc(100% - 2rem); /* Full width - wrapper padding */
	z-index: 2;
}

@media (min-width: 768px) {
	.spotlight-row__link {
		margin-top: -3rem;
		padding: 3rem;
		width: calc(83.3333% - 3.75rem - 2rem); /* width - outside padding - gaps */
	}
}

@media (min-width: 1024px) {
	.spotlight-row__link {
		margin-top: -4rem;
		padding-top: 4rem;
		padding-bottom: 4rem;
		width: calc(60% - 3.75rem - 2rem);
	}
}

@media (min-width: 1280px) {
	.spotlight-row__link {
		padding-right: var(--space-x-large);
		padding-left: var(--space-x-large);
		width: calc(66.6666% - 3.75rem - 2rem);
	}
}

@media (min-width: 1440px) {
	.spotlight-row__link {
		margin-top: calc(var(--space-x-large) * -1);
		padding: var(--space-x-large) 7rem;
		width: 872px;
	}
}

.spotlight-row__link:first-child {
	margin-top: 0;
}

.spotlight-row__title:not(:last-child),
.spotlight-row__meta:first-child:not(:last-child) {
	margin-bottom: 0.75rem;
}

@media (min-width: 1024px) {
	.spotlight-row__title:not(:last-child),
	.spotlight-row__meta:first-child:not(:last-child) {
		margin-bottom: var(--space-xx-small);
	}
}

.spotlight-row__title__label {
	border-bottom: 2px solid var(--color-alpha-foreground-0);
	display: inline;
	padding-bottom: 1px;
	transition: border-bottom-color 64ms linear;
}

@media (min-width: 1024px) {
	.spotlight-row__title__label {
		border-bottom-width: 3px;
	}
}

@media (min-width: 1440px) {
	.spotlight-row__title__label {
		padding-bottom: 2px;
	}
}

.spotlight-row__link:any-link:focus .spotlight-row__title__label,
.spotlight-row__link:any-link:hover .spotlight-row__title__label {
	border-bottom-color: var(--color-foreground);
}

@media (prefers-reduced-motion: reduce) {
	.spotlight-row__title__label {
		transition: none;
	}
}

.spotlight-row__description {
	opacity: var(--teaser-hover-opacity);
}

.spotlight-row__description:not(:last-child) {
	margin-bottom: var(--space-xxx-small);
}

@media (min-width: 1024px) {
	.spotlight-row__description:not(:last-child) {
		margin-bottom: var(--space-xx-small);
	}
}

@media (max-width: 767px) {
	.spotlight-row__meta {
		box-sizing: border-box;
		padding-right: var(--space-xx-small);
		padding-left: var(--space-xx-small);
		width: 100%;
	}
}

.spotlight-row__meta__item {
	display: inline-block;
	opacity: 0.5;
	user-select: none;
}

.spotlight-row__meta__item:not(:last-child) {
	margin-right: 0.75rem;
}

.spotlight-row__meta__item:not(.spotlight-row__meta__item--featured) {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 120px;
}
