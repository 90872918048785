.sticky-bookmark {
	background-color: var(--color-black);
	box-sizing: border-box;
	color: var(--color-white);
	padding-top: var(--space-xx-small);
	padding-bottom: var(--space-xx-small);
	position: fixed;
	bottom: 0;
	left: 0;
	transform: translateY(100.1%); /* out of view + a little extra */
	transition: transform 560ms cubic-bezier(0.44, 0, 0, 1);
	width: 100%;
	z-index: 9995;
}

@media (prefers-reduced-motion: reduce) {
	.sticky-bookmark {
		transition: none !important;
	}
}

@media (prefers-color-scheme: dark) {
	.sticky-bookmark {
		background-color: var(--color-foreground-light);
	}
}

body.is-scrolled-between-header-and-footer .sticky-bookmark {
	transform: translateY(0);
}

/* Don't show for screens that arent wide and/or tall enough */
@media (max-height: 540px), (max-width: 1279px) {
	.sticky-bookmark {
		display: none;
	}
}

.has-open-gallery .sticky-bookmark {
	display: none;
}

.l-styleguide .sticky-bookmark {
	position: relative;
	transform: none;
}

.sticky-bookmark__inner {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
}

.sticky-bookmark__label {
	display: inline-block;
	flex-shrink: 0;
	margin-right: var(--space-xx-small);
}

.sticky-bookmark__title {
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
