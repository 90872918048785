.side-to-side-media {
	display: flex;
}

.side-to-side-media__item {
	margin-right: var(--space-xx-small);
	margin-bottom: var(--space-medium);
	width: calc(50% - 0.5rem);
}

@media (min-width: 1024px) {
	.side-to-side-media__item {
		margin-right: var(--space-x-small);
		width: calc(50% - 0.75rem);
	}
}

.side-to-side-media__item:last-child {
	margin-right: 0;
}
