.aside-block {
}

.aside-block__row {
	background-color: var(--color-background);
	border: 1px solid var(--color-alpha-foreground-25);
	box-sizing: border-box;
	color: var(--color-foreground);
	display: block;
	width: 100%;
}

.aside-block__row:not(:last-child):not(:only-child) {
	border-bottom: 0;
}

.aside-block__row.aside-block__row--header {
	padding: var(--space-xx-small);
}

.aside-block__row.aside-block__row--inverted {
	background-color: var(--color-foreground);
	border: 1px solid var(--color-alpha-background-25);
	color: var(--color-background);
}

.aside-block__title {
	font-weight: var(--font-weight-bold);
	margin-bottom: var(--space-xx-small);
	text-transform: uppercase;
}

.aside-block__thumbnail {
	display: block;
	width: 100%;
}

.aside-block__link {
	box-sizing: border-box;
	color: currentColor;
	display: block;
	padding: var(--space-xx-small) var(--space-medium) var(--space-xx-small) var(--space-xx-small);
	position: relative;
	text-decoration: none;
	width: 100%;
}

.aside-block__link .icon {
	display: block;
	fill: currentColor;
	position: absolute;
	top: 50%;
	right: var(--space-xx-small);
	transform: translate(0, -50%);
	transition: transform 240ms ease-out;
	width: 12px;
	height: 12px;
}

.aside-block__link:focus,
.aside-block__link:hover {
	text-decoration: underline;
}

.aside-block__link:focus .icon,
.aside-block__link:hover .icon {
	transform: translate(0.25rem, -50%);
}
