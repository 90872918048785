.gallery {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
}

.gallery.is-open {
	display: block;
}

/*
 * Component placement
 */
.gallery__close-button,
.gallery__next-button,
.gallery__pagination,
.gallery__previous-button {
	position: absolute;
	z-index: 5;
}

.gallery__close-button,
.gallery__next-button,
.gallery__pagination {
	right: 1rem;
}

@media (min-width: 768px) {
	.gallery__close-button,
	.gallery__next-button,
	.gallery__pagination {
		right: 2.25rem;
	}
}

@media (min-width: 1024px) {
	.gallery__close-button,
	.gallery__next-button,
	.gallery__pagination {
		right: 3.75rem;
	}
}

.gallery__close-button {
	top: 1.875rem;
}

.gallery__next-button,
.gallery__previous-button {
	/* center - half of own height - half of collapsed description height */
	top: calc(50% - 0.4375em - 1.6875rem);
}

@media (min-width: 768px) {
	.gallery__next-button,
	.gallery__previous-button {
		top: calc(50% - 0.5625rem - 1.8125rem);
	}
}

@media (min-width: 1024px) {
	.gallery__next-button,
	.gallery__previous-button {
		top: calc(50% - 0.5625rem - 2.0625rem);
	}
}

.gallery__previous-button {
	left: 1rem;
}

@media (min-width: 768px) {
	.gallery__previous-button {
		left: 2.25rem;
	}
}

@media (min-width: 1024px) {
	.gallery__previous-button {
		left: 3.75rem;
	}
}

.gallery__pagination {
	bottom: 1rem;
	user-select: none;
}

@media (min-width: 768px) {
	.gallery__pagination {
		bottom: 1.125rem;
	}
}

@media (min-width: 1024px) {
	.gallery__pagination {
		bottom: 1.4375rem;
	}
}

/*
 * Icons
 */
.gallery__close-button,
.gallery__next-button,
.gallery__previous-button {
	appearance: none;
	background: none;
	border: none;
	border-radius: 0;
	color: currentColor;
	display: block;
	outline: 0;
	padding: 0;
	transition: opacity 96ms linear;
}

.gallery__close-button:focus,
.gallery__close-button:hover,
.gallery__next-button:focus,
.gallery__next-button:hover,
.gallery__previous-button:focus,
.gallery__previous-button:hover {
	opacity: 0.5;
}

.gallery__close-button:focus,
.gallery__next-button:focus,
.gallery__previous-button:focus {
	outline: 0;
}

.gallery__close-button:hover,
.gallery__next-button:hover,
.gallery__previous-button:hover {
	cursor: pointer;
}

.gallery__close-button .icon,
.gallery__next-button .icon,
.gallery__previous-button .icon {
	display: block;
	fill: currentColor;
	width: 100%;
	height: 100%;
}

.gallery__previous-button,
.gallery__next-button {
	width: 1rem;
	height: 1rem;
}

@media (min-width: 1024px) {
	.gallery__previous-button,
	.gallery__next-button {
		width: 1.125rem;
		height: 1.125rem;
	}
}

.gallery__close-button {
	width: 0.875rem;
	height: 0.875rem;
}

.gallery__previous-button .icon {
	transform: scaleX(-1);
}

/*
 * Gallery item layout
 */
.gallery__item {
	opacity: 1;
	overflow: hidden;
	position: absolute;
	bottom: 0;
	left: 0;
	transition: opacity 96ms linear;
	width: 100%;
	height: 100%;
}

.gallery.is-animating .gallery__item {
	opacity: 0;
}

.gallery__item__description-checkbox {
	display: none;
}

.gallery__item__top {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	bottom: 3.375rem;
	left: 0;
	width: 100%;
	height: calc(100% - 3.375rem);
	z-index: 1;
}

@media (min-width: 768px) {
	.gallery__item__top {
		bottom: 3.625rem;
		height: calc(100% - 3.625rem);
	}
}

@media (min-width: 1024px) {
	.gallery__item__top {
		bottom: 4.125rem;
		height: calc(100% - 4.125rem);
	}
}

.gallery__item__bottom {
	background-color: var(--color-black);
	border-top: 1px solid var(--color-alpha-white-25);
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: auto;
	min-height: 3.375rem;
	max-height: 3.375rem;
	z-index: 2;
}

@media (min-width: 768px) {
	.gallery__item__bottom {
		min-height: 3.625rem;
		max-height: 3.625rem;
	}
}

@media (min-width: 1024px) {
	.gallery__item__bottom {
		min-height: 4.125rem;
		max-height: 4.125rem;
	}
}

.gallery__item__description-checkbox:checked ~ .gallery__item__bottom {
	overflow-x: hidden;
	overflow-y: auto;
	max-height: calc(50vh - 3rem);
}

/*
 * Asset
 */
.gallery__item__asset-container {
	position: relative;
	width: calc(100% - 6rem); /* make it fit in between arrow buttons */
	max-width: 1096px; /* specific value from design */
	height: calc(100% - 6.25rem); /* specific value from design */
	max-height: 832px;
}
/* specific value from design */
@media (min-width: 768px) {
	.gallery__item__asset-container {
		width: calc(100% - 10rem);
	}
}

@media (min-width: 1024px) {
	.gallery__item__asset-container {
		width: calc(100% - 16rem);
	}
}

.gallery__item__asset {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) translateZ(0);
	width: auto;
	max-width: none;
	height: auto;
	height: 100%;
	z-index: 1;
}

.gallery__item__asset.gallery__item__asset--fill-horizontal {
	width: 100%;
	height: auto;
}

/*
 * Description
 */
.gallery__item__description {
	box-sizing: border-box;
	padding: 1rem 5rem 1rem 1rem;
	width: 100%;
	min-height: 3.375rem;
}

@media (min-width: 768px) {
	.gallery__item__description {
		padding: 1.25rem 33.3333% 1.25rem 2.25rem;
		min-height: 3.625rem;
	}
}

@media (min-width: 1024px) {
	.gallery__item__description {
		padding-right: 50%;
		padding-left: 3.75rem;
		min-height: 4.125rem;
	}
}

.gallery__item__description__inner {
	width: 100%;
}

.gallery__item__description__inner > * {
	margin-bottom: 1.25rem;
}

.gallery__item__description__inner > *:nth-last-child(2) {
	margin-bottom: 0;
}

/* Styles for collapsed description */

.gallery__item__description-checkbox:not(:checked) ~ .gallery__item__bottom .gallery__item__description__inner {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

/* Hide everything except first text and description toggle */

.gallery__item__description-checkbox:not(:checked) ~ .gallery__item__bottom .gallery__item__description__inner > *:not(:first-child):not(.gallery__item__description-toggle) {
	display: none;
}

/* Show preview of first text, clipped */

.gallery__item__description-checkbox:not(:checked) ~ .gallery__item__bottom .gallery__item__description__inner > *:first-child:not(.gallery__item__description-toggle) {
	display: block;
	margin-bottom: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

/* Make sure we're only showing 1 line */

.gallery__item__description-checkbox:not(:checked) ~ .gallery__item__bottom .gallery__item__description__inner br {
	display: none;
}

/*
 * Description toggle
 */
.gallery__item__description-toggle {
	display: inline-block;
	margin-bottom: 0;
	transition: opacity 96ms linear;
}

.gallery__item__bottom--no-read-more-needed .gallery__item__description-toggle {
	display: none;
}
/* Specific for collapsed description */
.gallery__item__description-checkbox:not(:checked) ~ .gallery__item__bottom .gallery__item__description-toggle {
	flex-shrink: 0;
	margin-left: var(--space-xxx-small);
}

@media (min-width: 1024px) {
	.gallery__item__description-checkbox:not(:checked) ~ .gallery__item__bottom .gallery__item__description-toggle {
		margin-left: var(--space-xx-small);
	}
}

.gallery__item__description-checkbox:not(:checked) ~ .gallery__item__bottom .gallery__item__description-toggle::after {
	content: " +";
}
/* Specific for uncollapsed description */
.gallery__item__description-checkbox:checked ~ .gallery__item__bottom .gallery__item__description-toggle {
	margin-top: 1.25rem;
}

.gallery__item__description-checkbox:checked ~ .gallery__item__bottom .gallery__item__description-toggle::after {
	content: " -";
}

@media (hover: hover) {
	.gallery__item__description-toggle:focus,
	.gallery__item__description-toggle:hover {
		opacity: 0.5;
	}
}

.gallery__item__description-toggle:focus {
	outline: 0;
}

.gallery__item__description-toggle:hover {
	cursor: pointer;
}

.gallery__item__description-toggle__close,
.gallery__item__description-toggle__open {
	border-bottom: 1px solid;
	display: inline-block;
	position: relative;
	transform: translateY(1px);
}

.gallery__item__description-checkbox:checked ~ .gallery__item__bottom .gallery__item__description-toggle__open {
	display: none;
}

.gallery__item__description-checkbox:not(:checked) ~ .gallery__item__bottom .gallery__item__description-toggle__close {
	display: none;
}
