h3-before-2023-01-31 {
	display: inline;
}

h3-before-2023-01-31 > * {
	font-family: var(--font-sans);
	font-size: 0.8125rem;
	font-weight: var(--font-weight-bold);
	line-height: 1.85;
	margin-top: 0;
	margin-bottom: 0.25rem;
	text-transform: uppercase;
}

@media (min-width: 1024px) {
	h3-before-2023-01-31 > * {
		font-size: 0.875rem;
		line-height: 1.71;
	}
}

h3-before-2023-01-31 > :last-child {
	margin-bottom: 0;
}

h3-before-2023-01-31 > :only-child {
	margin-bottom: 0;
}

h3-before-2023-01-31 > * b,
h3-before-2023-01-31 > * strong {
	font-family: var(--font-sans);
	font-weight: var(--font-weight-bold);
}
