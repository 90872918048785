.checklist__wrapper {
	width: 100%;
}

@media (min-width: 768px) {
	.checklist__wrapper {
		margin-right: auto;
		margin-left: auto;
		width: calc(100% - 3.75rem);
	}
}

@media (min-width: 1280px) {
	.checklist__wrapper {
		width: calc(100% - 7.5rem);
		max-width: 1320px;
	}
}

.checklist__chapter {
	background-color: var(--color-alpha-background-90);
	border-radius: 0.625rem;
	color: var(--color-foreground);
	position: relative;
	transition: background-color 96ms linear;
	width: 100%;
}

@media (min-width: 1280px) {
	.checklist__chapter {
		border-radius: 1.25rem;
	}
}

.checklist__chapter[data-toggled] {
	background-color: var(--color-background);
}

.checklist__chapter__header,
.checklist__chapter__content {
	padding: 2.1875rem 0.9375rem;
}

@media (min-width: 768px) {
	.checklist__chapter__header,
	.checklist__chapter__content {
		padding: 2.25rem 2.625rem;
	}
}

@media (min-width: 1440px) {
	.checklist__chapter__header,
	.checklist__chapter__content {
		padding: 4.1875rem 3.375rem 4.1875rem 4.5rem;
	}
}

.checklist__chapter__header {
	position: relative;
}

.checklist__chapter .checklist__chapter__header::after,
.checklist__chapter:not(:first-child) .checklist__chapter__header::before {
	border-top: 3px dotted;
	content: "";
	pointer-events: none;
	position: absolute;
	left: 0;
	width: 100%;
	height: 0;
}

.checklist__chapter .checklist__chapter__header::after {
	display: none;
	opacity: 0.35;
	bottom: 0;
	transform: translateY(50%);
}

.checklist__chapter[data-toggled] .checklist__chapter__header::after {
	display: block;
}

.checklist__chapter .checklist__chapter__header::before {
	top: 0;
	transform: translateY(-50%);
}

.checklist[data-parent-theme="theme-brown"] .checklist__chapter__header::after,
.checklist[data-parent-theme="theme-brown"] .checklist__chapter__header::before {
	border-top-color: var(--color-theme-brown);
}

.checklist[data-parent-theme="theme-crimson"] .checklist__chapter__header::after,
.checklist[data-parent-theme="theme-crimson"] .checklist__chapter__header::before {
	border-top-color: var(--color-theme-crimson);
}

.checklist[data-parent-theme="theme-dark-blue"] .checklist__chapter__header::after,
.checklist[data-parent-theme="theme-dark-blue"] .checklist__chapter__header::before {
	border-top-color: var(--color-theme-dark-blue);
}

.checklist[data-parent-theme="theme-dark-green"] .checklist__chapter__header::after,
.checklist[data-parent-theme="theme-dark-green"] .checklist__chapter__header::before {
	border-top-color: var(--color-theme-dark-green);
}

.checklist[data-parent-theme="theme-fuchsia-with-black"] .checklist__chapter__header::after,
.checklist[data-parent-theme="theme-fuchsia-with-black"] .checklist__chapter__header::before,
.checklist[data-parent-theme="theme-fuchsia-with-white"] .checklist__chapter__header::after,
.checklist[data-parent-theme="theme-fuchsia-with-white"] .checklist__chapter__header::before {
	border-top-color: var(--color-theme-fuchsia);
}

.checklist[data-parent-theme="theme-light-blue"] .checklist__chapter__header::after,
.checklist[data-parent-theme="theme-light-blue"] .checklist__chapter__header::before {
	border-top-color: var(--color-theme-light-blue);
}

.checklist[data-parent-theme="theme-light-green"] .checklist__chapter__header::after,
.checklist[data-parent-theme="theme-light-green"] .checklist__chapter__header::before {
	border-top-color: var(--color-theme-light-green);
}

.checklist[data-parent-theme="theme-orange"] .checklist__chapter__header::after,
.checklist[data-parent-theme="theme-orange"] .checklist__chapter__header::before {
	border-top-color: var(--color-theme-orange);
}

.checklist[data-parent-theme="theme-pink"] .checklist__chapter__header::after,
.checklist[data-parent-theme="theme-pink"] .checklist__chapter__header::before {
	border-top-color: var(--color-theme-pink);
}

.checklist[data-parent-theme="theme-red"] .checklist__chapter__header::after,
.checklist[data-parent-theme="theme-red"] .checklist__chapter__header::before {
	border-top-color: var(--color-theme-red);
}

.checklist[data-parent-theme="theme-yellow"] .checklist__chapter__header::after,
.checklist[data-parent-theme="theme-yellow"] .checklist__chapter__header::before {
	border-top-color: var(--color-theme-yellow);
}

.checklist__chapter__corner {
	--corner-size: 1.25rem;
	--corner-size-from-wide: 2.25rem;

	border-radius: 100%;
	pointer-events: none;
	position: absolute;
	width: var(--corner-size);
	height: var(--corner-size);
	z-index: 5;
}

@media (min-width: 1280px) {
	.checklist__chapter__corner {
		width: var(--corner-size-from-wide);
		height: var(--corner-size-from-wide);
	}
}

.checklist__chapter__corner.checklist__chapter__corner--top-left {
	top: calc(var(--corner-size) / -2);
	left: calc(var(--corner-size) / -2);
}

@media (min-width: 1280px) {
	.checklist__chapter__corner.checklist__chapter__corner--top-left {
		top: calc(var(--corner-size-from-wide) / -2);
		left: calc(var(--corner-size-from-wide) / -2);
	}
}

.checklist__chapter__corner.checklist__chapter__corner--top-right {
	top: calc(var(--corner-size) / -2);
	right: calc(var(--corner-size) / -2);
}

@media (min-width: 1280px) {
	.checklist__chapter__corner.checklist__chapter__corner--top-right {
		top: calc(var(--corner-size-from-wide) / -2);
		right: calc(var(--corner-size-from-wide) / -2);
	}
}

.checklist__chapter__corner.checklist__chapter__corner--bottom-right {
	right: calc(var(--corner-size) / -2);
	bottom: calc(var(--corner-size) / -2);
}

@media (min-width: 1280px) {
	.checklist__chapter__corner.checklist__chapter__corner--bottom-right {
		right: calc(var(--corner-size-from-wide) / -2);
		bottom: calc(var(--corner-size-from-wide) / -2);
	}
}

.checklist__chapter__corner.checklist__chapter__corner--bottom-left {
	bottom: calc(var(--corner-size) / -2);
	left: calc(var(--corner-size) / -2);
}

@media (min-width: 1280px) {
	.checklist__chapter__corner.checklist__chapter__corner--bottom-left {
		bottom: calc(var(--corner-size-from-wide) / -2);
		left: calc(var(--corner-size-from-wide) / -2);
	}
}

.checklist__chapter:first-child .checklist__chapter__corner.checklist__chapter__corner[class*="top-"] {
	display: none;
}

.checklist__chapter:last-child:not([data-toggled]) .checklist__chapter__corner.checklist__chapter__corner[class*="bottom-"] {
	display: none;
}

.checklist__chapter:not([data-toggled]) .checklist__chapter__content {
	overflow: hidden;
	padding: 0;
	height: 0;
}

.checklist__question {
	opacity: 0;
	transform: translateY(1rem);
	transition:
		opacity 160ms linear,
		transform 320ms cubic-bezier(0.5, 0, 0.25, 1);
}

.checklist__question:not(:last-child) {
	border-bottom: 1px solid var(--color-alpha-foreground-25);
	margin-bottom: var(--space-medium);
	padding-bottom: var(--space-medium);
}

@media (min-width: 1280px) {
	.checklist__question:not(:last-child) {
		margin-bottom: 3.75rem;
		padding-bottom: 3.75rem;
	}
}

.checklist__question:first-child {
	padding-top: 0.3125rem;
}

@media (min-width: 768px) {
	.checklist__question:first-child {
		padding-top: 0.25rem;
	}
}

@media (min-width: 1280px) {
	.checklist__question:first-child {
		padding-top: 1.5rem;
	}
}

@media (min-width: 1440px) {
	.checklist__question:first-child {
		padding-top: 0;
	}
}

.checklist__question:last-child {
	padding-bottom: 0.3125rem;
}

@media (min-width: 768px) {
	.checklist__question:last-child {
		padding-bottom: 0.25rem;
	}
}

@media (min-width: 1280px) {
	.checklist__question:last-child {
		padding-bottom: 1.5rem;
	}
}

@media (min-width: 1440px) {
	.checklist__question:last-child {
		padding-bottom: 0;
	}
}

.checklist__chapter[data-toggled] .checklist__question {
	opacity: 1;
	transform: translateY(0);
}
