@media (min-width: 768px) {
	.l-search {
		padding-right: 17%;
		padding-left: 17%;
	}
}

@supports (grid-template-columns: repeat(12, 1fr)) {
	.l-search {
		display: grid;
		grid-row-gap: var(--space-medium);
		grid-column-gap: 1rem;
		grid-template-columns: 1fr;
	}

	@media (min-width: 768px) {
		.l-search {
			grid-template-columns: repeat(12, 1fr);
			padding-right: 0;
			padding-left: 0;
		}
	}

	@media (min-width: 1440px) {
		.l-search {
			grid-column-gap: 1.5rem;
		}
	}
}

.l-search__aside,
.l-search__results {
	position: relative;
}

.l-search__aside:not(:last-child),
.l-search__results:not(:last-child) {
	margin-bottom: var(--space-medium);
}

.l-search__aside {
	z-index: 0;
}

@supports (grid-template-columns: repeat(12, 1fr)) {
	.l-search__aside {
		margin-bottom: 0;
	}

	@media (min-width: 768px) {
		.l-search__aside {
			grid-column-start: 1;
			grid-column-end: span 12;
		}
	}

	@media (min-width: 1440px) {
		.l-search__aside {
			grid-column-end: span 3;
		}
	}
}

.l-search__results {
	min-width: 0;
	z-index: 10;
}

@supports (grid-template-columns: repeat(12, 1fr)) {
	.l-search__results {
		margin-bottom: 0;
	}

	@media (min-width: 768px) {
		.l-search__results {
			grid-column-start: 1;
			grid-column-end: span 12;
		}
	}

	@media (min-width: 1440px) {
		.l-search__results {
			grid-column-start: 4;
			grid-column-end: span 9;
		}
	}
}

@supports (grid-template-columns: repeat(12, 1fr)) {
	@media (min-width: 768px) {
		.l-search--empty .l-search__results {
			grid-column-end: span 7;
		}
	}

	@media (min-width: 1440px) {
		.l-search--empty .l-search__results {
			grid-column-end: span 6;
		}
	}
}
