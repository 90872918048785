.form {
	position: relative;
	width: 100%;
}

.form__group {
	border: 0;
	margin: 0;
	padding: 0;
	width: 100%;
}

.form__group:not(:last-child) {
	margin-bottom: var(--space-small);
}

@media (min-width: 1440px) {
	.form__group:not(:last-child) {
		margin-bottom: var(--space-medium);
	}
}

.form__group__title {
	display: inline-block;
	margin-bottom: var(--space-x-small);
	padding: 0;
	min-width: 100%;
}

@media (min-width: 1440px) {
	.form__group__title {
		margin-bottom: var(--space-medium);
	}
}

.form__note {
	color: var(--color-foreground-medium);
	display: inline-block;
}

.form__note:not(:last-child) {
	margin-bottom: var(--space-xx-small);
}

.form__row {
	width: 100%;
}

.form__row:not(:last-child) {
	margin-bottom: var(--space-xx-small);
}

.form__label {
	display: inline-block;
	margin-bottom: 0.25rem;
}

@media (min-width: 1440px) {
	.form__label {
		margin-bottom: var(--space-xxx-small);
	}
}

.form__label__optional-label {
	color: var(--color-foreground-medium);
	display: inline-block;
	margin-left: 0.375rem;
}

.form__input,
.form__select,
.form__textarea {
	background: transparent;
	border: 1px solid var(--color-foreground-light);
	border-radius: 0;
	box-shadow: 0 0 0 2px var(--color-backgroud);
	box-sizing: border-box;
	color: currentColor;
	display: block;
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
	line-height: inherit;
	outline: 0;
	padding: 0.375rem 0.875rem;
	transition: box-shadow 96ms linear;
	width: 100%;
	height: 40px;
}

@media (prefers-reduced-motion: reduce) {
	.form__input,
	.form__select,
	.form__textarea {
		transition: none;
	}
}

@media (min-width: 1024px) {
	.form__input,
	.form__select,
	.form__textarea {
		padding: 0.25rem 0.875rem;
		height: 42px;
	}
}

.form__input:focus,
.form__select:focus,
.form__textarea:focus {
	box-shadow: 0 0 0 2px #b2e1f8;
	cursor: pointer;
}

.form__input:not(:focus):hover,
.form__select:not(:focus):hover,
.form__textarea:not(:focus):hover {
	box-shadow: 0 0 0 2px rgb(0 0 0 / 0.1);
	cursor: default;
}

.form__input[disabled],
.form__input[readonly],
.form__input.is-disabled,
.form__input.is-readonly,
.form__select[disabled],
.form__select[readonly],
.form__select.is-disabled,
.form__select.is-readonly,
.form__textarea[disabled],
.form__textarea[readonly],
.form__textarea.is-disabled,
.form__textarea.is-readonly {
	background-color: var(--color-foreground-x-light);
	box-shadow: none !important;
}

.form__input[disabled],
.form__input.is-disabled,
.form__select[disabled],
.form__select.is-disabled,
.form__textarea[disabled],
.form__textarea.is-disabled {
	pointer-events: none;
	user-select: none;
}

.form__input[readonly],
.form__input.is-readonly,
.form__select[readonly],
.form__select.is-readonly,
.form__textarea[readonly],
.form__textarea.is-readonly {
	cursor: text;
	user-select: text;
}

.form__input.has-error,
.form__select.has-error,
.form__textarea.has-error {
	border-color: var(--color-error);
}

.form__input::-ms-input-placeholder,
.form__select::-ms-input-placeholder,
.form__textarea::-ms-input-placeholder {
	color: var(--color-foreground-medium);
}

.form__input::-webkit-input-placeholder,
.form__select::-webkit-input-placeholder,
.form__textarea::-webkit-input-placeholder {
	color: var(--color-foreground-medium);
}

.form__input::placeholder,
.form__select::placeholder,
.form__textarea::placeholder {
	color: var(--color-foreground-medium);
}

.form__textarea {
	resize: none;
	height: 124px;
}

.form__select {
	appearance: none;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14'%3E%3Cpolygon points='1.6,2.7 7,8 12.4,2.7 14,4.3 7,11.3 0,4.3 '/%3E%3C/svg%3E%0A");
	background-position: calc(100% - 0.875rem) center;
	background-repeat: no-repeat;
	background-size: 0.875rem 0.875rem;
	padding-right: 2.5rem;
}

.form__select[disabled],
.form__select.is-disabled {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14'%3E%3Cpolygon fill='%23808080' points='1.6,2.7 7,8 12.4,2.7 14,4.3 7,11.3 0,4.3 '/%3E%3C/svg%3E%0A");
}

@media (prefers-color-scheme: dark) {
	.form__select:not([disabled]):not(.is-disabled) {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14'%3E%3Cpolygon fill='%23ffffff' points='1.6,2.7 7,8 12.4,2.7 14,4.3 7,11.3 0,4.3 '/%3E%3C/svg%3E%0A");
	}
}

.form__select::-ms-expand {
	display: none;
}

.form__error {
	display: inline-flex;
	padding-top: var(--space-xxx-small);
	padding-bottom: var(--space-xxx-small);
	justify-content: flex-start;
	align-items: center;
	width: 100%;
}

.form__error .icon {
	display: inline-block;
	fill: var(--color-error);
	flex-basis: auto;
	flex-grow: 0;
	flex-shrink: 0;
	margin-right: 0.625rem;
	width: 0.875rem;
	height: 0.875rem;
}

.form__error__label {
	flex-basis: auto;
	flex-grow: 1;
	flex-shrink: 0;
}

.form__checkbox,
.form__radio {
	display: block;
	position: relative;
	width: 100%;
}

.form__row .form__checkbox:not(:last-child),
.form__row .form__radio:not(:last-child) {
	margin-bottom: var(--space-xxx-small);
}

.form__checkbox.is-disabled,
.form__radio.is-disabled {
	pointer-events: none;
}

.form__checkbox__input,
.form__radio__input {
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 1px;
	height: 1px;
}

.form__checkbox__label,
.form__radio__label {
	display: inline-block;
	padding-left: 2rem;
}

@media (min-width: 1440px) {
	.form__checkbox__label,
	.form__radio__label {
		padding-left: 2.25rem;
	}
}

.form__checkbox__label:hover,
.form__radio__label:hover {
	cursor: pointer;
}

.form__checkbox__label::after,
.form__radio__label::after {
	opacity: 0;
	position: absolute;
	z-index: 2;
}

@media (min-width: 1024px) {
	.form__checkbox__label::after,
	.form__radio__label::after {
		top: 0.625rem;
		width: 0.75rem;
		height: 0.75rem;
	}
}

.form__checkbox__label::before,
.form__radio__label::before {
	background-color: var(--color-background);
	border: 1px solid var(--color-foreground-light);
	content: "";
	position: absolute;
	top: 0.1875rem;
	left: 0;
	width: 1rem;
	height: 1rem;
	z-index: 1;
}

@media (min-width: 1024px) {
	.form__checkbox__label::before,
	.form__radio__label::before {
		top: 0.1875rem;
		width: 1.125rem;
		height: 1.125rem;
	}
}

.form__checkbox__input:checked + .form__checkbox__label::after,
.form__radio__input:checked + .form__checkbox__label::after,
.form__checkbox__input:checked + .form__radio__label::after,
.form__radio__input:checked + .form__radio__label::after {
	opacity: 1;
}

.form__checkbox__input:checked + .form__checkbox__label::before,
.form__radio__input:checked + .form__checkbox__label::before,
.form__checkbox__input:checked + .form__radio__label::before,
.form__radio__input:checked + .form__radio__label::before {
	border-color: var(--color-foreground);
}

.form__checkbox.has-error .form__checkbox__label::before,
.form__radio.has-error .form__checkbox__label::before,
.form__checkbox.has-error .form__radio__label::before,
.form__radio.has-error .form__radio__label::before {
	border-color: var(--color-error);
}

.form__checkbox.is-disabled .form__checkbox__label,
.form__radio.is-disabled .form__checkbox__label,
.form__checkbox__input[disabled] + .form__checkbox__label,
.form__radio__input[disabled] + .form__checkbox__label,
.form__checkbox.is-disabled .form__radio__label,
.form__radio.is-disabled .form__radio__label,
.form__checkbox__input[disabled] + .form__radio__label,
.form__radio__input[disabled] + .form__radio__label {
	color: var(--color-foreground-medium);
	pointer-events: none;
}

.form__checkbox.is-disabled .form__checkbox__label::before,
.form__radio.is-disabled .form__checkbox__label::before,
.form__checkbox__input[disabled] + .form__checkbox__label::before,
.form__radio__input[disabled] + .form__checkbox__label::before,
.form__checkbox.is-disabled .form__radio__label::before,
.form__radio.is-disabled .form__radio__label::before,
.form__checkbox__input[disabled] + .form__radio__label::before,
.form__radio__input[disabled] + .form__radio__label::before {
	background-color: var(--color-foreground-x-light);
	border: 1px solid var(--color-foreground-light);
}

.form__checkbox__label::after {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14'%3E%3Cpolygon points='1.6,4.8 5.6,8.7 12.4,2 14,3.6 5.6,12 0,6.4 '/%3E%3C/svg%3E%0A");
	background-position: center;
	background-repeat: no-repeat;
	background-size: 12px 12px;
	content: "";
	top: 0.1875rem;
	left: 0;
	width: 1.0625rem;
	height: 1.0625rem;
}

@media (prefers-color-scheme: dark) {
	.form__checkbox__label::after {
		filter: invert(1);
	}
}

@media (min-width: 1024px) {
	.form__checkbox__label::after {
		background-size: 14px 14px;
		width: 1.25rem;
		height: 1.25rem;
	}
}

.form__checkbox.is-disabled .form__checkbox__input:checked .form__checkbox__label::after,
.form__checkbox__input[disabled]:checked + .form__checkbox__label::after {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14'%3E%3Cpolygon fill='%23808080' points='1.6,4.8 5.6,8.7 12.4,2 14,3.6 5.6,12 0,6.4 '/%3E%3C/svg%3E%0A");
}

.form__radio__label::after {
	background-color: var(--color-foreground);
	border-radius: 100%;
	content: "";
	top: 0.4375rem;
	left: 0.25rem;
	width: 0.625rem;
	height: 0.625rem;
}

@media (min-width: 1024px) {
	.form__radio__label::after {
		top: 0.4375rem;
		width: 0.75rem;
		height: 0.75rem;
	}
}

.form__radio__label::before {
	border-radius: 100%;
	overflow: hidden;
}

.form__radio.is-disabled .form__radio__input:checked .form__radio__label::after,
.form__radio__input[disabled]:checked + .form__radio__label::after {
	background-color: var(--color-foreground-medium);
}
