.type-g {
	font-family: var(--font-book);
	font-size: 0.8125rem;
	font-weight: 400;
	line-height: 1.85;
}

@media (min-width: 1024px) {
	.type-g {
		font-size: 0.875rem;
		line-height: 1.71;
	}
}

.type-g b,
.type-g strong {
	font-family: var(--font-sans);
	font-weight: var(--font-weight-bold);
}
