.l-landing {
}

.l-landing__video-row {
	padding-top: 2rem;
	padding-bottom: 2rem;
	position: relative;
}

@media (min-width: 768px) {
	.l-landing__video-row {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}
}

@media (min-width: 1024px) {
	.l-landing__video-row {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}
}

@media (min-width: 1440px) {
	.l-landing__video-row {
		padding-top: 5rem;
		padding-bottom: 5rem;
	}
}

.l-landing__video-row.theme-white:last-child,
.l-landing__video-row:not([class*="theme-"]):last-child {
	margin-bottom: 0;
	padding-bottom: 0;
}

/** If the first video row has theme-black, it should have a line separating
	 * it from the (black) (spotlight-)video-header component above.
	 */

.spotlight-video-header.theme-black + .l-site-container__body__main .l-landing__video-row.theme-black:first-child::before,
.video-header.theme-black + .l-site-container__body__main .l-landing__video-row.theme-black:first-child::before {
	background-color: var(--color-alpha-white-25);
	content: "";
	position: absolute;
	top: 0;
	left: 1rem;
	width: calc(100% - (2 * 1rem));
	height: 1px;
}

@media (min-width: 768px) {
	.spotlight-video-header.theme-black + .l-site-container__body__main .l-landing__video-row.theme-black:first-child::before,
	.video-header.theme-black + .l-site-container__body__main .l-landing__video-row.theme-black:first-child::before {
		left: 1.875rem;
		width: calc(100% - (2 * 1.875rem));
	}
}

@media (min-width: 1440px) {
	.spotlight-video-header.theme-black + .l-site-container__body__main .l-landing__video-row.theme-black:first-child::before,
	.video-header.theme-black + .l-site-container__body__main .l-landing__video-row.theme-black:first-child::before {
		left: calc((100% - 1320px) / 2);
		width: 1320px;
	}
}
