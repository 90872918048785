.author-avatar {
	background-color: var(--color-foreground);
	border-radius: 50%;
	color: var(--color-background);
	line-height: 3.75rem;
	overflow: hidden;
	position: relative;
	text-align: center;
	text-transform: uppercase;
	width: 3.75rem;
	height: 3.75rem;
}

@media (min-width: 1024px) {
	.author-avatar {
		line-height: 5.625rem;
		width: 5.625rem;
		height: 5.625rem;
	}
}

a[href] .author-avatar::before {
	background-color: var(--color-foreground);
	border-radius: 50%;
	content: "";
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	transition: opacity 96ms linear;
	width: 100%;
	height: 100%;
}

@media (prefers-reduced-motion: reduce) {
	a[href] .author-avatar::before {
		transition: none !important;
	}
}

a[href]:focus .author-avatar::before,
a[href]:hover .author-avatar::before {
	opacity: 0.25;
}

.author-avatar__media {
	border-radius: 50%;
	display: inline-block;
	width: 3.75rem;
	height: auto;
}

@media (min-width: 1024px) {
	.author-avatar__media {
		width: 5.625rem;
	}
}
