.type-a {
	font-family: var(--font-nib);
	font-size: 2.25rem;
	font-weight: 400;
	line-height: 1.17;
}

@media (min-width: 1024px) {
	.type-a {
		font-size: 3rem;
		line-height: 1.25;
	}
}

@media (min-width: 1440px) {
	.type-a {
		font-size: 3.75rem;
		line-height: 1.33;
	}
}

.type-a a:not([class]) {
	border-bottom-width: 2px;
}

@media (min-width: 1024px) {
	.type-a a:not([class]) {
		border-bottom-width: 3px;
		padding-bottom: 1px;
	}
}

@media (min-width: 1440px) {
	.type-a a:not([class]) {
		padding-bottom: 3px;
	}
}
