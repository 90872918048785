.accordion {
	margin-top: 0;
	margin-bottom: var(--space-medium);
}

.accordion:not(:first-child) {
	margin-top: calc(var(--space-xx-small) * -1);
}

.accordion:last-child,
.accordion:only-child {
	margin-bottom: 0;
}

.accordion__item {
	border-bottom: 1px solid var(--color-foreground-light);
}

.accordion__title {
	margin-bottom: 0;
}

.accordion__trigger {
	/* button reset */
	appearance: none;
	background: none;
	border-color: transparent;
	border-style: solid;
	border-width: 0;
	border-radius: 0;
	box-shadow: none;
	box-sizing: border-box;
	color: currentColor;
	cursor: pointer;
	display: inline-block;
	font-size: 1.125rem;
	line-height: 1.25;
	margin: 0;
	text-decoration: none;
	/* button reset */

	padding-top: var(--space-xx-small);
	padding-right: var(--space-small);
	padding-bottom: var(--space-xx-small);
	padding-left: 0;
	position: relative;
	text-align: left;
	width: 100%;
}

.accordion__trigger__title {
	pointer-events: none;
}

html:not(.no-js):not(.js-loading-error) .accordion__trigger .accordion__trigger__title::after {
	background-repeat: no-repeat;
	content: "";
	position: absolute;
	top: var(--space-x-small);
	right: 0;
	width: 12px;
	height: 12px;
}

html:not(.no-js):not(.js-loading-error) .accordion__trigger[aria-expanded="false"] .accordion__trigger__title::after {
	background-image: url("../images/check-down.svg");
}

html:not(.no-js):not(.js-loading-error) .accordion__trigger[aria-expanded="true"] .accordion__trigger__title::after {
	background-image: url("../images/check-top.svg");
}

@media (prefers-color-scheme: dark) {
	.accordion__trigger__title::after {
		filter: invert(1);
	}
}

html:not(.no-js, .js-loading-error) .accordion__panel {
	margin-top: var(--space-xx-small);
	margin-bottom: var(--space-small);
}

html:not(.no-js, .js-loading-error) .accordion__panel[hidden] {
	display: none;
}

@supports (content-visibility: hidden) {
	html:not(.no-js, .js-loading-error) .accordion__panel[hidden="until-found"] {
		content-visibility: hidden;
		display: block;
		margin: 0;
	}
}
