.search-form {
	box-sizing: border-box;
	display: flex;
	overflow: hidden;
	justify-content: flex-start;
	align-items: stretch;
	position: relative;
	width: 100%;
}

.search-form:not(:first-child) {
	margin-top: var(--space-x-small);
}

.search-form__clear-button-container,
.search-form__submit-button-container,
.search-form__text-input-container {
	position: relative;
	height: 3.125rem;
	z-index: 1;
}

@media (min-width: 1440px) {
	.search-form__clear-button-container,
	.search-form__submit-button-container,
	.search-form__text-input-container {
		height: 3.75rem;
	}
}

.search-form__clear-button-container:focus,
.search-form__submit-button-container:focus,
.search-form__text-input-container:focus {
	z-index: 3;
}

.search-form__clear-button-container,
.search-form__submit-button-container {
	flex-basis: 3.125rem;
	flex-grow: 0;
	flex-shrink: 0;
}

@media (min-width: 1440px) {
	.search-form__clear-button-container,
	.search-form__submit-button-container {
		flex-basis: 3.75rem;
	}
}

.search-form__text-input-container {
	flex-basis: auto;
	flex-grow: 1;
	flex-shrink: 1;
}

.search-form__text-input:not(:focus),
.search-form__text-input:not(:hover) {
	border-right-color: var(--color-alpha-white-0);
	border-left-color: var(--color-alpha-white-0);
}

.search-form__clear-button,
.search-form__submit-button,
.search-form__text-input {
	border: 1px solid var(--color-alpha-white-50);
	box-sizing: border-box;
	outline: 0;
	position: relative;
	transition: border-color 64ms linear;
	width: 100%;
	height: 100%;
	z-index: 1;
}

@media (prefers-reduced-motion: reduce) {
	.search-form__clear-button,
	.search-form__submit-button,
	.search-form__text-input {
		transition: none;
	}
}

.search-form__clear-button:focus,
.search-form__clear-button:hover,
.search-form__submit-button:focus,
.search-form__submit-button:hover,
.search-form__text-input:focus,
.search-form__text-input:hover {
	border-color: var(--color-white);
	z-index: 3;
}

.search-form__clear-button,
.search-form__submit-button {
	appearance: none;
	background: none;
	color: currentColor;
	display: flex;
	margin: 0;
	padding: 0;
	justify-content: center;
	align-items: center;
	width: 3.125rem;
}

@media (min-width: 1440px) {
	.search-form__clear-button,
	.search-form__submit-button {
		width: 3.75rem;
	}
}

.search-form__clear-button .icon,
.search-form__submit-button .icon {
	display: block;
	fill: currentColor;
}

.search-form__clear-button:hover,
.search-form__submit-button:hover {
	cursor: pointer;
}

.search-form__clear-button .icon {
	width: 0.75rem;
	height: 0.75rem;
}

@media (min-width: 1440px) {
	.search-form__clear-button .icon {
		width: 0.875rem;
		height: 0.875rem;
	}
}

.search-form__submit-button .icon {
	width: 1rem;
	height: 1rem;
}

@media (min-width: 1440px) {
	.search-form__submit-button .icon {
		width: 1.125rem;
		height: 1.125rem;
	}
}

.search-form__text-input {
	appearance: none;
	background: none;
	box-sizing: border-box;
	color: currentColor;
	display: block;
	margin: 0;
	outline: 0;
	padding: 0.625rem 1rem;
	transform: translateX(-1px);
	width: calc(100% + 2px);
}

@media (min-width: 1440px) {
	.search-form__text-input {
		padding: 0.875rem var(--space-x-small);
	}
}
