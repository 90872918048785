.pagination {
	display: flex;
	list-style: none;
	margin: 1.625rem 0;
	padding: 0;
	justify-content: flex-start;
	align-items: center;
}

@media (min-width: 1440px) {
	.pagination {
		margin: 5rem 0;
	}
}

.pagination:first-child {
	margin-top: 0;
}

.pagination:last-child {
	margin-bottom: 0;
}

.pagination__item.pagination__item--prev + .pagination__item--next {
	margin-left: 1rem;
}

/* Hide all extra pagination items on mobile if prev and next is visible */
@media (max-width: 567px) {
	.pagination--has-prev-and-next .pagination__item[class="pagination__item"] {
		display: none;
	}
}

/* is used in combination with .type-i */
.pagination__item__link {
	border: 1px solid transparent;
	color: var(--color-foreground-medium);
	display: block;
	line-height: 1em;
	padding-top: 0.6875rem;
	padding-right: 0.8125rem;
	padding-bottom: 0.6875rem;
	padding-left: 0.8125rem;
	text-decoration: none;
	transition: color 64ms;
}

.pagination__item:not(.pagination__item--current) .pagination__item__link[href]:hover {
	color: var(--color-foreground);
}

.pagination__item--prev .pagination__item__link,
.pagination__item--next .pagination__item__link {
	border-color: var(--color-foreground);
	color: var(--color-foreground);
}

.pagination__item--current .pagination__item__link {
	background-color: var(--color-foreground);
	color: var(--color-background);
}

.pagination__item--first:not(.pagination__item--current) .pagination__item__link,
.pagination__item--last:not(.pagination__item--current) .pagination__item__link {
	color: var(--color-foreground);
}
