.type-h {
	font-family: var(--font-sans);
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.63;
}

@media (min-width: 1024px) {
	.type-h {
		font-size: 1.125rem;
		line-height: 1.5;
	}
}

.type-h b,
.type-h strong {
	font-family: var(--font-sans);
	font-weight: var(--font-weight-semibold);
}
