.scroll-progress {
	background-color: var(--color-foreground);
	position: fixed;
	top: 0;
	left: 0;
	transform: scale3d(1, 0, 1);
	transform-origin: 0 0;
	width: 5px;
	height: 100vh;
	z-index: 9999;
}

.has-open-gallery .scroll-progress {
	display: none;
}

@media (prefers-reduced-motion: reduce) {
	.scroll-progress {
		display: none;
	}
}

@keyframes SCROLL_PROGRESS_ANIMATION {
	0% {
		transform: scale3d(1, 0, 1);
	}

	100% {
		transform: scale3d(1, 1, 1);
	}
}

@supports (animation-timeline: scroll(root block)) {
	.scroll-progress {
		animation-direction: alternate;
		animation-duration: 3s;
		animation-fill-mode: both;
		animation-name: SCROLL_PROGRESS_ANIMATION;
		animation-timeline: scroll(root block);
	}
}
