.u-visually-hidden {
	border: none !important;
	clip: rect(0 0 0 0) !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	white-space: nowrap !important;
	width: 1px !important;
	height: 1px !important;
}

.u-hidden {
	display: none;
}

.u-overflow-hidden {
	overflow: hidden;
}

@media (max-width: 1023px) {
	.u-hide-up-to-medium {
		display: none;
	}
}

@media (min-width: 1024px) {
	.u-hide-from-medium {
		display: none;
	}
}
