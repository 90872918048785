.spotlight-header {
	box-sizing: border-box;
	margin-top: calc(var(--small-screen-site-header-height) * -1);
	overflow: hidden;
	position: relative;
}

@media (min-width: 1280px) {
	.spotlight-header {
		margin-top: calc(var(--wide-screen-initial-site-header-height) * -1);
	}
}

.spotlight-header__link {
	box-sizing: border-box;
	color: currentColor;
	display: block;
	padding-top: var(--small-screen-site-header-height);
	padding-bottom: 2.25rem;
	text-decoration: none;
	width: 100%;
}

@media (min-width: 768px) {
	.spotlight-header__link {
		padding-top: calc(var(--small-screen-site-header-height) + 2.25rem);
		padding-right: 1.875rem;
		padding-right: calc(1.875rem + env(safe-area-inset-right));
		padding-left: 1.875rem;
		padding-left: calc(1.875rem + env(safe-area-inset-left));
	}
}

@media (min-width: 1024px) {
	.spotlight-header__link {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

@media (min-width: 1280px) {
	.spotlight-header__link {
		padding: calc(var(--wide-screen-initial-site-header-height) + 4.875rem) calc((100% - 1320px) / 2) 4.875rem;
	}
}

.spotlight-header__thumbnail-container {
	transition: opacity 96ms linear;
}

@media (max-width: 1023px) {
	.spotlight-header__thumbnail-container {
		width: 100%;
	}
}

@media (min-width: 1024px) {
	.spotlight-header__thumbnail-container {
		flex-basis: 60%;
		flex-grow: 0;
		flex-shrink: 0;
		margin-right: 4rem;
	}
}

@media (min-width: 1280px) {
	.spotlight-header__thumbnail-container {
		flex-basis: 63%;
	}
}

@media (max-width: 1023px) {
	.spotlight-header__thumbnail-container:not(:last-child) {
		margin-bottom: var(--space-medium);
	}
}

.spotlight-header__link:focus .spotlight-header__thumbnail-container,
.spotlight-header__link:hover .spotlight-header__thumbnail-container {
	opacity: var(--teaser-hover-opacity);
}

@media (prefers-reduced-motion: reduce) {
	.spotlight-header__thumbnail-container {
		transition: none;
	}
}

.spotlight-header__thumbnail {
	display: block;
	width: 100%;
}

.spotlight-header__content {
	padding-right: 1rem;
	padding-left: 1rem;
}

@media (min-width: 768px) {
	.spotlight-header__content {
		padding-right: 0;
		padding-left: 0;
	}
}

@media (min-width: 1024px) {
	.spotlight-header__content {
		flex-basis: auto;
		flex-grow: 1;
		flex-shrink: 1;
		padding: var(--space-medium) 0;
	}
}

.spotlight-header__title {
	display: inline-block;
	hyphens: auto;
	margin-bottom: 0.125rem;
	word-break: auto-phrase;
}

@media (min-width: 1024px) {
	.spotlight-header__title {
		margin-bottom: var(--space-xx-small);
	}
}

.spotlight-header__title__label {
	border-bottom: 2px solid var(--color-alpha-foreground-0);
	display: inline;
	padding-bottom: 1px;
	transition: border-bottom-color 64ms linear;
}

@media (min-width: 1024px) {
	.spotlight-header__title__label {
		border-bottom-width: 3px;
	}
}

@media (min-width: 1280px) {
	.spotlight-header__title__label {
		padding-bottom: 2px;
	}
}

.spotlight-header.theme-black .spotlight-header__title__label {
	border-bottom-color: var(--color-alpha-white-0);
}

.spotlight-header__link:focus .spotlight-header__title__label,
.spotlight-header__link:hover .spotlight-header__title__label {
	border-bottom-color: currentColor;
}

@media (prefers-reduced-motion: reduce) {
	.spotlight-header__title__label {
		transition: none;
	}
}

.spotlight-header__meta__item {
	display: inline-block;
	opacity: 0.5;
	user-select: none;
}

.spotlight-header__meta__item:not(:last-child) {
	margin-right: 0.75rem;
}

.spotlight-header__meta__item:not(.spotlight-row__meta__item--featured) {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 120px;
}

.spotlight-header__meta__item.spotlight-row__meta__item--featured {
	margin-bottom: 0.875rem;
}

.spotlight-header.theme-black .spotlight-header__meta__item {
	opacity: 1;
}
