.tableau {
	break-inside: avoid;
	display: flex;
	margin-top: 0;
	margin-bottom: var(--space-medium);
	overflow: hidden;
	justify-content: center;
	position: relative;
	max-width: calc(100vw - var(--space-medium));
	min-height: 250px;
}

.tableau:not(:first-child) {
	margin-top: calc(-1 * var(--space-xx-small));
}

.tableau:last-child {
	margin-bottom: 0;
}

.tableau:only-child {
	margin-bottom: 0;
}

.tableau.is-oversized {
	justify-content: flex-start;
}

.tableau.is-oversized.is-interactive {
	overflow-x: scroll;
}

.tableau iframe {
	flex-shrink: 0;
}

.tableau::before {
	background-color: #efefef;
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	transition: opacity 512ms;
	width: 100%;
	height: 100%;
	z-index: 2;
}

.tableau.is-interactive::before {
	opacity: 0;
	pointer-events: none;
}

.tableau__loader {
	display: inline-flex;
	font-family: var(--font-sans);
	font-weight: var(--font-weight-semibold);
	line-height: 2rem;
	align-items: center;
	pointer-events: none;
	position: absolute;
	top: 1.5rem;
	left: 2rem;
	z-index: 9;
}

.tableau__loader .icon {
	animation: SPIN 2000ms infinite;
	display: block;
	fill: currentColor;
	margin-right: 0.875rem;
	width: 1.125rem;
	height: 1.125rem;
}

.tableau:not(.is-loading) .tableau__loader {
	display: none;
}

@keyframes SPIN {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(359deg);
	}
}
