.type-b {
	font-family: var(--font-nib);
	font-size: 1.75rem;
	font-weight: 400;
	line-height: 1.21;
}

@media (min-width: 1024px) {
	.type-b {
		font-size: 2.125rem;
		line-height: 1.355;
	}
}

@media (min-width: 1440px) {
	.type-b {
		font-size: 2.5rem;
		line-height: 1.5;
	}
}

.type-b b,
.type-b strong {
	font-family: var(--font-sans);
	font-weight: 400;
}

.type-b a:not([class]) {
	border-bottom-width: 2px;
	padding-bottom: 1px;
}

@media (min-width: 1024px) {
	.type-b a:not([class]) {
		border-bottom-width: 3px;
	}
}

@media (min-width: 1440px) {
	.type-b a:not([class]) {
		padding-bottom: 2px;
	}
}
