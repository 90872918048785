.type-f {
	font-family: var(--font-nib);
	font-size: 1rem;
	font-weight: var(--font-weight-semibold);
	line-height: 1.5;
}

@media (min-width: 1024px) {
	.type-f {
		font-size: 1.125rem;
	}
}

.type-f a:not([class]) {
	padding-bottom: 1px;
}
