/*
 * SITE HEADER STRUCTURE
 *
 * Components:
 *
 *    A → Logo (small screens only)
 *    B → Navigation (large screens only)
 *    C → Language
 *    D → Search Link
 *    E → Menu Toggle
 *
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 * Small screens (up to 1280px)
 *
 * |---|-----------------|---|
 * | D |        A        | E |
 * |---|-----------------|---|
 *
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 * Large screens (1280px and up)
 *
 * |-------------|---|---|---|
 * |      B      | C | D | E |
 * |-------------|---|---|---|
 *
 */

.site-header {
	position: relative;
	width: 100%;
}

.site-header__wrapper {
	display: flex;
	justify-content: flex-start;
	align-items: stretch;
	transition: background-color 64ms linear;
}

.site-header__wrapper:not([class*="t-"]) {
	background-color: transparent;
}

@media (min-width: 768px) {
	.site-header__wrapper {
		margin-right: auto;
		margin-left: auto;
		width: calc(100% - 3.75rem);
		width: calc(100% - 3.75rem - env(safe-area-inset-left) - env(safe-area-inset-right));
	}
}

@media (min-width: 1280px) {
	.site-header__wrapper {
		flex-wrap: wrap;
		width: calc(100% - (1.875rem * 2));
	}
}

@media (min-width: 1280px) and (max-width: 1439px) {
	.site-header__wrapper {
		box-sizing: border-box;
		padding-left: 0;
		transition: padding-left 400ms cubic-bezier(0.65, 0, 0.015, 1);
	}
}

@media (min-width: 1440px) {
	.site-header__wrapper {
		max-width: 1320px;
	}
}

@media (prefers-reduced-motion: reduce) {
	.site-header__wrapper {
		transition: none !important;
	}
}

body.is-scrolled .site-header__wrapper {
	background-color: var(--color-background);
	color: var(--color-foreground);
}

/* Users with JS enabled will get a site-header with white color and a
	 * blurry background until they scroll down.
	 */

html:not(.no-js) .body--starts-with-dark-colors:not(.is-scrolled):not(.body--has-no-scroll-watcher) .site-header__wrapper {
	background-color: var(--color-alpha-black-25);
	color: var(--color-white);
	transition: background-color 96ms linear;
}

@supports (backdrop-filter: blur(2px)) {
	html:not(.no-js) .body--starts-with-dark-colors:not(.is-scrolled):not(.body--has-no-scroll-watcher) .site-header__wrapper {
		backdrop-filter: blur(2px);
		background-color: var(--color-alpha-black-10);
		transition:
			backdrop-filter 96ms linear,
			background-color 96ms linear;
	}
}

@media (min-width: 1280px) and (max-width: 1439px) {
	html:not(.no-js) .body--starts-with-dark-colors:not(.is-scrolled):not(.body--has-no-scroll-watcher) .site-header__wrapper {
		transition:
			background-color 96ms linear,
			padding-left 400ms cubic-bezier(0.65, 0, 0.015, 1);
	}

	@supports (backdrop-filter: blur(2px)) {
		html:not(.no-js) .body--starts-with-dark-colors:not(.is-scrolled):not(.body--has-no-scroll-watcher) .site-header__wrapper {
			transition:
				backdrop-filter 96ms linear,
				background-color 96ms linear,
				padding-left 400ms cubic-bezier(0.65, 0, 0.015, 1);
		}
	}
}

body.body--has-no-scroll-watcher .site-header__wrapper {
	background-color: var(--color-background);
}

/* --- General settings --- */
.site-header__logo-container,
.site-header__menu-toggle-container,
.site-header__navigation-container,
.site-header__search-link-container {
	box-sizing: border-box;
	position: relative;
}

.site-header__logo-container,
.site-header__menu-toggle-container,
.site-header__search-link-container {
	overflow: hidden;
}

@media (max-width: 1279px) {
	.site-header__logo-container,
	.site-header__menu-toggle-container,
	.site-header__search-link-container {
		height: var(--small-screen-site-header-height);
	}
}

@media (min-width: 1280px) {
	.site-header__menu-toggle-container,
	.site-header__navigation-container,
	.site-header__search-link-container {
		height: var(--wide-screen-site-header-height);
	}
}

.site-header__logo,
.site-header__menu-toggle,
.site-header__search-link {
	width: 100%;
	height: 100%;
}

/* ------ Icon + link styles ------ */
.site-header__logo,
.site-header__menu-toggle,
.site-header__navigation__link,
.site-header__search-link,
.site-header__navigation__sub-menu__link {
	color: currentColor;
	opacity: 1;
	text-decoration: none;
	transition: opacity 96ms linear;
}

.site-header__logo:focus,
.site-header__logo:hover,
.site-header__menu-toggle:focus,
.site-header__menu-toggle:hover,
.site-header__navigation__link:focus,
.site-header__navigation__link:hover,
.site-header__search-link:focus,
.site-header__search-link:hover,
.site-header__navigation__sub-menu__link:focus,
.site-header__navigation__sub-menu__link:hover {
	opacity: 0.5;
}

.site-header__logo:focus,
.site-header__menu-toggle:focus,
.site-header__navigation__link:focus,
.site-header__search-link:focus,
.site-header__navigation__sub-menu__link:focus {
	outline: 0;
}

.site-header__logo:hover,
.site-header__menu-toggle:hover,
.site-header__navigation__link:hover,
.site-header__search-link:hover,
.site-header__navigation__sub-menu__link:hover {
	cursor: pointer;
}

.site-header__menu-toggle,
.site-header__search-link {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

.site-header__menu-toggle .icon,
.site-header__search-link .icon {
	display: block;
	fill: currentColor;
}

.site-header__menu-toggle .icon,
.site-header__search-link .icon {
	width: 16px;
	height: 16px;
}

/* #region -------- A: Logo -------- */
.site-header__logo-container {
	display: flex;
	flex-basis: auto;
	flex-grow: 1;
	flex-shrink: 1;
	order: 2;
	padding: 0 var(--space-xx-small);
	justify-content: center;
	align-items: center;
}

@media (max-width: 1279px) {
	.site-header__logo-container {
		border-bottom: 1px solid var(--color-alpha-foreground-25);
	}
}

@media (min-width: 1280px) {
	.site-header__logo-container {
		display: none;
	}
}

@media (max-width: 1279px) {
	html:not(.no-js) .body--starts-with-dark-colors:not(.is-scrolled):not(.body--has-no-scroll-watcher) .site-header__logo-container {
		border-color: var(--color-alpha-white-50);
	}
}

.site-header__logo {
	display: block;
	width: auto;
	height: auto;
}

.site-header__logo .icon {
	display: block;
	fill: currentColor;
	width: 100%;
	max-height: 15px;
}

@media (min-width: 768px) and (max-width: 1279px) {
	.site-header__logo .icon {
		max-height: 16px;
	}
}

@media (min-width: 1280px) {
	.site-header__logo .icon {
		max-height: 20px;
	}
}

.site-header__logo .icon.icon-logo-fai-single-line {
	width: 100%;
	max-height: 15px;
}

@media (min-width: 768px) {
	.site-header__logo .icon.icon-logo-fai-single-line {
		max-height: 20px;
	}
}

/* #endregion -------- A: Logo -------- */

/* #region ----- B: Navigation ----- */
@media (max-width: 1279px) {
	.site-header__navigation-container {
		display: none;
	}
}

@media (min-width: 1280px) {
	.site-header__navigation-container {
		border-left: 1px solid var(--color-alpha-foreground-25);
		display: flex;
		flex-basis: auto;
		flex-grow: 1;
		flex-shrink: 1;
		order: 1;
		justify-content: center;
		align-items: flex-start;
	}
}

@media (min-width: 1280px) {
	html:not(.no-js) .body--starts-with-dark-colors:not(.is-scrolled):not(.body--has-no-scroll-watcher) .site-header__navigation-container {
		border-color: var(--color-alpha-white-50);
	}
}

.site-header__navigation {
	box-sizing: border-box;
	list-style: none;
	margin: 0;
	padding: 0 1.25rem;
	width: 100%;
}

@media (min-width: 1280px) {
	.site-header__navigation {
		border-top: 1px solid var(--color-alpha-foreground-25);
		border-bottom: 1px solid var(--color-alpha-foreground-25);
	}
}

@media (min-width: 1280px) {
	html:not(.no-js) .body--starts-with-dark-colors:not(.is-scrolled):not(.body--has-no-scroll-watcher) .site-header__navigation {
		border-color: var(--color-alpha-white-50);
	}
}

.site-header__navigation__item {
	display: inline-block;
	line-height: 1.63;
	position: relative;
}

@media (min-width: 1024px) {
	.site-header__navigation__item {
		line-height: 1.78;
	}
}

.site-header__navigation__item:not(:last-child) {
	margin-right: 2rem;
}

.site-header__navigation__link {
	box-sizing: border-box;
	display: inline-block;
	padding-top: 0.6875rem;
	padding-bottom: 0.6875rem;
	position: relative;
	height: 100%;
}

.site-header__navigation__link::after {
	background-color: currentColor;
	content: "";
	display: block;
	position: absolute;
	bottom: -1px;
	left: 0;
	transform: scaleY(0);
	transform-origin: bottom center;
	transition: transform 96ms ease-in;
	width: 100%;
	height: 3px;
}

.site-header__navigation__item--active > .site-header__navigation__link::after,
.site-header__navigation__link:focus::after,
.site-header__navigation__link:hover::after {
	transform: scaleY(1);
	transition: transform 160ms ease-out;
}

/* #region sub menu */
.site-header__navigation__sub-menu-trigger {
	cursor: pointer;
	transition: opacity 96ms linear;
}

.site-header__navigation__sub-menu-trigger:hover {
	opacity: 0.5;
}

.site-header__navigation__sub-menu-trigger:hover > .site-header__navigation__link {
	opacity: 1;
}

.site-header__navigation__sub-menu-trigger::after {
	background-color: currentColor;
	content: "";
	display: block;
	position: absolute;
	bottom: -1px;
	left: 0;
	transform: scaleY(0);
	transform-origin: bottom center;
	transition: transform 96ms ease-in;
	width: 100%;
	height: 3px;
}

.site-header__navigation__item--active > .site-header__navigation__sub-menu-trigger::after,
.site-header__navigation__sub-menu-trigger:hover::after {
	transform: scaleY(1);
	transition: transform 160ms ease-out;
}

.site-header__navigation__sub-menu-trigger .site-header__navigation__dropdown {
	-mrh-resets: button;
	box-sizing: border-box;
	margin-left: 8px;
	padding-top: 0.6875rem;
	padding-bottom: 0.6875rem;
}

.site-header__navigation__dropdown .icon {
	width: 0.8125rem;
	height: 0.8125rem;
}

.site-header__navigation__sub-menu-fly-out {
	display: block;
	opacity: 1;
	position: absolute;
	transform: translateX(0);
	transition:
		transform 96ms ease-out,
		opacity 96ms linear;
	visibility: hidden;
	width: max-content;
}

.site-header__navigation__item:hover .site-header__navigation__sub-menu-fly-out,
.site-header__navigation__sub-menu-fly-out[aria-expanded="true"] {
	opacity: 1;
	transform: translateX(-20px);
	visibility: visible;
}

.site-header__navigation__sub-menu {
	background-color: var(--color-background);
	border: 1px solid var(--color-alpha-foreground-25);
	list-style: none;
	padding: 0.625rem 20px;
}

body.is-scrolled .site-header__navigation__sub-menu {
	background-color: var(--color-background);
	color: var(--color-foreground);
}

html:not(.no-js) .body--starts-with-dark-colors:not(.is-scrolled):not(.body--has-no-scroll-watcher) .site-header__navigation__sub-menu {
	background-color: var(--color-alpha-black-25);
	border-color: var(--color-alpha-white-50);
	color: var(--color-white);
	transition: background-color 96ms linear;
}

@supports (backdrop-filter: blur(2px)) {
	html:not(.no-js) .body--starts-with-dark-colors:not(.is-scrolled):not(.body--has-no-scroll-watcher) .site-header__navigation__sub-menu {
		backdrop-filter: blur(2px);
		background-color: var(--color-alpha-black-10);
		transition:
			backdrop-filter 96ms linear,
			background-color 96ms linear;
	}
}

/* search page */
html:not(.no-js) .body--search:not(.is-scrolled):not(.body--has-no-scroll-watcher) .site-header__navigation__sub-menu {
	background-color: var(--color-black);
}

.site-header__navigation__sub-menu__item:not(:last-child) {
	margin-bottom: 0.3125rem;
}

.site-header__navigation__sub-menu__item--active .site-header__navigation__sub-menu__link,
.site-header__navigation__sub-menu__link:hover {
	text-decoration: underline;
}

/* #endregion sub menu */

/* #endregion ----- B: Navigation ----- */

/* #region ----- C: Language Navigation ----- */
.site-header__navigation-languages {
	display: none;
}

@media (--mq-from-wide) {
	.site-header__navigation-languages {
		border-top: 1px solid var(--color-alpha-foreground-25);
		border-bottom: 1px solid var(--color-alpha-foreground-25);
		display: flex;
		order: 2;
		padding-right: 20px;
		padding-left: 20px;
		align-items: center;
	}
}

html:not(.no-js) .body--starts-with-dark-colors:not(.is-scrolled):not(.body--has-no-scroll-watcher) .site-header__navigation-languages {
	border-color: var(--color-alpha-white-50);
}
/* #endregion ----- C: Language Navigation ----- */

/* #region ----- D: Search Link ----- */
.site-header__search-link-container {
	border-right: 1px solid var(--color-alpha-foreground-25);
	border-bottom: 1px solid var(--color-alpha-foreground-25);
	flex-basis: 50px;
	flex-grow: 0;
	flex-shrink: 0;
	order: 1;
}

@media (min-width: 768px) {
	.site-header__search-link-container {
		border-left: 1px solid var(--color-alpha-foreground-25);
	}
}

@media (min-width: 1280px) {
	.site-header__search-link-container {
		border-top: 1px solid var(--color-alpha-foreground-25);
		border-right: 0;
	}
}

@media (min-width: 1280px) {
	.site-header__search-link-container {
		flex-basis: 60px;
		order: 2;
	}
}

html:not(.no-js) .body--starts-with-dark-colors:not(.is-scrolled):not(.body--has-no-scroll-watcher) .site-header__search-link-container {
	border-color: var(--color-alpha-white-50);
}

.body--search .site-header__search-link {
	pointer-events: none;
}

/* #endregion ----- D: Search Link ----- */

/* #region ----- E: Menu Toggle ----- */
.site-header__menu-toggle-container {
	border-bottom: 1px solid var(--color-alpha-foreground-25);
	border-left: 1px solid var(--color-alpha-foreground-25);
	flex-basis: 50px;
	flex-grow: 0;
	flex-shrink: 0;
	order: 3;
}

@media (min-width: 768px) {
	.site-header__menu-toggle-container {
		border-right: 1px solid var(--color-alpha-foreground-25);
	}
}

@media (min-width: 1280px) {
	.site-header__menu-toggle-container {
		border-top: 1px solid var(--color-alpha-foreground-25);
		flex-basis: 53px;
		order: 5;
	}
}

html:not(.no-js) .body--starts-with-dark-colors:not(.is-scrolled):not(.body--has-no-scroll-watcher) .site-header__menu-toggle-container {
	border-color: var(--color-alpha-white-50);
}

/* #endregion ----- E: Menu Toggle ----- */

/* ---- Open nav styles ---- */
@media (max-width: 1279px) {
	#site-container-navigation-toggle:checked ~ .l-site-container__header .site-header__wrapper {
		background-color: var(--color-foreground);
		color: var(--color-background);
		transition:
			background-color 96ms linear,
			color 96ms linear;
	}
}

@media (max-width: 1279px) {
	#site-container-navigation-toggle:checked ~ .l-site-container__header .site-header__logo-container,
	#site-container-navigation-toggle:checked ~ .l-site-container__header .site-header__menu-toggle-container,
	#site-container-navigation-toggle:checked ~ .l-site-container__header .site-header__navigation-container,
	#site-container-navigation-toggle:checked ~ .l-site-container__header .site-header__search-link-container {
		border-color: var(--color-alpha-background-25);
		transition: border-color 96ms linear;
	}
}

.site-header__menu-toggle .icon-close {
	display: none;
}

@media (max-width: 1279px) {
	#site-container-navigation-toggle:checked ~ .l-site-container__header .site-header__menu-toggle .icon-close {
		display: block;
	}
}

@media (max-width: 1279px) {
	#site-container-navigation-toggle:checked ~ .l-site-container__header .site-header__menu-toggle .icon-hamburger {
		display: none;
	}
}

/* ---- Reduced motion ----- */
@media (prefers-reduced-motion: reduce) {
	.site-header__wrapper {
		transition: none !important;
	}
}

/* #region Site header wrapper on site container with grey background */
.l-site-container--grey-background .site-header__wrapper,
body.is-scrolled .l-site-container--grey-background .site-header__wrapper,
.l-site-container--grey-background .site-header__navigation__sub-menu,
body.is-scrolled .l-site-container--grey-background .site-header__navigation__sub-menu {
	background-color: var(--color-theme-grey);
}
/* #endregion Site header wrapper on site container with grey background */
