.text-teaser {
	display: block;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.text-teaser__link {
	color: currentColor;
	display: inline-block;
	text-decoration: none;
}

.text-teaser__title {
	display: inline-block;
	hyphens: auto;
	word-break: auto-phrase;
}

.text-teaser__title:not(:last-child) {
	margin-bottom: 0.1875rem;
}

.text-teaser--featured .text-teaser__title {
	position: relative;
	z-index: 3;
}

/* Designs mix and match type styles here:
		 * → up-to-small → type-f
		 * → from-small  → type-b
		 *
		 * The "type-b"-class is set in the html, so we only need to overwrite
		 * some styles here for the "up-to-small" breakpoint.
		 */
@media (max-width: 767px) {
	.text-teaser--featured .text-teaser__title {
		font-size: 1rem;
		font-weight: var(--font-weight-semibold);
		line-height: 1.5;
	}
}

@supports (-webkit-line-clamp: 3) {
	.text-teaser__title {
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		display: block;
		display: -webkit-box;
		overflow: hidden;
	}
}

.text-teaser__title__label {
	border-bottom: 1px solid var(--color-alpha-foreground-0);
	display: inline;
	padding-bottom: 1px;
	transition: border-bottom-color 64ms linear;
}

.text-teaser__link:focus .text-teaser__title__label,
.text-teaser__link:hover .text-teaser__title__label {
	border-bottom-color: var(--color-foreground);
}

@media (prefers-reduced-motion: reduce) {
	.text-teaser__title__label {
		transition: none;
	}
}

.text-teaser--featured .text-teaser__title__label {
	padding-bottom: 1px;
}

@media (max-width: 767px) {
	.text-teaser--featured .text-teaser__title__label {
		border-bottom-width: 1px;
	}
}

@media (min-width: 768px) {
	.text-teaser--featured .text-teaser__title__label {
		border-bottom-width: 2px;
	}
}

@media (min-width: 1024px) {
	.text-teaser--featured .text-teaser__title__label {
		border-bottom-width: 3px;
	}
}

@media (min-width: 1440px) {
	.text-teaser--featured .text-teaser__title__label {
		padding-bottom: 2px;
	}
}

.text-teaser__meta__item {
	display: inline-block;
	opacity: 0.5;
	overflow: hidden;
	text-overflow: ellipsis;
	user-select: none;
	white-space: nowrap;
	max-width: 120px;
}

.text-teaser__meta__item:not(:last-child) {
	margin-right: 0.75rem;
}
