.authors {
}

.authors__item {
	border-top: 1px solid var(--color-foreground-light);
	color: currentColor;
	display: flex;
	padding-top: var(--space-medium);
	padding-bottom: var(--space-medium);
	align-items: center;
}

a.authors__item {
	text-decoration: none;
}

.authors__item:focus {
	outline: 0;
}

.authors__item:last-child {
	border-bottom: 1px solid var(--color-foreground-light);
}

.authors__item__avatar {
	flex-shrink: 0;
	margin-right: var(--space-xx-small);
}

@media (min-width: 1024px) {
	.authors__item__avatar {
		margin-right: var(--space-x-small);
	}
}

.authors__item__excerpt {
	color: var(--color-foreground-medium);
	flex-grow: 1;
	flex-shrink: 1;
}

@media (max-width: 767px) {
	.authors__item__excerpt {
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		display: block; /* fallback */
		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;
		max-height: 3.25rem;
	}
}

.authors__item[href] .authors__item__excerpt {
	transition: color 96ms linear;
}

@media (prefers-reduced-motion: reduce) {
	.authors__item[href] .authors__item__excerpt {
		transition: none !important;
	}
}

.authors__item[href]:focus .authors__item__excerpt,
.authors__item[href]:hover .authors__item__excerpt {
	color: var(--color-foreground);
}
