@media (min-width: 768px) {
	.doormat {
		box-sizing: border-box;
		padding-right: 1.875rem;
		padding-right: calc(1.875rem + env(safe-area-inset-right));
		padding-left: 1.875rem;
		padding-left: calc(1.875rem + env(safe-area-inset-left));
		width: 100%;
	}
}

@media (min-width: 1440px) {
	.doormat {
		padding-right: calc((100% - 1320px) / 2);
		padding-left: calc((100% - 1320px) / 2);
	}
}

@media (min-width: 768px) {
	.doormat__container {
		display: flex;
		justify-content: center;
	}
}

.doormat__block {
	box-sizing: border-box;
	padding: var(--space-medium) var(--space-xx-small);
}

@media (min-width: 768px) {
	.doormat__block {
		flex-basis: calc((100% - var(--space-x-small)) / 2);
		flex-grow: 0;
		flex-shrink: 0;
		padding: var(--space-small);
	}
}

@media (max-width: 767px) {
	.doormat__block + .doormat__block {
		margin-top: var(--space-x-small);
	}
}

@media (min-width: 768px) {
	.doormat__block + .doormat__block {
		margin-left: var(--space-x-small);
	}
}

.doormat__block__inner {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}

.doormat__title,
.doormat__supertitle,
.doormat__description,
.doormat__cta {
	margin-bottom: 0;
	max-width: 500px;
}

.doormat__title {
	text-transform: none;
}

.doormat__title + .doormat__description,
.doormat__supertitle + .doormat__description {
	margin-top: var(--space-xx-small);
}

.doormat__block:not(.doormat__block--has-theme) .doormat__description {
	opacity: var(--teaser-hover-opacity);
}

.doormat__title__box + .doormat__description {
	margin-top: var(--space-xx-small);
}

.doormat__content + .doormat__cta {
	margin-top: var(--space-x-small);
}
