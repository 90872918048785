/* Table */
.l-site-container--grey-background thead tr,
.l-site-container--grey-background tbody tr:nth-child(odd),
.l-site-container--grey-background thead + tbody tr:nth-child(even) {
	background-color: var(--color-background);
}

.l-site-container--grey-background thead + tbody tr:nth-child(odd) {
	background-color: var(--color-background-light);
}

@media (prefers-color-scheme: dark) {
	.l-site-container--grey-background thead tr,
	.l-site-container--grey-background tbody tr:nth-child(odd),
	.l-site-container--grey-background thead + tbody tr:nth-child(even) {
		background-color: var(--color-background-light);
	}

	.l-site-container--grey-background thead + tbody tr:nth-child(odd) {
		background-color: var(--color-background);
	}
}

/* Site Header (Dark Mode) */
@media (prefers-color-scheme: dark) {
	.l-site-container--grey-background .site-header__wrapper,
	body.is-scrolled .l-site-container--grey-background .site-header__wrapper,
	.l-site-container--grey-background .site-header__navigation__sub-menu,
	body.is-scrolled .l-site-container--grey-background .site-header__navigation__sub-menu {
		background-color: var(--color-background);
	}
}

/* Blocks - Databank, Aside Block Row (Dark Mode) */
@media (prefers-color-scheme: dark) {
	.databank-entity-container__inner,
	.aside-block__row {
		background-color: var(--color-background-light);
	}
}

/* Notice, Banner, Doormat, Card (Theme Grey) */
.l-site-container--grey-background .notice.theme-grey,
.l-site-container--grey-background .banner.theme-grey,
.l-site-container--grey-background .doormat .doormat__block.theme-grey,
.l-site-container--grey-background .card.theme-grey {
	background-color: var(--color-background);
}

/* Site Container */
.l-site-container--grey-background {
	background-color: var(--color-theme-grey);
}

@media (prefers-color-scheme: dark) {
	.l-site-container--grey-background {
		background-color: var(--color-background);
	}
}
