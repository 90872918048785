.sort {
	display: inline-block;
}

@media (min-width: 1024px) {
	.sort {
		display: inline-flex;
		align-items: center;
	}
}

.sort__label {
	margin-right: var(--space-xx-small);
}

@media (max-width: 1023px) {
	.sort__label {
		display: none;
	}
}

.sort__select-wrapper {
	display: block;
	margin-top: -12px;
	position: relative;
	transform: translateY(6px);
	width: 10rem;
}

.sort__select-wrapper .icon-check-down {
	position: absolute;
	top: 50%;
	right: 0.75rem;
	transform: translateY(-50%);
	width: 0.5rem;
	height: 0.5rem;
}

.sort__select {
	appearance: none;
	background-color: transparent;
	border: 1px solid currentColor;
	border-radius: 0;
	box-sizing: border-box;
	color: currentColor;
	display: block;
	padding: 0.5rem;
	width: 100%;
}

.sort__select::-ms-expand {
	display: none;
}
