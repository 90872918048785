.tumblr-item {
}

.tumblr-item__image {
	width: 100%;
	height: 100%;
}

.tumblr-item:hover .tumblr-item__image {
	opacity: var(--teaser-hover-opacity);
}

/* #region Content */
.tumblr-item__content {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	margin-top: 0;
	margin-left: 0;
	padding: 14px;
	position: relative;
	width: 100%;
}

@media (min-width: 1440px) {
	.tumblr-item__content {
		padding: var(--space-xx-small);
	}
}

.tumblr-item__content--with-image {
	margin-top: -14px;
	margin-left: 14px;
	width: calc(100% - 14px);
}

@media (min-width: 1440px) {
	.tumblr-item__content--with-image {
		margin-top: calc(var(--space-xx-small) * -1);
		margin-left: var(--space-xx-small);
		width: calc(100% - var(--space-xx-small));
	}
}
/* #endregion Content */

.tumblr-item__label {
	color: var(--color-alpha-foreground-80);
	margin-bottom: 0;
}

.tumblr-item:hover .tumblr-item__title {
	text-decoration: underline;
	text-decoration-thickness: 1px;
	text-underline-offset: 6px;
}
