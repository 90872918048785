.tumblr-grid-items {
	display: grid;
	grid-row-gap: var(--space-small);
	list-style-type: none;
	margin: 0;
	padding: 0;
}

/* from-xsmall */
@media (min-width: 568px) {
	.tumblr-grid-items {
		grid-row-gap: var(--space-x-small);
		grid-column-gap: var(--space-xx-small);
		grid-template-columns: repeat(2, 1fr);
	}

	@supports (grid-template-rows: masonry) {
		.tumblr-grid-items {
			grid-template-rows: masonry;
		}
	}
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.tumblr-grid-items {
		grid-template-columns: repeat(3, 1fr);
	}
}

/* from-large */
@media (min-width: 1440px) {
	.tumblr-grid-items {
		grid-row-gap: var(--space-medium);
		grid-column-gap: var(--space-small);
		grid-template-columns: repeat(4, 1fr);
	}
}
