.featured-content {
	overflow: hidden;
	position: relative;
	width: 100%;
}

@media (min-width: 1024px) {
	.featured-content {
		display: flex;
		align-items: stretch;
	}
}

@media (max-width: 1023px) {
	.featured-content__main {
		margin-bottom: 1.25rem;
	}
}

@media (min-width: 1024px) {
	.featured-content__main {
		flex-basis: calc(66.6666% - var(--space-x-small));
		flex-grow: 0;
		flex-shrink: 0;
		margin-right: var(--space-x-small);
	}
}

.featured-content__main__item {
	width: 100%;
}

@media (min-width: 1024px) {
	.featured-content__main__item {
		height: 100%;
	}
}

.featured-content__aside {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}

@media (max-width: 767px) {
	.featured-content__aside {
		flex-wrap: nowrap;
		overflow-x: scroll;
	}
}

@media (min-width: 1024px) {
	.featured-content__aside {
		flex-basis: auto;
		flex-grow: 1;
		flex-shrink: 1;
		flex-direction: column;
		align-content: space-between;
		justify-content: space-between;
	}
}

@media (max-width: 1023px) {
	.featured-content__aside__item {
		flex-grow: 0;
		flex-shrink: 0;
	}
}

@media (max-width: 767px) {
	.featured-content__aside__item {
		flex-basis: auto;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.featured-content__aside__item {
		flex-basis: calc(33.3333% - var(--space-xx-small));
	}
}

@media (min-width: 1024px) {
	.featured-content__aside__item {
		width: 100%;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.featured-content__aside__item:not(:last-child) {
		margin-right: var(--space-x-small);
	}
}

@media (min-width: 1024px) {
	.featured-content__aside__item:not(:last-child) {
		border-bottom: 1px solid var(--color-alpha-foreground-25);
		margin-bottom: var(--space-medium);
		padding-bottom: var(--space-medium);
	}
}

@media (max-width: 767px) {
	.featured-content__aside__item__inner {
		box-sizing: content-box;
		padding-left: 0.75rem;
		width: calc((100vw - 2rem) * 0.45);
	}
}

@media (max-width: 767px) {
	.featured-content__aside__item:first-child .featured-content__aside__item__inner {
		padding-left: 0;
	}
}
