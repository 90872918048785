h2 {
	font-family: var(--font-sans);
	font-size: 1.375rem;
	font-weight: var(--font-weight-medium);
	line-height: 1.45;
	margin-top: 0;
	margin-bottom: var(--space-medium);
}

@media (min-width: 1024px) {
	h2 {
		font-size: 1.5rem;
		line-height: 1.495;
	}
}

@media (min-width: 1440px) {
	h2 {
		font-size: 1.625rem;
		line-height: 1.54;
	}
}

h2:last-child {
	margin-bottom: 0;
}

h2:only-child {
	margin-bottom: 0;
}

h2 a:not([class]) {
	border-bottom-width: 2px;
}
