.theme-black {
	background-color: var(--color-black);
}

.theme-brown {
	background-color: var(--color-theme-brown);
}

.theme-crimson {
	background-color: var(--color-theme-crimson);
}

.theme-dark-blue {
	background-color: var(--color-theme-dark-blue);
}

.theme-dark-green {
	background-color: var(--color-theme-dark-green);
}

.theme-grey {
	background-color: var(--color-theme-grey);
}

.theme-light-blue {
	background-color: var(--color-theme-light-blue);
}

.theme-light-green {
	background-color: var(--color-theme-light-green);
}

.theme-orange {
	background-color: var(--color-theme-orange);
}

.theme-pink {
	background-color: var(--color-theme-pink);
}

.theme-red {
	background-color: var(--color-theme-red);
}

.theme-fuchsia-with-black {
	background-color: var(--color-theme-fuchsia);
	color: var(--color-black);
}

@media (prefers-color-scheme: dark) {
	.theme-fuchsia-with-black {
		color: var(--color-white); /* Flip colors for dark color scheme */
	}
}

.theme-fuchsia-with-white {
	background-color: var(--color-theme-fuchsia);
	color: var(--color-white);

	/* Note : not flipping colors here. */
}

.theme-white {
	background-color: var(--color-white);
}

@media (prefers-color-scheme: dark) {
	.theme-white {
		background-color: var(--color-black); /* Flip colors for dark color scheme */
	}
}

.theme-yellow {
	background-color: var(--color-theme-yellow);
}

.theme-grey,
.theme-orange,
.theme-pink,
.theme-white,
.theme-yellow {
	color: var(--color-black);
}

@media (prefers-color-scheme: dark) {
	.theme-grey,
	.theme-orange,
	.theme-pink,
	.theme-white,
	.theme-yellow {
		color: var(--color-white);
	}
}

.theme-black,
.theme-brown,
.theme-crimson,
.theme-dark-blue,
.theme-dark-green,
.theme-light-blue,
.theme-light-green,
.theme-red {
	color: var(--color-white);
}
