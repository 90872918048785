.list-teaser {
}

.list-teaser__wrapper {
	color: currentColor;
	display: block;
	padding-top: var(--space-medium);
	padding-bottom: var(--space-small);
	text-decoration: none;
}

.list-teaser__wrapper:focus {
	outline: 0;
}

@media (min-width: 568px) {
	.list-teaser__wrapper {
		display: flex;
		align-items: flex-start;
	}
}

.list__item:first-child .list-teaser__wrapper {
	padding-top: 0;
}

.archive-bar + .list .list__item:first-child .list-teaser__wrapper {
	padding-top: var(--space-medium);
}

.list-teaser__thumbnail {
	position: relative;
}

@media (max-width: 567px) {
	.list-teaser__thumbnail {
		margin-bottom: var(--space-xx-small);
	}
}

@media (min-width: 568px) {
	.list-teaser__thumbnail {
		flex-shrink: 0;
		margin-right: var(--space-xx-small);
	}
}

@media (min-width: 1024px) {
	.list-teaser__thumbnail {
		margin-right: var(--space-small);
	}
}

.list-teaser__thumbnail::after {
	background-color: var(--color-foreground);
	content: "";
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	transition: opacity 96ms linear;
	width: 100%;
	height: 100%;
	z-index: 2;
}

@media (prefers-reduced-motion: reduce) {
	.list-teaser__thumbnail::after {
		transition: none;
	}
}

.list-teaser__wrapper:focus .list-teaser__thumbnail::after,
.list-teaser__wrapper:hover .list-teaser__thumbnail::after {
	opacity: 0.25;
}

.list-teaser__thumbnail__item {
	border: 1px solid var(--color-alpha-foreground-25);
	box-sizing: border-box;
	display: block;
	opacity: 1;
	position: relative;
	width: 100%;
	height: auto;
	z-index: 1;
}

@media (min-width: 568px) {
	.list-teaser__thumbnail__item {
		width: 255px;
	}
}

@media (min-width: 1024px) {
	.list-teaser__thumbnail__item {
		width: 200px;
	}
}

@media (min-width: 568px) {
	.list-teaser__data {
		flex-grow: 1;
		flex-shrink: 1;
	}
}

@media (min-width: 1024px) {
	.list-teaser__data {
		display: flex;
	}
}

@media (min-width: 1024px) {
	.list-teaser__content {
		flex-grow: 1;
		flex-shrink: 1;
	}
}

.list-teaser__title.list-teaser__title--by-kunsten::before {
	content: "•   ";
	display: inline-block;
	width: var(--space-xx-small);
}

@media (min-width: 1024px) {
	.list-teaser__title.list-teaser__title--by-kunsten::before {
		width: var(--space-x-small);
	}
}

.list-teaser__title__label__box {
	display: inline;
	padding-bottom: 1px;
}

@media (min-width: 1440px) {
	.list-teaser__title__label__box {
		padding-bottom: 2px;
	}
}

.list-teaser__title__label {
	display: inline;
}

.list-teaser__wrapper:hover .list-teaser__title__label,
.list-teaser__wrapper:focus .list-teaser__title__label {
	border-bottom: 1px solid currentColor;
}

.list-teaser__subtitle {
	color: var(--color-foreground-medium);
}

.list-teaser__title + .list-teaser__subtitle {
	margin-top: var(--space-xxx-small);
}

.list-teaser__excerpt {
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	color: var(--color-foreground-dark);
	display: block; /* fallback */
	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
	max-height: 3rem;
}

.list-teaser__title + .list-teaser__excerpt,
.list-teaser__subtitle + .list-teaser__excerpt {
	margin-top: var(--space-xxx-small);
}

.list-teaser__subtitle,
.list-teaser__excerpt {
	transition: color 96ms linear;
}

@media (prefers-reduced-motion: reduce) {
	.list-teaser__subtitle,
	.list-teaser__excerpt {
		transition: none;
	}
}

.list-teaser__wrapper:hover .list-teaser__subtitle,
.list-teaser__wrapper:focus .list-teaser__subtitle,
.list-teaser__wrapper:hover .list-teaser__excerpt,
.list-teaser__wrapper:focus .list-teaser__excerpt {
	color: var(--color-foreground);
}

.list-teaser__meta-list {
	list-style: none;
	margin-top: 0;
	margin-bottom: 0;
	margin-left: 0;
	padding-left: 0;
}

@media (max-width: 1023px) {
	.list-teaser__meta-list {
		margin-top: var(--space-xx-small);
	}
}

@media (min-width: 1024px) {
	.list-teaser__meta-list {
		flex-shrink: 0;
		margin-left: var(--space-large);
		width: 255px;
	}
}
