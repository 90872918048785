.entity-list {
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%;
}

.entity-list__item,
.entity-list__item__link {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
}

.entity-list__item {
	border-bottom: 1px solid var(--color-alpha-foreground-25);
	padding-top: var(--space-xx-small);
	padding-bottom: var(--space-xx-small);
}

.entity-list__item:first-child {
	padding-top: 0;
}

.entity-list__item:last-child {
	border-bottom: 0;
	padding-bottom: 0;
}

.entity-list__item__link {
	color: currentColor;
	text-decoration: none;
}

.entity-list__item__thumbnail-container {
	background-color: var(--color-foreground);
	flex-basis: 40px;
	flex-grow: 0;
	flex-shrink: 0;
	margin-right: var(--space-xx-small);
}

@media (min-width: 1024px) {
	.entity-list__item__thumbnail-container {
		margin-right: var(--space-x-small);
	}
}

.entity-list__item__thumbnail,
.entity-list__item__thumbnail-dummy {
	transition: opacity 96ms linear;
	width: 40px;
	height: 40px;
}

@media (prefers-reduced-motion: reduce) {
	.entity-list__item__thumbnail,
	.entity-list__item__thumbnail-dummy {
		transition: none;
	}
}

.entity-list__item__link:focus .entity-list__item__thumbnail,
.entity-list__item__link:hover .entity-list__item__thumbnail,
.entity-list__item__link:focus .entity-list__item__thumbnail-dummy,
.entity-list__item__link:hover .entity-list__item__thumbnail-dummy {
	opacity: var(--teaser-hover-opacity);
}

.entity-list__item__thumbnail-dummy {
	background-color: var(--color-foreground-light);
}

.entity-list__item__content {
	flex-basis: auto;
	flex-grow: 1;
	flex-shrink: 1;
}

.entity-list__item__description,
.entity-list__item__title {
	font-family: var(--font-sans);
	font-weight: 400;
}

.entity-list__item__title {
	display: inline-block;
}

.entity-list__item__title:not(:last-child) {
	margin-bottom: var(--space-xxx-small);
}

.entity-list__item__title__label {
	display: inline;
	padding-bottom: 1px;
}

.entity-list__item__link:focus .entity-list__item__title__label,
.entity-list__item__link:hover .entity-list__item__title__label {
	border-bottom: 1px solid currentColor;
}

.entity-list__item__description {
	color: var(--color-foreground-dark);
	transition: color 96ms linear;
}

@media (prefers-reduced-motion: reduce) {
	.entity-list__item__description {
		transition: none;
	}
}

@supports (-webkit-line-clamp: 2) {
	.entity-list__item__description {
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		display: block;
		display: -webkit-box;
		overflow: hidden;
	}
}

.entity-list__item__link:focus .entity-list__item__description,
.entity-list__item__link:hover .entity-list__item__description {
	color: var(--color-foreground);
}
