.gallery-teaser {
	margin-bottom: var(--space-medium);
	overflow: hidden;
	padding-top: 1.0625rem;
	position: relative;
	text-align: center;
	width: 100%;
}

.gallery-teaser::after,
.gallery-teaser::before {
	background-color: var(--color-foreground-medium);
	content: "";
	position: absolute;
	height: 1px;
}

.gallery-teaser::after {
	top: 0;
	left: 1.25rem;
	width: calc(100% - 2.5rem);
}

.gallery-teaser::before {
	top: 0.5625rem;
	left: 0.625rem;
	width: calc(100% - 1.25rem);
}

.gallery-teaser:last-child,
.gallery-teaser:only-child {
	margin-bottom: 0;
}

.gallery-teaser__inner {
	box-sizing: border-box;
	overflow: hidden;
	padding: var(--space-medium) 3.5rem;
	position: relative;
	width: 100%;
}

@media (min-width: 1024px) {
	.gallery-teaser__inner {
		padding: var(--space-x-large);
	}
}

@media (min-width: 1280px) {
	.gallery-teaser__inner {
		padding: 7rem;
	}
}

.gallery-teaser__button,
.gallery-teaser__title {
	position: relative;
	z-index: 3;
}

.gallery-teaser__title {
	margin-bottom: 1.125rem;
}

.gallery-teaser__background-container {
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.gallery-teaser__background-container::after {
	background-color: var(--color-alpha-black-10);
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
}

.gallery-teaser__background {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	max-width: none;
	height: auto;
	z-index: 1;
}

@supports (object-fit: cover) {
	.gallery-teaser__background {
		object-fit: cover;
		position: relative;
		top: auto;
		left: auto;
		transform: none;
		height: 100%;
	}
}
