.breadcrumb {
	margin-top: 0.9375rem;
	position: relative;
	z-index: 3;
}

.breadcrumb__list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.breadcrumb__list__item {
	color: var(--color-foreground-dark);
	display: inline-block;
}

.breadcrumb__link {
	color: inherit;
	text-decoration: none;
}

.breadcrumb__link:hover {
	text-decoration: underline;
}
