.sway {
	break-inside: avoid;
	display: block;
	overflow: hidden;
	position: relative;
	width: 100%;
	height: calc((100vw - 1rem - 1rem) / 3 * 2); /* force to 3:2 on .is-style-xlarge */
	max-height: calc(100vh - var(--small-screen-site-header-height));
}

@media (min-width: 768px) {
	.sway {
		height: calc((100vw - 1.875rem - 1.875rem) * 1.516 / 3 * 2); /* force to 3:2 on .is-style-xlarge */
	}
}

@media (min-width: 1280px) {
	.sway {
		max-height: calc(100vh - var(--wide-screen-site-header-height));
	}
}

/* If the user sets the reading time above 5 minutes, in come the sticky elements: */

/* Only visible for screens that are wide and tall enough */
@media (min-height: 541px) and (min-width: 1280px) {
	body.has-sticky-elements .sway {
		max-height: calc(100vh - var(--wide-screen-site-header-height) - 3.75rem - 8.75rem);
	}
}

@supports (grid-template-columns: repeat(12, 1fr)) {
	@media (min-width: 768px) {
		.sway {
			height: calc(((100vw - 1.875rem - 1.875rem - (11 * 1rem)) / 12 * 8 + (7 * 1rem)) * 1.516 / 3 * 2); /* force to 3:2 on .is-style-xlarge */
		}
	}

	@media (min-width: 1440px) {
		.sway {
			height: 878px; /* force to 3:2 on .is-style-xlarge */
		}
	}
}

@media (min-width: 1440px) {
	.l-single__main > *:nth-child(1) .sway,
	.l-single__main > *:nth-child(2) .sway,
	.l-single__main.l-single__main--has-tall-after > *:nth-child(3) .sway,
	.l-single__main.l-single__main--has-tall-after > *:nth-child(4) .sway {
		padding-bottom: 66.6666%;
		height: auto;
		max-height: none;
	}
}

.sway__iframe {
	border: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
