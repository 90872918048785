.language-list {
}

.language-list__item {
	color: currentColor;
	display: inline-block;
	text-decoration: none;
	transition: color 96ms linear;
}

@media (prefers-reduced-motion: reduce) {
	.language-list__item {
		transition: none;
	}
}

.language-list__item:not(:last-child) {
	margin-right: 0.25rem;
}

.language-list__item.language-list__item--active,
.language-list__item:focus,
.language-list__item:hover {
	text-decoration: underline;
}
