.type-e,
.type-e p {
	font-family: var(--font-sans);
	font-size: 1.25rem;
	font-weight: 400;
	line-height: 1.4;
}

@media (min-width: 1024px) {
	.type-e,
	.type-e p {
		font-size: 1.375rem;
		line-height: 1.5;
	}
}

.type-e b,
.type-e strong,
.type-e p b,
.type-e p strong {
	font-weight: var(--font-weight-bold);
}

@media (min-width: 1024px) {
	.type-e a:not([class]),
	.type-e p a:not([class]) {
		border-bottom-width: 2px;
	}
}

@media (min-width: 1440px) {
	.type-e a:not([class]),
	.type-e p a:not([class]) {
		padding-bottom: 1px;
	}
}
