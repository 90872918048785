.cite-author {
	display: flex;
	align-items: center;
}

.cite-author__avatar {
	background-color: var(--color-foreground);
	border-radius: 50%;
	color: var(--color-background);
	flex-shrink: 0;
	line-height: 3.5rem;
	overflow: hidden;
	position: relative;
	text-align: center;
	text-transform: uppercase;
	transition: color 96ms linear;
	width: 3.5rem;
	height: 3.5rem;
}

@media (prefers-reduced-motion: reduce) {
	.cite-author__avatar {
		transition: none;
	}
}

@media (min-width: 1024px) {
	.cite-author__avatar {
		line-height: 3.75rem;
		width: 3.75rem;
		height: 3.75rem;
	}
}

a .cite-author__avatar::before {
	background-color: var(--color-foreground);
	border-radius: 50%;
	content: "";
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	transition: opacity 96ms linear;
	width: 100%;
	height: 100%;
}

@media (prefers-reduced-motion: reduce) {
	a .cite-author__avatar::before {
		transition: none !important;
	}
}

a:focus .cite-author__avatar::before,
a:hover .cite-author__avatar::before {
	opacity: 0.25;
}

.cite-author__avatar__media {
	border-radius: 50%;
	display: inline-block;
	width: 3.5rem;
	height: auto;
}

@media (prefers-reduced-motion: reduce) {
	.cite-author__avatar__media {
		transition: none;
	}
}

@media (min-width: 1024px) {
	.cite-author__avatar__media {
		width: 3.75rem;
	}
}

.cite-author__data {
	flex-grow: 1;
	margin-left: var(--space-xx-small);
}

.cite-author__title {
	border-bottom: 1px solid transparent;
	transition: border-bottom-color 96ms linear;
}

@media (prefers-reduced-motion: reduce) {
	.cite-author__title {
		transition: none;
	}
}

a:focus .cite-author__title,
a:hover .cite-author__title {
	border-bottom: 1px solid currentColor;
}

.cite-author__subtitle {
	display: block;
}

.cite-author__subtitle {
	color: var(--color-foreground-medium);
}
