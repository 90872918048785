.l-landing-posts-preview {
	width: 100%;
}

@media (min-width: 768px) {
	.l-landing-posts-preview {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: center;
	}
}

.l-landing-posts-preview__title {
	display: inline-block;
	margin-bottom: var(--space-xx-small);
}

@media (min-width: 768px) {
	.l-landing-posts-preview__title {
		flex-basis: auto;
		flex-grow: 0;
		flex-shrink: 0;
		margin-right: var(--space-xx-small);
		margin-bottom: var(--space-xxx-small);
		order: 1;
		max-width: 100%;
	}
}

.l-landing-posts-preview__posts {
	width: 100%;
}

@media (min-width: 768px) {
	.l-landing-posts-preview__posts {
		order: 3;
		padding-top: var(--space-x-small);
	}
}

@media (max-width: 767px) {
	.l-landing-posts-preview__posts:not(:last-child) {
		margin-bottom: var(--space-small);
	}
}

.l-landing-posts-preview__link {
	color: var(--color-foreground);
	display: inline-block;
	text-decoration: none;
}

.l-landing-posts-preview__link:focus {
	outline: 0;
}

@media (max-width: 767px) {
	.l-landing-posts-preview__link {
		/* .type-h */
		background-color: var(--color-alpha-foreground-0);
		border: 1px solid var(--color-foreground);
		border-radius: 0;
		box-shadow: none;
		box-sizing: border-box;
		font-family: var(--font-sans);
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.63;
		padding: 0.4375rem 1rem;
		text-align: center;
		transition:
			background-color 64ms linear,
			color 64ms linear;
		width: 100%;
		max-width: 100%;
	}

	@media (prefers-reduced-motion: reduce) {
		.l-landing-posts-preview__link {
			transition: none;
		}
	}

	.l-landing-posts-preview__link:focus,
	.l-landing-posts-preview__link:hover {
		background-color: var(--color-alpha-foreground-100);
		color: var(--color-background);
	}
}

@media (min-width: 768px) {
	.l-landing-posts-preview__link {
		/* .type-g */
		flex-basis: auto;
		flex-grow: 0;
		flex-shrink: 0;
		font-family: var(--font-book);
		font-size: 0.8125rem;
		font-weight: 400;
		line-height: 1.85;
		margin-bottom: var(--space-xxx-small);
		opacity: 0.5;
		order: 2;
		transform: translateY(3px); /* visual alignment with title */
		transition: opacity 64ms linear;
	}

	@media (prefers-reduced-motion: reduce) {
		.l-landing-posts-preview__link {
			transition: none;
		}
	}

	@media (min-width: 1024px) {
		.l-landing-posts-preview__link {
			font-size: 0.875rem;
			line-height: 1.71;
		}
	}

	.l-landing-posts-preview__link:focus,
	.l-landing-posts-preview__link:hover {
		opacity: 1;
	}
}

@media (min-width: 1024px) {
	.l-landing-posts-preview__link {
		transform: translateY(4px); /* visual alignment with title */
	}
}

.theme-black .l-landing-posts-preview__link,
.theme-brown .l-landing-posts-preview__link,
.theme-crimson .l-landing-posts-preview__link,
.theme-dark-blue .l-landing-posts-preview__link,
.theme-dark-green .l-landing-posts-preview__link,
.theme-light-blue .l-landing-posts-preview__link,
.theme-light-green .l-landing-posts-preview__link,
.theme-red .l-landing-posts-preview__link,
.theme-fuchsia-with-white .l-landing-posts-preview__link {
	color: var(--color-white);
}
