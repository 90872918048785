.team-member-teaser {
}

.team-member-teaser__wrapper {
	color: currentColor;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	text-decoration: none;
}

.team-member-teaser__wrapper:focus {
	outline: 0;
}

.team-member-teaser__avatar {
	background-color: var(--color-foreground);
	border-radius: 50%;
	color: var(--color-background);
	flex-shrink: 0;
	line-height: 3.75rem;
	overflow: hidden;
	position: relative;
	text-align: center;
	text-transform: uppercase;
	transition: color 96ms linear;
	width: 3.75rem;
	height: 3.75rem;
}

@media (min-width: 1024px) {
	.team-member-teaser__avatar {
		line-height: 5.625rem;
		width: 5.625rem;
		height: 5.625rem;
	}
}

.team-member-teaser__avatar::before {
	background-color: var(--color-foreground);
	border-radius: 50%;
	content: "";
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	transition: opacity 96ms linear;
	width: 100%;
	height: 100%;
}

@media (prefers-reduced-motion: reduce) {
	.team-member-teaser__avatar::before {
		transition: none !important;
	}
}

a:focus .team-member-teaser__avatar::before,
a:hover .team-member-teaser__avatar::before {
	opacity: 0.25;
}

.team-member-teaser__avatar__media {
	border-radius: 50%;
	display: inline-block;
	width: 3.75rem;
	height: auto;
}

@media (min-width: 1024px) {
	.team-member-teaser__avatar__media {
		width: 5.625rem;
	}
}

.team-member-teaser__data {
	flex-grow: 1;
	margin-left: var(--space-xx-small);
}

@media (min-width: 1024px) {
	.team-member-teaser__data {
		margin-left: var(--space-x-small);
	}
}

.team-member-teaser__data__title__box {
	display: inline;
	padding-bottom: 1px;
}

@media (min-width: 1440px) {
	.team-member-teaser__data__title__box {
		padding-bottom: 2px;
	}
}

.team-member-teaser__data__title {
	display: inline;
	transition: border-bottom-color 96ms linear;
}

@media (prefers-reduced-motion: reduce) {
	.team-member-teaser__data__title {
		transition: none !important;
	}
}

a:focus .team-member-teaser__data__title,
a:hover .team-member-teaser__data__title {
	border-bottom: 1px solid currentColor;
}

.team-member-teaser__data__subtitle {
	display: block;
	opacity: 0.5;
}
