.archive-bar {
	border-bottom: 1px solid var(--color-foreground-light);
	display: flex;
	padding-bottom: var(--space-medium);
	align-items: center;
}

.archive-bar__column {
	flex-grow: 1;
}

.archive-bar__column:not(:first-child):not(:last-child) {
	text-align: center;
}

.archive-bar__column:last-child {
	text-align: right;
}
