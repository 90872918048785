.l-error {
	box-sizing: border-box;
	display: flex;
	padding: 1rem;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100vh;
}

@media (min-width: 768px) {
	.l-error {
		padding: 2rem;
	}
}

.l-error .icon {
	margin-bottom: var(--space-medium);
	max-height: 1.375rem;
}

@media (prefers-color-scheme: dark) {
	.l-error .icon {
		filter: invert(1);
	}
}

.l-error .icon.icon-logo-fai-double-line {
	max-height: 2.5rem;
}

.l-error__main {
	text-align: center;
	max-width: 40.5rem;
}
