h3 {
	font-family: var(--font-sans);
	font-size: 1.125rem;
	font-weight: var(--font-weight-bold);
	line-height: 1.75rem;
	margin-top: 0;
	margin-bottom: var(--space-medium);
	text-transform: none;
}

@media (min-width: 1024px) {
	h3 {
		font-size: 1.25rem;
		line-height: 1.875rem;
	}
}

h3:last-child {
	margin-bottom: 0;
}

h3:only-child {
	margin-bottom: 0;
}

h3 b,
h3 strong {
	font-family: var(--font-sans);
	font-weight: var(--font-weight-bold);
}
