.downloads {
}

.downloads__title__box {
	margin-bottom: var(--space-small);
}

.downloads__item {
	border-bottom: 1px solid var(--color-foreground-light);
	color: currentColor;
	display: flex;
	padding-top: var(--space-xx-small);
	padding-bottom: var(--space-xxx-small);
	justify-content: flex-end;
	align-items: flex-end;
	text-decoration: none;
	transition: border-bottom-color 96ms linear;
}

@media (prefers-reduced-motion: reduce) {
	.downloads__item {
		transition: none;
	}
}

.downloads__item:focus {
	outline: 0;
}

.downloads__item:focus,
.downloads__item:hover {
	border-bottom-color: var(--color-foreground);
}

.downloads__item__title {
	flex-grow: 1;
	flex-shrink: 1;
	/* stylelint-disable-next-line declaration-property-value-keyword-no-deprecated */
	word-break: break-word;
}

.downloads__item__meta {
	flex-shrink: 0;
	margin-left: var(--space-xx-small);
}

.downloads__item__meta .icon-arrow-down {
	display: inline-block;
	vertical-align: middle;
	width: 0.625rem;
	height: 0.625rem;
}
