.databank-entity-container {
	box-shadow: 0 0 0.25rem 0 var(--color-alpha-foreground-50);
}

.databank-entity-container:not(:last-child) {
	margin-bottom: var(--space-medium);
}

@supports (filter: drop-shadow(0 0 10px #000000)) and (clip-path: polygon(0 0, 100% 0, 100% 100%)) {
	.databank-entity-container {
		box-shadow: none;
		filter: drop-shadow(0 0 0.125rem var(--color-alpha-foreground-50));
	}
}

.databank-entity-container__inner {
	background-color: var(--color-background);
	box-sizing: border-box;
	padding: var(--space-xx-small);
	width: 100%;
}

@media (min-width: 1024px) {
	.databank-entity-container__inner {
		padding-top: var(--space-x-small);
		padding-right: var(--space-x-small);
		padding-left: var(--space-x-small);
	}
}

@supports (filter: drop-shadow(0 0 10px #000000)) and (clip-path: polygon(0 0, 100% 0, 100% 100%)) {
	.databank-entity-container__inner {
		/** This clip-path based on footnote text and style.
		 * If either of these change, it needs to be updated.
		 *
		 * Shape:
		 *
		 * |-------------|
		 * |             |
		 * |             |
		 * |             |
		 * |             |
		 * |      -------|
		 * |-----|
		 *
		 */
		clip-path: polygon(0 0, 100% 0, 100% calc(100% - 48px), 190px calc(100% - 48px), 190px 100%, 0 100%);
	}

	@media (min-width: 1024px) {
		.databank-entity-container__inner {
			clip-path: polygon(0 0, 100% 0, 100% calc(100% - 50px), 224px calc(100% - 50px), 224px 100%, 0 100%);
		}
	}
}

.databank-entity-container__title__box {
	margin-bottom: var(--space-xx-small);
}

@media (min-width: 1024px) {
	.databank-entity-container__title__box {
		margin-bottom: var(--space-x-small);
	}
}

.databank-entity-container__title {
	font-family: var(--font-sans);
	font-size: 1.25rem;
	font-weight: var(--font-weight-medium);
	line-height: 1.5;
	text-transform: none;
}

/** Changes done here will influence the clip-path
 * set above in this file. Update accordingly.
 */
.databank-entity-container__footnote {
	color: var(--color-foreground-medium);
	display: inline-block;
	font-family: var(--font-mono);
	font-size: 0.625rem;
	line-height: 1.65;
	padding-top: var(--space-small);
}

@media (min-width: 1024px) {
	.databank-entity-container__footnote {
		font-size: 0.6875rem;
		line-height: 1.5;
	}
}
