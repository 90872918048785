.sticky-suggestions {
	background-color: var(--color-background);
	color: var(--color-foreground);
	position: fixed;
	top: var(--small-screen-site-header-height);
	left: 0;
	transform: translateY(-100%) translateY(-120px) translateY(-1px) translateZ(0);
	transition: transform 560ms cubic-bezier(0.44, 0, 0, 1);
	width: 100%;
	z-index: 9995;
}

@media (min-width: 1280px) {
	.sticky-suggestions {
		top: var(--wide-screen-site-header-height);
	}
}

@media (prefers-reduced-motion: reduce) {
	.sticky-suggestions {
		display: none;
	}
}

body.is-scrolled-between-header-and-footer.is-scrolled-up .sticky-suggestions {
	transform: translateY(-1px) translateZ(0);
}

/* Don't show for screens that arent wide and/or tall enough */
@media (max-height: 540px), (max-width: 1279px) {
	.sticky-suggestions {
		display: none;
	}
}

.has-open-gallery .sticky-suggestions {
	display: none;
}

.l-styleguide .sticky-suggestions {
	position: relative;
	transform: none;
}

.sticky-suggestions__wrapper {
	border-bottom: 1px solid var(--color-foreground-light);
	display: flex;
	padding-top: var(--space-medium);
	padding-bottom: var(--space-medium);
	align-items: flex-start;
}

.sticky-suggestions__item {
	flex-basis: calc((100% - (3 * var(--space-small))) / 4);
	flex-grow: 0;
	flex-shrink: 0;
}

.sticky-suggestions__item + .sticky-suggestions__item {
	margin-left: var(--space-small);
}

.sticky-suggestions__link {
	color: currentColor;
	display: flex;
	align-items: flex-start;
	text-decoration: none;
}

.sticky-suggestions__thumbnail-container {
	background-color: var(--color-alpha-foreground-25);
	flex-basis: 56px;
	flex-grow: 0;
	flex-shrink: 0;
	margin-right: var(--space-xx-small);
	overflow: hidden;
	position: relative;
	transition: opacity 96ms linear;
	width: 56px;
	height: 56px;
}

.sticky-suggestions__link:focus .sticky-suggestions__thumbnail-container,
.sticky-suggestions__link:hover .sticky-suggestions__thumbnail-container {
	opacity: var(--teaser-hover-opacity);
}

.sticky-suggestions__thumbnail {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}

@supports (object-fit: cover) {
	.sticky-suggestions__thumbnail {
		object-fit: cover;
		height: 100%;
	}
}

.sticky-suggestions__content {
	flex-basis: auto;
	flex-grow: 1;
	flex-shrink: 1;
	transform: translateY(-2px);
}

.sticky-suggestions__meta,
.sticky-suggestions__title {
	line-height: 1.45;
}

.sticky-suggestions__meta {
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	display: block; /* fallback */
	display: -webkit-box;
	hyphens: auto;
	opacity: 0.5;
	overflow: hidden;
	text-overflow: ellipsis;
	max-height: 1.25rem;
	word-break: auto-phrase;
}

.sticky-suggestions__title {
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	display: block; /* fallback */
	display: -webkit-box;
	hyphens: auto;
	overflow: hidden;
	text-overflow: ellipsis;
	max-height: 3.75rem;
	word-break: auto-phrase;
}

.sticky-suggestions__meta + .sticky-suggestions__title {
	-webkit-line-clamp: 2;
	max-height: 2.5rem;
}

.sticky-suggestions__title__label {
	border-bottom: 1px solid transparent;
	display: inline;
	transition: border-color 96ms linear;
}

.sticky-suggestions__link:focus .sticky-suggestions__title__label,
.sticky-suggestions__link:hover .sticky-suggestions__title__label {
	border-color: currentColor;
}
