.wrapper {
	box-sizing: border-box;
	padding-right: 1rem;
	padding-right: calc(1rem + env(safe-area-inset-right));
	padding-left: 1rem;
	padding-left: calc(1rem + env(safe-area-inset-left));
	width: 100%;
}

@media (min-width: 768px) {
	.wrapper {
		padding-right: 1.875rem;
		padding-right: calc(1.875rem + env(safe-area-inset-right));
		padding-left: 1.875rem;
		padding-left: calc(1.875rem + env(safe-area-inset-left));
	}
}

@media (min-width: 1440px) {
	.wrapper {
		padding-right: calc((100% - 1320px) / 2);
		padding-left: calc((100% - 1320px) / 2);
	}
}

@media (min-width: 768px) {
	.wrapper.wrapper--small {
		padding-right: 16.6666%;
		padding-left: 16.6666%;
	}
}

@media (min-width: 1440px) {
	.wrapper.wrapper--small {
		padding-right: calc((100% - 660px) / 2);
		padding-left: calc((100% - 660px) / 2);
	}
}
