/*
 * SITE FOOTER STRUCTURE
 *
 * Components:
 *
 *  A → Background (if available)
 *  B → Banner (if available)
 *  C → Body
 *    - C1 → Logo (large screens only)
 *    - C2 → Navigation
 *    - C3 → Social navigation
 *    - C4 → Flanders logo
 *
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 * Mobile screens (up to 768px)
 *
 * |---------------------------|
 * | A |-------------------|   |
 * |   |                   |   |
 * |   |         B         |   |
 * |   |                   |   |
 * |   |-------------------|   |
 * |   |        C2         |   |
 * |   |-------------------|   |
 * |   |        C3         |   |
 * |   |-------------------|   |
 * |   |        C4         |   |
 * |   |-------------------|   |
 *
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 * Small screens (768px up to 1440px)
 *
 * |---------------------------|
 * | A |-------------------|   |
 * |   |                   |   |
 * |   |         B         |   |
 * |   |                   |   |
 * |   |-------------------|   |
 * |   |        C2         |   |
 * |   |-------------------|   |
 * |   |     C3     |  C4  |   |
 * |   |-------------------|   |
 *
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 * Large screens (1440px and up)
 *
 * |---------------------------|
 * | A |-------------------|   |
 * |   |                   |   |
 * |   |         B         |   |
 * |   |                   |   |
 * |   |-------------------|   |
 * |   |    C1   |   C2    |   |
 * |   |-------------------|   |
 * |   |     C3     |  C4  |   |
 * |   |-------------------|   |
 *
 */

.site-footer {
	position: relative;
	width: 100%;
}

/* ------ A Background ------ */
.site-footer__background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.site-footer__background__attachment {
	display: block;
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	width: auto;
	max-width: none;
	height: 100%;
}

@supports (object-fit: cover) {
	.site-footer__background__attachment {
		object-fit: cover;
		object-position: top center;
		position: relative;
		top: auto;
		left: auto;
		transform: none;
		width: 100%;
	}
}

/* -------- A End --------- */

/* - B + C General settings - */
.site-footer__banner,
.site-footer__body {
	margin-right: auto;
	margin-left: auto;
	position: relative;
	width: calc(100% - 2rem);
	z-index: 3;
}

@media (min-width: 768px) {
	.site-footer__banner,
	.site-footer__body {
		width: calc(100% - 3.75rem);
		width: calc(100% - 3.75rem - env(safe-area-inset-left) - env(safe-area-inset-right));
	}
}

@media (min-width: 1440px) {
	.site-footer__banner,
	.site-footer__body {
		width: 1320px;
	}
}

/* -------- B Banner -------- */
.site-footer__banner {
	padding-top: var(--space-medium);
	padding-bottom: var(--space-medium);
	text-align: center;
}

@media (min-width: 1440px) {
	.site-footer__banner {
		padding-top: var(--space-x-large);
		padding-bottom: var(--space-x-large);
	}
}

.site-footer__banner__inner {
	width: 100%;
}

@media (min-width: 768px) {
	.site-footer__banner__inner {
		margin-right: auto;
		margin-left: auto;
		width: 66.6666%;
	}
}

@media (min-width: 1440px) {
	.site-footer__banner__inner {
		width: 50%;
	}
}

.site-footer__banner__inner p {
	display: inline-block;
	width: 100%;
}

.site-footer__banner__inner p:not(:last-child) {
	margin-bottom: var(--space-xx-small);
}

@media (min-width: 1440px) {
	.site-footer__banner__inner p:not(:last-child) {
		margin-bottom: var(--space-small);
	}
}

/* -------- B End --------- */

/* -------- C Body -------- */
.site-footer__body {
	background-color: var(--color-background);
	box-sizing: border-box;
	color: var(--color-foreground);
	overflow: hidden;
}

@media (min-width: 768px) {
	.site-footer__body {
		display: flex;
		flex-wrap: wrap;
	}
}

.site-footer__body:only-child {
	border-top: 1px solid var(--color-alpha-foreground-25);
	border-right: 1px solid var(--color-alpha-foreground-25);
	border-left: 1px solid var(--color-alpha-foreground-25);
}

/* -- C General settings -- */
@media (min-width: 768px) {
	.site-footer__body__flanders-logo-container,
	.site-footer__body__navigation-container__inner,
	.site-footer__body__social-navigation-container__inner {
		box-sizing: border-box;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		height: 100%;
	}
}

.site-footer__body__logos-container,
.site-footer__body__logo-container,
.site-footer__body__navigation-container,
.site-footer__body__social-navigation-container {
	box-sizing: border-box;
}

@media (max-width: 767px) {
	.site-footer__body__logos-container,
	.site-footer__body__logo-container,
	.site-footer__body__navigation-container,
	.site-footer__body__social-navigation-container {
		width: 100%;
	}
}

/* ---- C Link styles ----- */
.site-footer__flanders-logo,
.site-footer__union-logo,
.site-footer__logo,
.site-footer__navigation__link {
	color: currentColor;
	text-decoration: none;
	transition: opacity 96ms linear;
}

@media (prefers-reduced-motion: reduce) {
	.site-footer__flanders-logo,
	.site-footer__union-logo,
	.site-footer__logo,
	.site-footer__navigation__link {
		transition: none;
	}
}

.site-footer__flanders-logo:focus,
.site-footer__union-logo:focus,
.site-footer__logo:focus,
.site-footer__navigation__link:focus {
	outline: 0;
}

.site-footer__flanders-logo:hover,
.site-footer__union-logo:hover,
.site-footer__logo:hover,
.site-footer__navigation__link:hover {
	cursor: pointer;
}

.site-footer__flanders-logo:focus,
.site-footer__flanders-logo:hover,
.site-footer__union-logo:focus,
.site-footer__union-logo:hover,
.site-footer__logo:focus,
.site-footer__logo:hover,
.site-footer__navigation__link:focus,
.site-footer__navigation__link:hover {
	opacity: 0.5;
}

/* -------- C1 Logo -------- */
@media (max-width: 1439px) {
	.site-footer__body__logo-container {
		display: none;
	}
}

@media (min-width: 1440px) {
	.site-footer__body__logo-container {
		flex-basis: 50%;
		flex-grow: 1;
		flex-shrink: 1;
	}
}

@media (min-width: 1440px) {
	.site-footer__body__logo-container:not(:last-child) {
		border-bottom: 1px solid var(--color-alpha-foreground-25);
	}
}

@media (min-width: 1440px) {
	.site-footer__body__logo-container__inner {
		padding: var(--space-medium) 0 var(--space-medium) var(--space-medium);
	}
}

.site-footer__logo {
	display: block;
	transform: translateY(0.375rem); /* match navigation lineheight */
	width: auto;
	height: auto;
}

.site-footer__logo .icon {
	display: block;
	fill: currentColor;
	width: 195px;
	height: 20px;
}

.site-footer__logo .icon.icon-logo-fai-double-line {
	width: 184px;
	height: 43px;
}

/* -------- C1 End --------- */

/* ----- C2 Navigation ----- */
@media (min-width: 768px) {
	.site-footer__body__navigation-container {
		display: flex;
		flex-basis: 100%;
		flex-grow: 0;
		flex-shrink: 0;
		justify-content: flex-start;
		align-items: flex-start;
	}
}

@media (min-width: 1440px) {
	.site-footer__body__navigation-container {
		flex-basis: 50%;
		flex-grow: 1;
	}
}

.site-footer__body__navigation-container:not(:last-child) {
	border-bottom: 1px solid var(--color-alpha-foreground-25);
}

.site-footer__body__navigation-container__inner {
	padding: 1.25rem;
}

@media (min-width: 768px) {
	.site-footer__body__navigation-container__inner {
		padding: var(--space-x-small);
	}
}

@media (min-width: 1440px) {
	.site-footer__body__navigation-container__inner {
		padding: var(--space-medium) var(--space-medium) var(--space-medium) 0;
	}
}

.site-footer__navigation {
	list-style: none;
	margin: 0;
	padding: 0;
}

@media (min-width: 768px) {
	.site-footer__navigation {
		flex-basis: calc(33% - 0.75rem);
		flex-grow: 0;
		flex-shrink: 0;
	}
}

@media (min-width: 768px) {
	.site-footer__navigation:not(:last-child) {
		margin-right: 1.125rem;
	}
}

/* -------- C2 End --------- */

/* -- C3 Social navigation - */
@media (min-width: 768px) {
	.site-footer__body__social-navigation-container {
		flex-basis: auto;
		flex-grow: 1;
		flex-shrink: 0;
	}
}

@media (max-width: 767px) {
	.site-footer__body__social-navigation-container:not(:last-child) {
		border-bottom: 1px solid var(--color-alpha-foreground-25);
	}
}

@media (min-width: 768px) {
	.site-footer__body__social-navigation-container:not(:last-child) {
		border-right: 1px solid var(--color-alpha-foreground-25);
	}
}

.site-footer__body__social-navigation-container__inner {
	padding: 0.625rem 1.25rem;
}

@media (min-width: 768px) {
	.site-footer__body__social-navigation-container__inner {
		padding: 0.875rem var(--space-x-small);
	}
}

@media (min-width: 1440px) {
	.site-footer__body__social-navigation-container__inner {
		padding: 1.875rem var(--space-medium);
	}
}

/* -------- C3 End --------- */

/* --- C4 Flanders + Union logo ---- */
.site-footer__body__logos-container {
	box-sizing: border-box;
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
	height: 100%;
}

@media (max-width: 767px) {
	.site-footer__body__logos-container {
		width: 100%;
	}
}

@media (min-width: 768px) {
	.site-footer__body__logos-container {
		display: flex;
		flex-basis: auto;
		flex-grow: 0;
		flex-shrink: 1;
		align-items: center;
	}
}

/* Flanders */
.site-footer__body__flanders-logo-container {
	padding-top: 1.25rem;
	padding-right: 1.25rem;
	padding-left: 1.25rem;
}

@media (min-width: 768px) {
	.site-footer__body__flanders-logo-container {
		padding-top: 0;
		padding-right: var(--space-x-small);
		padding-left: var(--space-x-small);
	}
}

@media (min-width: 1440px) {
	.site-footer__body__flanders-logo-container {
		padding-right: var(--space-medium);
		padding-left: var(--space-medium);
	}
}

@media (prefers-color-scheme: dark) {
	.site-footer__body__flanders-logo-container {
		filter: invert(1);
	}
}

.site-footer__flanders-logo {
	display: block;
	width: 144px;
	height: 61px;
}

.site-footer__flanders-logo__image {
	display: block;
	fill: currentColor;
	width: 100%;
	height: 100%;
}

/* Union */
.site-footer__body__union-logo-container {
	padding-right: 1.25rem;
	padding-left: 1.25rem;
}

@media (min-width: 768px) {
	.site-footer__body__union-logo-container {
		padding-right: var(--space-x-small);
		padding-left: var(--space-x-small);
	}
}

@media (min-width: 1440px) {
	.site-footer__body__union-logo-container {
		padding-right: var(--space-medium);
		padding-left: var(--space-medium);
	}
}

@media (prefers-color-scheme: dark) {
	.site-footer__body__union-logo-container {
		filter: invert(1);
	}
}

.site-footer__union-logo {
	display: block;
	width: 190px;
	height: 40px;
}

.site-footer__union-logo__image {
	display: block;
	width: 100%;
	height: 100%;
}
/* -------- C4 End --------- */

/* -------- C End ---------- */
