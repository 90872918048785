.thumb-below {
	border-bottom: 1px solid var(--color-foreground-light);
	padding-top: var(--space-medium);
	padding-bottom: var(--space-medium);
	text-align: center;
}

@media (min-width: 1024px) {
	.thumb-below {
		padding-top: var(--space-x-large);
		padding-bottom: var(--space-x-large);
	}
}

.thumb-below__wrapper {
	display: inline-flex;
	flex-direction: column;
	align-items: stretch;
}

.thumb-below__media {
	display: block;
	margin: 0 auto;
	width: 100%;
	max-width: calc(100vw - 2rem);
	max-height: calc(100vw - 2rem);
}

/*
	 * See thumb-below.twig for these values.
	 * Changes there should also be done here!
	 * (breakpoints, max-height, max-width)
	 */
@media (min-width: 320px) {
	.thumb-below__media {
		max-width: 536px;
		max-height: 536px;
	}
}

@media (min-width: 568px) {
	.thumb-below__media {
		max-width: 708px;
		max-height: 708px;
	}
}

@media (min-width: 768px) {
	.thumb-below__media {
		max-width: 708px;
		max-height: 708px;
	}
}

@media (min-width: 1024px) {
	.thumb-below__media {
		max-width: 964px;
		max-height: 600px;
	}
}

@media (min-width: 1280px) {
	.thumb-below__media {
		max-width: 1220px;
		max-height: 735px;
	}
}

@media (min-width: 1440px) {
	.thumb-below__media {
		max-width: 1320px;
		max-height: 800px;
	}
}

.thumb-below__caption {
	margin-top: var(--space-x-small);
	opacity: 0.5;
	text-align: left;
	max-width: 290px;
}

@media (min-width: 568px) {
	.thumb-below__caption {
		max-width: 536px;
	}
}

@media (min-width: 768px) {
	.thumb-below__caption {
		max-width: 708px;
	}
}

@media (min-width: 1024px) {
	.thumb-below__caption {
		max-width: 964px;
	}
}
