@media (min-width: 768px) {
	.l-single {
		padding-right: 17%;
		padding-left: 17%;
	}
}

@supports (grid-template-columns: repeat(12, 1fr)) {
	@media (min-width: 768px) {
		.l-single {
			display: grid;
			grid-row-gap: var(--space-medium);
			grid-column-gap: 1rem;
			grid-template-columns: repeat(12, 1fr);
			padding-right: 0;
			padding-left: 0;
		}
	}

	@media (min-width: 1440px) {
		.l-single {
			grid-column-gap: 1.5rem;
		}
	}
}

.l-single__before {
	margin-bottom: var(--space-medium);
	position: relative;
	z-index: 0;
}

@supports (grid-template-columns: repeat(12, 1fr)) {
	.l-single__before {
		grid-column-start: 1;
		grid-column-end: span 1;
	}

	@media (min-width: 768px) {
		.l-single__before {
			grid-column-start: 3;
			grid-column-end: span 8;
			margin-bottom: 0;
		}
	}

	@media (min-width: 1440px) {
		.l-single__before {
			grid-column-start: 1;
			grid-column-end: span 3;
		}
	}
}

.l-single__after {
	margin-bottom: var(--space-medium);
	position: relative;
	z-index: 0;
}

@supports (grid-template-columns: repeat(12, 1fr)) {
	.l-single__after {
		grid-column-start: 1;
		grid-column-end: span 1;
	}

	@media (min-width: 768px) {
		.l-single__after {
			grid-column-start: 3;
			grid-column-end: span 8;
			margin-bottom: 0;
		}
	}

	@media (min-width: 1440px) {
		.l-single__after {
			grid-column-start: 10;
			grid-column-end: span 3;
			padding-left: 2.5rem;
		}
	}
}

.l-single__intro {
	margin-bottom: var(--space-medium);
}

.l-single__main {
	margin-bottom: var(--space-medium);
	position: relative;
	min-width: 0;
	z-index: 10;
}

@supports (grid-template-columns: repeat(12, 1fr)) {
	.l-single__main {
		grid-column-start: 1;
		grid-column-end: span 1;
	}

	@media (min-width: 768px) {
		.l-single__main {
			grid-column-start: 3;
			grid-column-end: span 8;
			margin-bottom: 0;
		}
	}

	@media (min-width: 1440px) {
		.l-single__main {
			grid-column-start: 4;
			grid-column-end: span 6;
		}
	}
}

/* Make sure wide blocks don't overlap with tall after */
@media (min-width: 1440px) {
	.l-single__main > *:nth-child(1),
	.l-single__main > *:nth-child(2),
	.l-single__main.l-single__main--has-tall-after > *:nth-child(3),
	.l-single__main.l-single__main--has-tall-after > *:nth-child(4) {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
}

@media (min-width: 768px) {
	.l-single__main > .is-style-large {
		margin-right: -13%;
		margin-left: -13%;
	}
}

@supports (grid-template-columns: repeat(12, 1fr)) {
	@media (min-width: 768px) {
		.l-single__main > .is-style-large {
			margin-right: -13%;
			margin-left: -13%;
		}
	}

	@media (min-width: 1440px) {
		.l-single__main > .is-style-large {
			margin-right: -34.5%;
			margin-left: -34.5%;
		}
	}
}

@media (min-width: 768px) {
	.l-single__main > .is-style-xlarge {
		margin-right: -25.8%;
		margin-left: -25.8%;
	}
}

@supports (grid-template-columns: repeat(12, 1fr)) {
	@media (min-width: 768px) {
		.l-single__main > .is-style-xlarge {
			margin-right: -25.8%;
			margin-left: -25.8%;
		}
	}

	@media (min-width: 1440px) {
		.l-single__main > .is-style-xlarge {
			margin-right: -51.7%;
			margin-left: -51.7%;
		}
	}
}

.l-single__generic-row {
	margin-bottom: var(--space-medium);
}

@media (min-width: 768px) {
	.l-single__generic-row.is-style-xlarge {
		margin-top: var(--space-large);
		margin-bottom: var(--space-large);
	}
}

.l-single__generic-row:last-child,
.l-single__generic-row:only-child {
	margin-bottom: 0;
}
