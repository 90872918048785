.notice {
	box-sizing: border-box;
	padding: var(--space-medium) var(--space-xx-small);
}

@media (min-width: 768px) {
	.notice {
		flex-basis: calc((100% - var(--space-x-small)) / 2);
		flex-grow: 0;
		flex-shrink: 0;
		padding: var(--space-small);
	}
}

.notice.notice--error {
	border: 2px solid var(--color-error);
}

.notice__inner {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}

.notice__title {
	margin-bottom: 0;
	text-transform: none;
	max-width: 500px;
}

.notice:not(.notice--error) .notice__description {
	opacity: var(--teaser-hover-opacity);
}

.notice__title__box + .notice__description {
	margin-top: var(--space-xx-small);
}
