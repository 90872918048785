.l-archive {
	display: flex;
	flex-wrap: wrap;
}

@media (min-width: 1280px) {
	.l-archive {
		flex-wrap: nowrap;
	}
}

.l-archive > *:not(:last-child) {
	margin-bottom: var(--space-medium);
}

@media (min-width: 1280px) {
	.l-archive > *:not(:last-child) {
		margin-right: 1.5rem;
		margin-bottom: 0;
	}
}

.l-archive__aside {
	flex-basis: 100%;
	flex-grow: 1;
	flex-shrink: 0;
}

@media (min-width: 1280px) {
	.l-archive__aside {
		flex-basis: 23.63%; /* 3 cols + 2 gutter = ((88px * 3) + (24px * 2)) / 1320px */
	}
}

.l-archive__aside.l-archive__aside--spacer-only {
	margin-bottom: 0;
}

.l-archive__main {
	flex-basis: 100%;
	flex-grow: 1;
	flex-shrink: 0;
}

@media (min-width: 1280px) {
	.l-archive__main {
		flex-basis: 74.54%; /* 9 cols + 8 gutter = ((88px * 9) + (24px * 8)) / 1320px */
	}
}
