:root {
	color-scheme: light dark;
}

body {
	background-color: var(--color-background);
	box-sizing: border-box;
	color: var(--color-foreground);
	min-height: 100vh;
}

body.is-scrolled-on-load *,
body.is-scrolled-on-load *::after,
body.is-scrolled-on-load *::before {
	transition: none !important;
}

body.has-open-gallery {
	overflow: hidden;
}

main:not([class]) {
	display: block;
	width: 100%;
}

textarea {
	resize: none;
}

hr {
	background-color: currentColor;
	border: none;
	color: currentColor;
	opacity: 0.25;
	height: 1px;
}

img,
video {
	display: block;
	max-width: 100%;
}

table,
td {
	border: 1px solid var(--color-foreground-light);
}

thead tr,
tbody tr:nth-child(odd),
thead + tbody tr:nth-child(even) {
	background-color: var(--color-background-light);
}

thead + tbody tr:nth-child(odd) {
	background-color: var(--color-background);
}

table {
	border-collapse: collapse;
	box-sizing: border-box;
	width: 100%;
	max-width: 100%;
}

th,
td {
	box-sizing: border-box;
	padding: 0.75rem 1.5rem;
	text-align: left;
}

:is(td, th).has-text-align-center {
	text-align: center;
}

:is(td, th).has-text-align-right {
	text-align: right;
}

ol:not([class]),
ul:not([class]) {
	box-sizing: border-box;
	margin: 0 0 2rem 0;
}

ol:not([class]):last-child,
ul:not([class]):last-child {
	margin-bottom: 0;
}

li ol:not([class]),
li ul:not([class]) {
	margin-bottom: 0;
}

ul:not([class]) {
	list-style: none;
	padding-left: 0;
}

ul:not([class]) ul:not([class]) {
	margin-top: 0.625rem;
	margin-bottom: 1.875rem;
}

ol:not([class]) {
	padding-left: 1.25rem;
}

ol li::before {
	font-variant-numeric: tabular-nums;
}

li:not([class]) {
	margin: 0;
	padding: 0;
	text-align: left;
}

ul:not([class]) li:not([class]) {
	padding-left: 1.875rem;
	position: relative;
}

ul:not([class]) li:not([class])::before {
	content: "▪︎ ";
	display: inline-block;
	position: absolute;
	top: 0;
	left: 0;
}

ul:not([class]) ul:not([class]) li:not([class]) {
	padding-left: 2.25rem;
}

ul:not([class]) ul:not([class]) li:not([class])::before {
	content: "— ";
}

blockquote {
	margin-right: 0;
	margin-left: 0;
}

blockquote:not(.is-style-large) {
	border-left: 5px solid var(--color-foreground-x-light);
	color: var(--color-foreground-dark);
	padding-left: 1.5625rem;
}

@media (min-width: 1024px) {
	blockquote:not(.is-style-large) {
		padding-left: 2.5rem;
	}
}

summary {
	list-style-image: url("../images/check-right.svg");
}

@media (prefers-color-scheme: dark) {
	summary {
		list-style-image: url("../images/check-right-white.svg");
	}
}

details[open] > summary {
	/*
		Inlined `../images/check-down.svg` because CSS only starts loading assets when they are needed.
		I do not want to have a glitch when the details element is opened.
	*/
	list-style-image: url("data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%20512%20512%22%3E%3Cpath%20d=%22M61.5%2099%20256%20293.1%20450.5%2099l59.7%2059.8L256%20413%201.8%20158.8z%22/%3E%3C/svg%3E");
}

@media (prefers-color-scheme: dark) {
	details[open] > summary {
		list-style-image: url("data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox%3D%220%200%20512%20512%22%20fill%3D%22%23fff%22%3E%3Cpath%20d%3D%22M61.5%2099%20256%20293.1%20450.5%2099l59.7%2059.8L256%20413%201.8%20158.8z%22%2F%3E%3C%2Fsvg%3E");
	}
}

summary::marker {
	/*
		Font size controls the `list-style-image` size.
		The size was adjusted visually to match a previous icon size.
	 */
	font-size: 25px;
}

@media (min-width: 1024px) {
	summary::marker {
		font-size: 28px;
	}
}

/* Remove Margin from Figure set by Gutenberg Blocks */
.wp-block-table {
	margin: 0;
}

.mr-table-scroll-container {
	margin-bottom: var(--space-medium);
}

/* Fix anchor links so the site header doesn't float in front of it */
.l-site-container__body__main *:target {
	margin-top: calc(-1 * var(--wide-screen-site-header-height));
	padding-top: var(--wide-screen-site-header-height);
}

@media (max-width: 1279px) {
	.l-site-container__body__main *:target {
		margin-top: calc(-1 * var(--small-screen-site-header-height));
		padding-top: var(--small-screen-site-header-height);
	}
}

@media (max-width: 567px) {
	.g-recaptcha {
		transform: scale(0.85);
		transform-origin: 0 0;
	}
}
