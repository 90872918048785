.card {
	display: block;
	overflow: hidden;
	position: relative;
	width: 100%;
	height: 100%;
}

@media (prefers-color-scheme: dark) {
	.card {
		background-color: #777777;
	}
}

.card.card--has-background {
	background-color: var(--color-black);
	color: var(--color-white);
}

.card__link {
	box-sizing: border-box;
	color: currentColor;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	justify-content: space-between;
	align-items: flex-start;
	position: relative;
	text-decoration: none;
	width: 100%;
	height: 100%;
	min-height: 156px;
}

@media (min-width: 768px) {
	.card__link {
		min-height: 312px;
	}
}

.card--has-background .card__link {
	min-height: 312px;
}

@media (min-width: 768px) {
	.card--has-background .card__link {
		min-height: 340px;
	}
}

@media (min-width: 1024px) {
	.card--has-background .card__link {
		min-height: 380px;
	}
}

@media (min-width: 1440px) {
	.card--has-background .card__link {
		min-height: 416px;
	}
}

.card__link:focus {
	outline: 0;
}

.card__button {
	background-color: var(--color-alpha-foreground-0);
	border: 1px solid var(--color-foreground);
	border-radius: 0;
	box-shadow: none;
	box-sizing: border-box;
	color: currentColor;
	display: flex;
	margin-top: 1rem;
	margin-left: 1rem;
	justify-content: center;
	align-items: center;
	position: relative;
	transition:
		background-color 64ms linear,
		color 64ms linear;
	width: 36px;
	height: 36px;
	z-index: 3;
}

@media (min-width: 1440px) {
	.card__button {
		margin-top: var(--space-x-small);
		margin-left: var(--space-x-small);
	}
}

.card__link:focus .card__button,
.card__link:hover .card__button {
	background-color: var(--color-foreground);
	color: var(--color-background);
}

.card--has-background .card__button {
	background-color: var(--color-alpha-white-50);
	border: 1px solid var(--color-alpha-white-50);
}

@supports (backdrop-filter: blur(2px)) {
	.card--has-background .card__button {
		backdrop-filter: blur(2px);
		background-color: var(--color-alpha-white-10);
	}
}

.card--has-background .card__link:focus .card__button,
.card--has-background .card__link:hover .card__button {
	background-color: var(--color-white);
	border-color: var(--color-white);
	color: var(--color-black);
}

@media (prefers-reduced-motion: reduce) {
	.card__button {
		transition: none;
	}
}

.card__button .icon {
	display: block;
	fill: currentColor;
	width: 10px;
	height: 10px;
}

.card__background-container {
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.card__background {
	display: block;
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	width: auto;
	max-width: none;
	height: 100%;
}

@supports (object-fit: cover) {
	.card__background {
		object-fit: cover;
		position: relative;
		top: auto;
		left: auto;
		transform: none;
		width: 100%;
	}
}

.card__content {
	box-sizing: border-box;
	flex-basis: auto;
	flex-grow: 0;
	flex-shrink: 0;
	margin-top: 0.9375rem;
	overflow: hidden;
	padding: 1rem;
	position: relative;
	width: 100%;
	z-index: 3;
}

@media (min-width: 1440px) {
	.card__content {
		padding: var(--space-x-small);
	}
}

.card__content > * {
	position: relative;
	max-width: 500px;
	z-index: 3;
}

.card--has-background .card__content {
	padding-top: var(--space-large);
}

@media (min-width: 1440px) {
	.card--has-background .card__content {
		padding-top: 7rem;
	}
}

.card--has-background .card__content::after {
	background: linear-gradient(0deg, var(--color-alpha-black-60) 45%, var(--color-alpha-black-0) 90%);
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.card__title {
	display: inline-block;
	hyphens: auto;
	margin-bottom: 0.5rem;
	position: relative;
	word-break: auto-phrase;
	z-index: 3;
}

@media (min-width: 1024px) {
	.card__title {
		margin-bottom: 0.625rem;
	}
}

@supports (-webkit-line-clamp: 3) {
	.card__title {
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		display: block;
		display: -webkit-box;
		overflow: hidden;
	}
}

.card__title__label {
	border-bottom: 1px solid var(--color-alpha-foreground-0);
	display: inline;
	padding-bottom: 1px;
	transition: border-bottom-color 64ms linear;
}

@media (min-width: 1024px) {
	.card__title__label {
		border-bottom-width: 2px;
	}
}

@media (min-width: 1440px) {
	.card__title__label {
		padding-bottom: 2px;
	}
}

@media (prefers-reduced-motion: reduce) {
	.card__title__label {
		transition: none;
	}
}

.card__link:focus .card__title__label,
.card__link:hover .card__title__label {
	border-bottom-color: var(--color-foreground);
}

.card--has-background .card__title__label {
	border-bottom-color: var(--color-alpha-white-0);
}

.card--has-background .card__link:focus .card__title__label,
.card--has-background .card__link:hover .card__title__label {
	border-bottom-color: var(--color-white);
}

.card__content__description {
	line-height: 1.46;
	margin-bottom: 0.5rem;
	position: relative;
	z-index: 3;
}

@media (max-width: 1023px) {
	.card__content__description {
		hyphens: auto;
		word-break: auto-phrase;
	}
}

@media (min-width: 1024px) {
	.card__content__description {
		line-height: 1.5;
	}
}

@media (prefers-reduced-motion: reduce) {
	.card__content__description {
		transition: none;
	}
}

.card:not(.card--has-background) .card__content__description {
	opacity: var(--teaser-hover-opacity);
	transition: opacity 64ms linear;
}

.card:not(.card--has-background) .card__link:focus .card__content__description,
.card:not(.card--has-background) .card__link:hover .card__content__description {
	opacity: 1;
}

@supports (-webkit-line-clamp: 4) {
	.card__content__description {
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 4;
		display: block;
		display: -webkit-box;
		overflow: hidden;
	}
}

.card__content__description > * {
	line-height: inherit;
}
