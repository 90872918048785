.guides-list {
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%;
}

.guides-list__item:not(:last-child) {
	margin-bottom: var(--space-xx-small);
}

.guides-list__link {
	box-sizing: border-box;
	display: inline-flex;
	padding: 1.25rem var(--space-x-small);
	justify-content: flex-start;
	align-items: center;
	text-decoration: none;
	width: 100%;
}

.guides-list__link__category {
	border-radius: 100%;
	display: block;
	flex-grow: 0;
	flex-shrink: 0;
	margin-right: var(--space-x-small);
	width: 0.625rem;
	height: 0.625rem;
}

.guides-list__link__title {
	transform: translateY(0.125rem); /* visually center */
}

/* extra wrapper needed for multiline custom underline */
.guides-list__link__title__inner {
	border-bottom: 1px solid var(--color-alpha-foreground-0);
	display: inline;
	padding-bottom: 0.0625rem;
	transition: border-bottom-color 96ms linear;
}

@media (prefers-reduced-motion: reduce) {
	.guides-list__link__title__inner {
		transition: none;
	}
}

.guides-list__link:focus .guides-list__link__title__inner,
.guides-list__link:hover .guides-list__link__title__inner {
	border-bottom-color: var(--color-foreground);
}
