.video-teaser-row {
	width: 100%;
}

/* Fallback flex styles */
@media (min-width: 768px) {
	.video-teaser-row {
		display: flex;
		flex-basis: 100%;
		flex-grow: 0;
		flex-shrink: 0;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: stretch;
	}
}

@supports (grid-template-columns: 1fr 1fr 1fr 1fr) {
	@media (min-width: 1024px) {
		.video-teaser-row {
			display: grid;
			grid-gap: var(--space-x-small);
			grid-template-rows: 1fr;
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}
	}
}

@supports (grid-template-columns: 1fr 1fr 1fr) {
	@media (min-width: 1024px) {
		.video-teaser-row.video-teaser-row--large-items {
			grid-template-columns: 1fr 1fr 1fr;
		}
	}
}

@media (max-width: 767px) {
	.video-teaser-row:not(:last-child) {
		margin-bottom: var(--space-small);
	}
}

@media (min-width: 768px) {
	.video-teaser-row__item {
		flex-basis: calc(50% - var(--space-x-small));
		flex-grow: 0;
		flex-shrink: 0;
	}
}

@media (min-width: 1024px) {
	.video-teaser-row__item {
		flex-basis: calc(25% - 1.75rem);
		flex-grow: 0;
		flex-shrink: 0;
	}
}

@media (min-width: 1024px) {
	.video-teaser-row--large-items .video-teaser-row__item {
		flex-basis: calc(33.3333% - 1.125rem);
		flex-grow: 0;
		flex-shrink: 0;
	}
}

@supports (grid-template-columns: 1fr 1fr 1fr 1fr) {
	@media (min-width: 1024px) {
		.video-teaser-row__item {
			flex: none;
		}
	}
}

@media (max-width: 767px) {
	.video-teaser-row__item:not(:last-child) {
		margin-bottom: var(--space-x-small);
	}
}

@media (min-width: 1024px) {
	.video-teaser-row__item:not(:last-child) {
		margin-right: var(--space-x-small);
	}
}

@supports (grid-template-columns: 1fr 1fr 1fr 1fr) {
	@media (min-width: 1024px) {
		.video-teaser-row__item:not(:last-child) {
			margin-right: 0;
		}
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.video-teaser-row__item:nth-child(odd) {
		margin-right: 2.25rem;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.video-teaser-row__item:first-child,
	.video-teaser-row__item:nth-child(2) {
		margin-bottom: 2.25rem;
	}
}
