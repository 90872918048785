.contact-links {
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%;
}

.contact-links__item {
	border-bottom: 1px solid var(--color-alpha-foreground-25);
	display: block;
	margin-bottom: 0.75rem;
	padding-bottom: 0.75rem;
	width: 100%;
}

.contact-links__item:last-child {
	margin-bottom: 0;
}

.contact-links__link {
	color: currentColor;
	display: inline-flex;
	line-height: 1.6;
	margin-left: 2.3125rem;
	position: relative;
	text-decoration: none;
	width: calc(100% - 2.3125rem);
}

.contact-links__link::before {
	background-image: url("../images/otherlink.svg");
	background-position: center left;
	background-repeat: no-repeat;
	background-size: contain;
	content: "";
	display: block;
	position: absolute;
	top: 50%;
	left: -2.3125rem;
	transform: translateY(calc(-50% - 1px));
	width: 2.3125rem;
	height: 17px;
}

@media (prefers-color-scheme: dark) {
	.contact-links__link::before {
		filter: invert(1);
	}
}

.contact-links__link[href*="facebook.com"]::before {
	background-image: url("../images/facebook.svg");
}

.contact-links__link[href*="instagram.com"]::before {
	background-image: url("../images/instagram.svg");
}

.contact-links__link[href*="linkedin.com"]::before {
	background-image: url("../images/linkedin.svg");
}

.contact-links__link[href*="mailto:"]::before {
	background-image: url("../images/mail.svg");
}

.contact-links__link[href*="blog.kunsten.be"]::before,
.contact-links__link[href*="medium.com"]::before {
	background-image: url("../images/medium.svg");
}

.contact-links__link[href*="soundcloud.com"]::before {
	background-image: url("../images/soundcloud.svg");
}

.contact-links__link[href*="twitter.com"]::before {
	background-image: url("../images/twitter.svg");
}

.contact-links__link[href*="vimeo.com"]::before {
	background-image: url("../images/vimeo.svg");
}

.contact-links__link[href*="youtube.com"]::before {
	background-image: url("../images/youtube.svg");
}

.contact-links__link__label {
	border-bottom: 1px solid var(--color-alpha-foreground-0);
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	transition: border-bottom-color 96ms linear;
	white-space: nowrap;
	max-width: 240px;
}

@media (min-width: 375px) {
	.contact-links__link__label {
		max-width: 280px;
	}
}

@media (min-width: 768px) {
	.contact-links__link__label {
		max-width: 420px;
	}
}

@media (min-width: 1024px) {
	.contact-links__link__label {
		max-width: 560px;
	}
}

@media (prefers-reduced-motion: reduce) {
	.contact-links__link__label {
		transition: none;
	}
}

.contact-links__link:focus .contact-links__link__label,
.contact-links__link:hover .contact-links__link__label {
	border-bottom-color: var(--color-foreground);
}
