.checklist-answer {
	border: 3px solid transparent;
	border-radius: 0.3125rem;
	opacity: 0;
	transform: translateY(1rem);
	transition:
		opacity 160ms linear,
		transform 320ms cubic-bezier(0.5, 0, 0.25, 1);
}

.checklist-answer:not(:last-child) {
	margin-bottom: 1rem;
}

@media (min-width: 1440px) {
	.checklist-answer:not(:last-child) {
		margin-bottom: 0.5625rem;
	}
}

.checklist-question[data-toggled] .checklist-answer {
	opacity: 1;
	transform: translateY(0);
}

.checklist[data-parent-theme="theme-orange"] .checklist-answer[data-toggled] {
	border-color: var(--color-theme-orange);
}

.checklist[data-parent-theme="theme-pink"] .checklist-answer[data-toggled] {
	border-color: var(--color-theme-pink);
}

.checklist[data-parent-theme="theme-yellow"] .checklist-answer[data-toggled] {
	border-color: var(--color-theme-yellow);
}

.checklist[data-parent-theme="theme-brown"] .checklist-answer[data-toggled] {
	border-color: var(--color-theme-brown);
}

.checklist[data-parent-theme="theme-crimson"] .checklist-answer[data-toggled] {
	border-color: var(--color-theme-crimson);
}

.checklist[data-parent-theme="theme-dark-blue"] .checklist-answer[data-toggled] {
	border-color: var(--color-theme-dark-blue);
}

.checklist[data-parent-theme="theme-dark-green"] .checklist-answer[data-toggled] {
	border-color: var(--color-theme-dark-green);
}

.checklist[data-parent-theme="theme-light-blue"] .checklist-answer[data-toggled] {
	border-color: var(--color-theme-light-blue);
}

.checklist[data-parent-theme="theme-light-green"] .checklist-answer[data-toggled] {
	border-color: var(--color-theme-light-green);
}

.checklist[data-parent-theme="theme-red"] .checklist-answer[data-toggled] {
	border-color: var(--color-theme-red);
}

.checklist-answer__header {
	appearance: none;
	background: none;
	border: 0;
	box-shadow: none;
	cursor: pointer;
	display: flex;
	padding: 1.25rem 0.875rem 1.25rem 1rem;
	align-items: flex-start;
	text-align: left;
	width: 100%;
}

@media (min-width: 768px) {
	.checklist-answer__header {
		padding-top: 1.0625rem;
		padding-right: 1rem;
		padding-bottom: 1.0625rem;
	}
}

@media (min-width: 1440px) {
	.checklist-answer__header {
		padding-right: 1.3125rem;
		padding-left: 2.3125rem;
	}
}

.checklist-answer__header:focus:not(:focus-visible) {
	outline: 0;
}

.checklist-answer__header > * {
	pointer-events: none;
}

@media (max-width: 767px) {
	.checklist-answer__index {
		border: none;
		clip: rect(0 0 0 0);
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		white-space: nowrap;
		width: 1px;
		height: 1px;
	}
}

@media (min-width: 768px) {
	.checklist-answer__index {
		flex-shrink: 0;
		line-height: 2.3125rem;
		margin-right: 1.25rem;
		position: relative;
		width: 1.25rem;
		height: 2.3125rem;
	}
}

@media (min-width: 1280px) {
	.checklist-answer__index {
		margin-right: 2.25rem;
		width: 2.25rem;
	}
}

.checklist-answer__index::after {
	background-color: #979797;
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	width: 1px;
	height: 100%;
}

.checklist-answer__title {
	flex-grow: 1;
	margin-right: 0.375rem;
	margin-bottom: 0;
	padding-top: 0.125rem;
}

@media (min-width: 768px) {
	.checklist-answer__title {
		margin-right: 1.875rem;
		padding-top: 0.3125rem;
	}
}

@media (min-width: 1280px) {
	.checklist-answer__title {
		margin-right: 2.6875rem;
		padding-top: 0.1875rem;
	}
}

.checklist-answer__toggle-icon {
	appearance: none;
	background-color: transparent;
	border: 1px solid #d4d4d4;
	border-radius: 100%;
	color: var(--color-alpha-foreground-50);
	display: inline-block;
	flex-shrink: 0;
	padding: 0;
	position: relative;
	width: 1.875rem;
	height: 1.875rem;
}

@media (min-width: 1440px) {
	.checklist-answer__toggle-icon {
		margin-top: 0.1875rem;
		width: 2.25rem;
		height: 2.25rem;
	}
}

.checklist-answer__toggle-icon > span {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.checklist-answer__toggle-icon .icon {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 66.6666%;
	height: 66.6666%;
}

.checklist-answer[data-toggled] .checklist-answer__toggle-icon {
	background-color: var(--color-foreground);
	border-color: var(--color-foreground);
	color: var(--color-background);
}

.checklist-answer__response {
	padding-right: 0.875rem;
	padding-bottom: 1.25rem;
	padding-left: 1rem;
}

@media (min-width: 768px) {
	.checklist-answer__response {
		box-sizing: border-box;
		padding-right: 4.8125rem;
		padding-bottom: 1.875rem;
		padding-left: 3.5rem;
		width: 100%;
	}
}

@media (min-width: 1280px) {
	.checklist-answer__response {
		padding-right: 13.25rem;
		padding-left: 5.5rem;
	}
}

@media (min-width: 1440px) {
	.checklist-answer__response {
		padding-left: 6.8125rem;
	}
}

.checklist-answer:not([data-toggled]) .checklist-answer__response {
	margin: 0;
	overflow: hidden;
	padding: 0;
	height: 0;
}

.checklist-answer__response > * {
	opacity: 0;
	transform: translateY(-1rem);
	transition:
		opacity 160ms linear 160ms,
		transform 640ms cubic-bezier(0.6, 0, 0, 1);
}

.checklist-answer[data-toggled] .checklist-answer__response > * {
	opacity: 1;
	transform: translateY(0);
}

.checklist-answer__response__text {
	max-width: 40.5rem;
}

.checklist-answer__response__text:not(:last-child) {
	margin-bottom: var(--space-medium);
}

.checklist-answer__response__posts-title__box {
	margin-bottom: var(--space-xx-small);
}

.checklist-answer__response__posts-title {
	margin-top: 0;
}
