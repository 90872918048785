.video-header {
	box-sizing: border-box;
	margin-top: calc(var(--small-screen-site-header-height) * -1);
	overflow: hidden;
	padding-top: var(--small-screen-site-header-height);
	position: relative;
}

@media (min-width: 1280px) {
	.video-header {
		margin-top: calc(var(--wide-screen-initial-site-header-height) * -1);
		padding-top: var(--wide-screen-initial-site-header-height);
	}
}

.video-header__wrapper {
	padding-top: var(--space-medium);
	padding-bottom: var(--space-medium);
}

@media (min-width: 1280px) {
	.video-header__wrapper {
		padding-top: var(--space-x-large);
		padding-bottom: var(--space-x-large);
	}
}

@media (min-width: 1024px) {
	.video-header--spotlight .video-header__wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.video-header__video-container:not(:last-child) {
	margin-bottom: var(--space-medium);
}

@media (min-width: 1024px) {
	.video-header--spotlight .video-header__video-container {
		flex-basis: 60%;
		flex-grow: 0;
		flex-shrink: 0;
		margin-right: 4rem;
		margin-bottom: 0;
	}
}

@media (min-width: 1280px) {
	.video-header--spotlight .video-header__video-container {
		flex-basis: 63%;
	}
}

@media (min-width: 1024px) {
	.video-header__content {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
	}
}

@media (min-width: 1024px) {
	.video-header--spotlight .video-header__content {
		display: block;
		flex-basis: auto;
		flex-grow: 1;
		flex-shrink: 1;
		padding: var(--space-medium) 0;
	}
}

@media (min-width: 1024px) {
	.video-header__content__heading {
		display: flex;
		flex-basis: auto;
		flex-grow: 1;
		flex-shrink: 1;
		flex-direction: column;
		align-items: flex-start;
	}
}

@media (max-width: 1023px) {
	.video-header__content__heading:not(:last-child) {
		margin-bottom: 3rem;
	}
}

@media (min-width: 1024px) {
	.video-header--spotlight .video-header__content__heading {
		margin-bottom: 0.625rem;
	}
}

@media (min-width: 1024px) {
	.video-header__content__description {
		flex-basis: calc(50% - var(--space-x-small));
		flex-grow: 0;
		flex-shrink: 0;
		margin-left: var(--space-x-small);
		padding-bottom: 4px;
		transform: translateY(4px);
	}
}

@media (min-width: 1280px) {
	.video-header__content__description {
		padding-bottom: 6px;
		transform: translateY(6px);
	}
}

.video-header__content__description p:not(:last-child) {
	margin-bottom: 0.625rem;
}

@media (min-width: 1024px) {
	.video-header--spotlight .video-header__content__description {
		margin-left: 0;
	}
}

.video-header__title {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 0.625rem;
	width: 100%;
}

.video-header__meta {
	display: inline-block;
	opacity: var(--teaser-hover-opacity);
	width: 100%;
}

@media (max-width: 767px) {
	.video-header__meta__date-prefix {
		display: none;
	}
}
