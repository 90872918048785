.related-content {
}

.related-content__title__box {
	margin-bottom: var(--space-xx-small);
}

@media (min-width: 768px) {
	.related-content__title__box {
		margin-bottom: 2.125rem;
	}
}

.related-content__title {
	text-transform: none !important;
}

.related-content__checkbox {
	display: none;
}

.related-content__posts {
	box-sizing: border-box;
	list-style: none;
	margin: 0;
	padding-left: 0;
}

.related-content__posts:not(:last-child) {
	margin-bottom: var(--space-xx-small);
}

@media (min-width: 768px) {
	.related-content__posts:not(:last-child) {
		margin-bottom: var(--space-small);
	}
}

.related-content__posts__item {
	margin: 0;
	padding: 0 0 0 1.875rem;
	position: relative;
}

.related-content__posts__item:not(:last-child) {
	margin-bottom: 0.75rem;
}

.related-content__checkbox:not(:checked) ~ .related-content__posts .related-content__posts__item:nth-child(1n + 4) {
	display: none;
}

.related-content__posts__item::before {
	content: "▪︎ ";
	display: inline-block;
	position: absolute;
	top: 0;
	left: 0;
}

.related-content__posts__link {
	color: var(--color-foreground);
	text-decoration: none;
	transition: color 96ms linear;
}

.related-content__posts__link:focus,
.related-content__posts__link:hover {
	color: var(--color-foreground-dark);
	outline: 0;
}

.related-content__posts__link:focus {
	outline: 0;
}

.related-content__toggle {
	color: var(--color-foreground-medium);
	text-decoration: none;
	transition: color 96ms linear;
}

@media (hover: hover) {
	.related-content__toggle:focus,
	.related-content__toggle:hover {
		color: var(--color-foreground);
	}
}

.related-content__toggle:focus {
	outline: 0;
}

.related-content__toggle:hover {
	cursor: pointer;
}

.related-content__toggle__close,
.related-content__toggle__open {
	display: inline-flex;
	justify-content: flex-start;
	align-items: center;
	user-select: none;
}

.related-content__toggle__close .icon,
.related-content__toggle__open .icon {
	display: inline-block;
	fill: currentColor;
	margin-right: 1.6875rem;
	width: 0.875rem;
	height: 0.875rem;
}

.related-content__checkbox:not(:checked) ~ .related-content__toggle .related-content__toggle__close {
	display: none;
}

.related-content__checkbox:checked ~ .related-content__toggle .related-content__toggle__open {
	display: none;
}
