.checklist-teaser {
	display: block;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.checklist-teaser__link {
	color: currentColor;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	text-decoration: none;
	width: 100%;
}

.checklist-teaser__thumbnail-container {
	border-right: 1px solid var(--color-alpha-foreground-25);
	flex-basis: 58px;
	flex-grow: 0;
	flex-shrink: 0;
	margin-right: 1.25rem;
	overflow: hidden;
	position: relative;
	transition: opacity 96ms linear;
	width: 58px;
	height: 58px;
}

@media (min-width: 768px) {
	.checklist-teaser__thumbnail-container {
		flex-basis: 96px;
		width: 96px;
		height: 96px;
	}
}

@media (min-width: 1440px) {
	.checklist-teaser__thumbnail-container {
		flex-basis: 112px;
		margin-right: var(--space-x-small);
		width: 112px;
		height: 112px;
	}
}

@media (prefers-reduced-motion: reduce) {
	.checklist-teaser__thumbnail-container {
		transition: none;
	}
}

.checklist-teaser__link:focus .checklist-teaser__thumbnail-container,
.checklist-teaser__link:hover .checklist-teaser__thumbnail-container {
	opacity: var(--teaser-hover-opacity);
}

.checklist-teaser__thumbnail-container .icon {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 1.25rem;
	height: 1.25rem;
}

@media (min-width: 1280px) {
	.checklist-teaser__thumbnail-container .icon {
		width: 1.5rem;
		height: 1.5rem;
	}
}

.checklist-teaser__thumbnail {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}

@supports (object-fit: cover) {
	.checklist-teaser__thumbnail {
		object-fit: cover;
		height: 100%;
	}
}

.checklist-teaser__content {
	flex-basis: auto;
	flex-grow: 1;
	flex-shrink: 1;
	margin-right: 1.25rem;
	transform: translateY(-4px);
}

@media (min-width: 1440px) {
	.checklist-teaser__content {
		margin-right: var(--space-x-small);
	}
}

.checklist-teaser__title {
	display: inline-block;
	hyphens: auto;
	transform: translateY(0.125rem);
	word-break: auto-phrase;
}

.checklist-teaser__title:not(:last-child) {
	margin-bottom: 0.3125rem;
}

@supports (-webkit-line-clamp: 2) {
	.checklist-teaser__title {
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		display: block;
		display: -webkit-box;
		overflow: hidden;
	}

	@media (min-width: 768px) {
		.checklist-teaser__title {
			-webkit-line-clamp: 3;
		}
	}
}

.checklist-teaser__title__label {
	border-bottom: 1px solid var(--color-alpha-foreground-0);
	display: inline;
	padding-bottom: 1px;
	transition: border-bottom-color 64ms linear;
}

.checklist-teaser__link:focus .checklist-teaser__title__label,
.checklist-teaser__link:hover .checklist-teaser__title__label {
	border-bottom-color: var(--color-foreground);
}

@media (prefers-reduced-motion: reduce) {
	.checklist-teaser__title__label {
		transition: none;
	}
}
