.main-navigation,
.main-navigation__list {
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%;
}

.main-navigation__item:not(:last-child) {
	margin-bottom: 1.875rem;
}

@media (min-width: 1440px) {
	.main-navigation__item:not(:last-child) {
		margin-bottom: var(--space-x-small);
	}
}

.main-navigation__title {
	margin-bottom: var(--space-xx-small);
	position: relative;
}

.main-navigation__title::after {
	background-color: var(--color-foreground);
	content: "";
	opacity: 0.25;
	position: absolute;
	bottom: 0.5rem;
	left: 0;
	width: 100%;
	height: 1px;
	z-index: 1;
}

@media (min-width: 1024px) {
	.main-navigation__title::after {
		bottom: 0.625rem;
	}
}

.main-navigation__title__label {
	background-color: var(--color-background);
	box-sizing: border-box;
	display: inline-block;
	font-weight: var(--font-weight-bold);
	padding-right: 1rem;
	position: relative;
	text-transform: uppercase;
	z-index: 2;
}

.main-navigation__list__item + .main-navigation__list__item {
	margin-top: var(--space-xxx-small);
}

.main-navigation__list__link {
	color: currentColor;
	position: relative;
	text-decoration: none;
}

.main-navigation__list__link[href*="facebook.com"],
.main-navigation__list__link[href*="instagram.com"],
.main-navigation__list__link[href*="linkedin.com"],
.main-navigation__list__link[href*="blog.kunsten.be"],
.main-navigation__list__link[href*="medium.com"],
.main-navigation__list__link[href*="soundcloud.com"],
.main-navigation__list__link[href*="twitter.com"],
.main-navigation__list__link[href*="vimeo.com"],
.main-navigation__list__link[href*="youtube.com"] {
	margin-left: 2.3125rem;
	position: relative;
}

.main-navigation__list__link[href*="facebook.com"]:focus,
.main-navigation__list__link[href*="facebook.com"]:hover,
.main-navigation__list__link[href*="instagram.com"]:focus,
.main-navigation__list__link[href*="instagram.com"]:hover,
.main-navigation__list__link[href*="linkedin.com"]:focus,
.main-navigation__list__link[href*="linkedin.com"]:hover,
.main-navigation__list__link[href*="blog.kunsten.be"]:focus,
.main-navigation__list__link[href*="blog.kunsten.be"]:hover,
.main-navigation__list__link[href*="medium.com"]:focus,
.main-navigation__list__link[href*="medium.com"]:hover,
.main-navigation__list__link[href*="soundcloud.com"]:focus,
.main-navigation__list__link[href*="soundcloud.com"]:hover,
.main-navigation__list__link[href*="twitter.com"]:focus,
.main-navigation__list__link[href*="twitter.com"]:hover,
.main-navigation__list__link[href*="vimeo.com"]:focus,
.main-navigation__list__link[href*="vimeo.com"]:hover,
.main-navigation__list__link[href*="youtube.com"]:focus,
.main-navigation__list__link[href*="youtube.com"]:hover {
	padding-left: 0;
}

.main-navigation__list__link[href*="facebook.com"]::before,
.main-navigation__list__link[href*="instagram.com"]::before,
.main-navigation__list__link[href*="linkedin.com"]::before,
.main-navigation__list__link[href*="blog.kunsten.be"]::before,
.main-navigation__list__link[href*="medium.com"]::before,
.main-navigation__list__link[href*="soundcloud.com"]::before,
.main-navigation__list__link[href*="twitter.com"]::before,
.main-navigation__list__link[href*="vimeo.com"]::before,
.main-navigation__list__link[href*="youtube.com"]::before {
	background-image: url("../images/otherlink.svg");
	background-position: center left;
	background-repeat: no-repeat;
	background-size: contain;
	content: "";
	display: block;
	position: absolute;
	top: 50%;
	left: -2.3125rem;
	transform: translateY(calc(-50% - 1px));
	width: 2.3125rem;
	height: 17px;
}

@media (prefers-color-scheme: dark) {
	.main-navigation__list__link[href*="facebook.com"]::before,
	.main-navigation__list__link[href*="instagram.com"]::before,
	.main-navigation__list__link[href*="linkedin.com"]::before,
	.main-navigation__list__link[href*="blog.kunsten.be"]::before,
	.main-navigation__list__link[href*="medium.com"]::before,
	.main-navigation__list__link[href*="soundcloud.com"]::before,
	.main-navigation__list__link[href*="twitter.com"]::before,
	.main-navigation__list__link[href*="vimeo.com"]::before,
	.main-navigation__list__link[href*="youtube.com"]::before {
		filter: invert(1);
	}
}

.main-navigation__list__link[href*="facebook.com"]::before {
	background-image: url("../images/facebook.svg");
}

.main-navigation__list__link[href*="instagram.com"]::before {
	background-image: url("../images/instagram.svg");
}

.main-navigation__list__link[href*="linkedin.com"]::before {
	background-image: url("../images/linkedin.svg");
}

.main-navigation__list__link[href*="blog.kunsten.be"]::before,
.main-navigation__list__link[href*="medium.com"]::before {
	background-image: url("../images/medium.svg");
}

.main-navigation__list__link[href*="soundcloud.com"]::before {
	background-image: url("../images/soundcloud.svg");
}

.main-navigation__list__link[href*="twitter.com"]::before {
	background-image: url("../images/twitter.svg");
}

.main-navigation__list__link[href*="vimeo.com"]::before {
	background-image: url("../images/vimeo.svg");
}

.main-navigation__list__link[href*="youtube.com"]::before {
	background-image: url("../images/youtube.svg");
}

.main-navigation__list__label {
	display: inline-block;
	position: relative;
	transform: translateX(0) translateZ(0);
	transition:
		border-bottom-color 96ms linear,
		transform 288ms cubic-bezier(0.625, 0, 0.1, 1);
}

@media (prefers-reduced-motion: reduce) {
	.main-navigation__list__label {
		transition: none;
	}
}

.main-navigation__list__label::before {
	content: "•   ";
	display: inline-block;
	line-height: 1;
	opacity: 0;
	pointer-events: none;
	position: absolute;
	top: 0.25rem;
	left: -0.75rem;
	transition: opacity 96ms linear 64ms;
}

@media (min-width: 1024px) {
	.main-navigation__list__label::before {
		top: 0.3125rem;
	}
}

@media (prefers-reduced-motion: reduce) {
	.main-navigation__list__label::before {
		content: none;
		display: none;
	}
}

.main-navigation__list__label .icon {
	margin-left: 10px;
	width: 0.8125rem;
	height: 0.8125rem;
}

/* #region sub list menu */
.main-navigation__list__item__sub-list {
	list-style: none;
	padding-left: 1.25rem;
}

.main-navigation__list__item__sub-list__item {
	margin-top: var(--space-xxx-small);
}
/* #endregion sub list menu */

.main-navigation__list__link[href*="facebook.com"] .main-navigation__list__label::before,
.main-navigation__list__link[href*="instagram.com"] .main-navigation__list__label::before,
.main-navigation__list__link[href*="linkedin.com"] .main-navigation__list__label::before,
.main-navigation__list__link[href*="blog.kunsten.be"] .main-navigation__list__label::before,
.main-navigation__list__link[href*="medium.com"] .main-navigation__list__label::before,
.main-navigation__list__link[href*="soundcloud.com"] .main-navigation__list__label::before,
.main-navigation__list__link[href*="twitter.com"] .main-navigation__list__label::before,
.main-navigation__list__link[href*="vimeo.com"] .main-navigation__list__label::before,
.main-navigation__list__link[href*="youtube.com"] .main-navigation__list__label::before {
	display: none;
}

.main-navigation__list__link:focus .main-navigation__list__label,
.main-navigation__list__link:hover .main-navigation__list__label,
.main-navigation:not(:hover):not(:focus) .main-navigation__list__item--active > .main-navigation__list__link .main-navigation__list__label,
.main-navigation:not(:hover):not(:focus) .main-navigation__list__item__sub-list__item--active .main-navigation__list__link .main-navigation__list__label {
	text-decoration: 1px solid underline currentColor;
	text-underline-offset: 7px;
	transform: translateX(0.75rem) translateZ(0);
}

.main-navigation__list__link:focus .main-navigation__list__label::before,
.main-navigation__list__link:hover .main-navigation__list__label::before,
.main-navigation:not(:hover):not(:focus) .main-navigation__list__item--active > .main-navigation__list__link .main-navigation__list__label::before,
.main-navigation:not(:hover):not(:focus) .main-navigation__list__item__sub-list__item--active > .main-navigation__list__link .main-navigation__list__label::before {
	opacity: 1;
	transition: opacity 96ms linear;
}

@media (prefers-reduced-motion: reduce) {
	.main-navigation__list__link:focus .main-navigation__list__label,
	.main-navigation__list__link:hover .main-navigation__list__label,
	.main-navigation:not(:hover):not(:focus) .main-navigation__list__item--active > .main-navigation__list__link .main-navigation__list__label {
		transform: translateX(0) translateZ(0);
	}
}

.main-navigation__list__link[href*="facebook.com"] .main-navigation__list__label:focus,
.main-navigation__list__link[href*="facebook.com"] .main-navigation__list__label:hover,
.main-navigation:not(:hover):not(:focus) .main-navigation__list__item--active .main-navigation__list__link[href*="facebook.com"] .main-navigation__list__label,
.main-navigation__list__link[href*="instagram.com"] .main-navigation__list__label:focus,
.main-navigation__list__link[href*="instagram.com"] .main-navigation__list__label:hover,
.main-navigation:not(:hover):not(:focus) .main-navigation__list__item--active .main-navigation__list__link[href*="instagram.com"] .main-navigation__list__label,
.main-navigation__list__link[href*="linkedin.com"] .main-navigation__list__label:focus,
.main-navigation__list__link[href*="linkedin.com"] .main-navigation__list__label:hover,
.main-navigation:not(:hover):not(:focus) .main-navigation__list__item--active .main-navigation__list__link[href*="linkedin.com"] .main-navigation__list__label,
.main-navigation__list__link[href*="blog.kunsten.be"] .main-navigation__list__label:focus,
.main-navigation__list__link[href*="blog.kunsten.be"] .main-navigation__list__label:hover,
.main-navigation:not(:hover):not(:focus) .main-navigation__list__item--active .main-navigation__list__link[href*="blog.kunsten.be"] .main-navigation__list__label,
.main-navigation__list__link[href*="medium.com"] .main-navigation__list__label:focus,
.main-navigation__list__link[href*="medium.com"] .main-navigation__list__label:hover,
.main-navigation:not(:hover):not(:focus) .main-navigation__list__item--active .main-navigation__list__link[href*="medium.com"] .main-navigation__list__label,
.main-navigation__list__link[href*="soundcloud.com"] .main-navigation__list__label:focus,
.main-navigation__list__link[href*="soundcloud.com"] .main-navigation__list__label:hover,
.main-navigation:not(:hover):not(:focus) .main-navigation__list__item--active .main-navigation__list__link[href*="soundcloud.com"] .main-navigation__list__label,
.main-navigation__list__link[href*="twitter.com"] .main-navigation__list__label:focus,
.main-navigation__list__link[href*="twitter.com"] .main-navigation__list__label:hover,
.main-navigation:not(:hover):not(:focus) .main-navigation__list__item--active .main-navigation__list__link[href*="twitter.com"] .main-navigation__list__label,
.main-navigation__list__link[href*="vimeo.com"] .main-navigation__list__label:focus,
.main-navigation__list__link[href*="vimeo.com"] .main-navigation__list__label:hover,
.main-navigation:not(:hover):not(:focus) .main-navigation__list__item--active .main-navigation__list__link[href*="vimeo.com"] .main-navigation__list__label,
.main-navigation__list__link[href*="youtube.com"] .main-navigation__list__label:focus,
.main-navigation__list__link[href*="youtube.com"] .main-navigation__list__label:hover,
.main-navigation:not(:hover):not(:focus) .main-navigation__list__item--active .main-navigation__list__link[href*="youtube.com"] .main-navigation__list__label {
	transform: translateX(0) translateZ(0);
}
