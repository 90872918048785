body {
	font-family: var(--font-serif);
	font-size: 1rem;
}

@media (min-width: 768px) {
	body {
		font-size: 1.125rem;
	}
}

* {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

i,
em {
	font-style: italic;
}

b,
strong {
	font-weight: var(--font-weight-bold);
}

a:not([class]) {
	border-bottom: 1px solid currentColor;
	box-sizing: border-box;
	color: currentColor;
	display: inline;
	text-decoration: none;
	transition: color 96ms linear;
}

@media (prefers-reduced-motion: reduce) {
	a:not([class]) {
		transition: none !important;
	}
}

a:not([class]):active,
a:not([class]):focus,
a:not([class]):hover {
	color: var(--color-foreground-dark);
	text-decoration: none;
}

p a:not([class]) {
	line-height: inherit;
}

a[href^="tel"]:not([class]) {
	color: currentColor;
	text-decoration: none;
}

p,
blockquote {
	margin-top: 0;
	margin-bottom: var(--space-medium);
}

p:last-child,
blockquote:last-child {
	margin-bottom: 0;
}

p:only-child,
blockquote:only-child {
	margin-bottom: 0;
}

table {
	margin-top: 0;
	margin-bottom: 0;
}

blockquote.is-style-large p {
	font-family: var(--font-nib);
	font-size: 1.75rem;
	font-weight: 400;
	line-height: 1.21;
}

@media (min-width: 1024px) {
	blockquote.is-style-large p {
		font-size: 2.125rem;
		line-height: 1.355;
	}
}

@media (min-width: 1440px) {
	blockquote.is-style-large p {
		font-size: 2.5rem;
		line-height: 1.5;
	}
}

blockquote.is-style-large p b,
blockquote.is-style-large p strong {
	font-family: var(--font-sans);
	font-weight: 400;
}

blockquote.is-style-large p a:not([class]) {
	border-bottom-width: 2px;
	padding-bottom: 1px;
}

@media (min-width: 1024px) {
	blockquote.is-style-large p a:not([class]) {
		border-bottom-width: 3px;
	}
}

@media (min-width: 1440px) {
	blockquote.is-style-large p a:not([class]) {
		padding-bottom: 2px;
	}
}

blockquote.is-style-large p {
	margin-bottom: 1.375rem;
}

.button.button--secondary, /* ⚠️ these share typo styles with type-h, */
.button.button--text,      /* ⚠️ see button.css for other styles      */
blockquote p,
th,
td strong {
	font-family: var(--font-sans);
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.63;
}

@media (min-width: 1024px) {
	.button.button--secondary,
	.button.button--text,
	blockquote p,
	th,
	td strong {
		font-size: 1.125rem;
		line-height: 1.5;
	}
}

.button.button--secondary b,
.button.button--secondary strong,
.button.button--text b,
.button.button--text strong,
blockquote p b,
blockquote p strong,
th b,
th strong,
td strong b,
td strong strong {
	font-weight: var(--font-weight-semibold);
}

blockquote p {
	margin-bottom: var(--space-xxx-small);
}

@media (min-width: 1024px) {
	blockquote p {
		margin-bottom: var(--space-xx-small);
	}
}

th,
td strong {
	font-weight: var(--font-weight-semibold);
	line-height: normal;
}

p,
li:not([class]) {
	font-weight: 400;
	line-height: 1.63;
}

@media (min-width: 1024px) {
	p,
	li:not([class]) {
		line-height: 1.78;
	}
}

.button, /* ⚠️ shares typo styles with type-i, see button.css for other styles */
figcaption, /* ⚠️ shares typo styles with type-i, see core-blocks.css for other styles */
cite {
	font-family: var(--font-mono);
	font-size: 0.75rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
}

@media (min-width: 1024px) {
	.button,
	figcaption,
	cite {
		font-size: 0.8125rem;
		line-height: 1.54;
	}
}

@media (max-width: 567px) {
	p > a:not([class]) {
		hyphens: auto;
		/* stylelint-disable-next-line declaration-property-value-keyword-no-deprecated */
		word-break: break-word;
	}
}

td {
	font-size: 0.9375rem;
	font-weight: 400;
	line-height: 1.63;
}

@media (min-width: 1024px) {
	td {
		line-height: 1.78;
	}
}
