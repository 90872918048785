.categories-overview {
	display: block;
}

@media (min-width: 568px) {
	.categories-overview {
		columns: 2;
		column-gap: var(--space-x-small);
	}
}

@media (min-width: 768px) {
	.categories-overview {
		columns: 3;
	}
}

.categories-overview__list {
	list-style: none;
	margin-top: 0;
	margin-right: 0;
	margin-bottom: 1rem;
	margin-left: 0;
	padding: 0;
}

@media (min-width: 1024px) {
	.categories-overview__list {
		margin-bottom: 1.125rem;
	}
}

.categories-overview__list__link {
	color: inherit;
}
