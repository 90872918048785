h4 {
	font-family: var(--font-sans);
	font-size: 0.8125rem;
	font-weight: var(--font-weight-bold);
	line-height: 1.5rem;
	margin-top: 0;
	margin-bottom: var(--space-medium);
	text-transform: uppercase;
}

@media (min-width: 1024px) {
	h4 {
		font-size: 0.875rem;
		line-height: 1.5rem;
	}
}

h4:last-child {
	margin-bottom: 0;
}

h4:only-child {
	margin-bottom: 0;
}

h4 b,
h4 strong {
	font-family: var(--font-sans);
	font-weight: var(--font-weight-bold);
}
