h6 {
	font-family: var(--font-sans);
	font-size: 1rem;
	font-weight: var(--font-weight-semibold);
	line-height: normal;
	margin-top: 0;
	margin-bottom: 0.25rem;
}

@media (min-width: 1024px) {
	h6 {
		font-size: 1.125rem;
		line-height: normal;
	}
}

h6:last-child {
	margin-bottom: 0;
}

h6:only-child {
	margin-bottom: 0;
}

h6 b,
h6 strong {
	font-family: var(--font-sans);
	font-weight: var(--font-weight-semibold);
}
