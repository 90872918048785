.issuu {
	break-inside: avoid;
	display: block;
	overflow: hidden;
	padding-bottom: 66.6666%;
	position: relative;
	width: 100%;
}

.issuu__iframe {
	border: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
