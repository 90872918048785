h4-before-2023-01-31 {
	display: inline;
}

h4-before-2023-01-31 > * {
	font-family: var(--font-sans);
	font-size: 1rem;
	font-weight: var(--font-weight-semibold);
	line-height: normal;
	margin-top: 0;
	margin-bottom: 0.25rem;
	text-transform: none;
}

@media (min-width: 1024px) {
	h4-before-2023-01-31 > * {
		font-size: 1.125rem;
		line-height: normal;
	}
}

h4-before-2023-01-31 > :last-child {
	margin-bottom: 0;
}

h4-before-2023-01-31 > :only-child {
	margin-bottom: 0;
}

h4-before-2023-01-31 > * b,
h4-before-2023-01-31 > * strong {
	font-family: var(--font-sans);
	font-weight: var(--font-weight-semibold);
}
