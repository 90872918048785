@media (min-width: 1024px) {
	.team-member-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-start;
	}
}

@supports (grid-template-columns: 1fr 1fr) {
	.team-member-list {
		display: grid;
		grid-gap: var(--space-medium);
		grid-template-columns: 100%;
	}

	@media (min-width: 1024px) {
		.team-member-list {
			grid-gap: var(--space-large) var(--space-x-large);
			grid-template-columns: 1fr 1fr;
		}
	}
}

.team-member-list__item {
	margin-bottom: var(--space-medium);
}

@media (min-width: 1024px) {
	.team-member-list__item {
		flex-basis: calc(50% - (var(--space-x-large) / 2));
		flex-grow: 0;
		flex-shrink: 0;
		margin-bottom: var(--space-large);
	}
}

.team-member-list__item:last-child,
.team-member-list__item:nth-last-child(2):not(:nth-child(even)) {
	margin-bottom: 0;
}

@supports (grid-template-columns: 1fr 1fr) {
	.team-member-list__item {
		flex: none;
		margin-bottom: 0;
	}
}
