.compact-teaser-grid {
	width: 100%;
}

/* Fallback flex styles */
@media (min-width: 768px) {
	.compact-teaser-grid {
		display: flex;
		flex-basis: 100%;
		flex-grow: 0;
		flex-shrink: 0;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: stretch;
	}
}

@media (max-width: 767px) {
	.compact-teaser-grid:not(:last-child) {
		margin-bottom: var(--space-small);
	}
}

@media (min-width: 768px) {
	.compact-teaser-grid__item {
		border-bottom: 1px solid var(--color-alpha-foreground-25);
		flex-basis: calc(50% - 0.75rem);
		flex-grow: 0;
		flex-shrink: 0;
		margin-bottom: var(--space-medium);
		padding-bottom: var(--space-medium);
	}
}

@media (min-width: 1024px) {
	.compact-teaser-grid__item {
		flex-basis: calc(33.3333% - var(--space-x-small));
		flex-grow: 0;
		flex-shrink: 0;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.compact-teaser-grid__item:nth-child(odd) {
		margin-right: var(--space-x-small);
	}
}

@media (min-width: 1024px) {
	.compact-teaser-grid__item:nth-child(3n + 1),
	.compact-teaser-grid__item:nth-child(3n + 2) {
		margin-right: var(--space-x-small);
	}
}

@media (max-width: 767px) {
	.compact-teaser-grid__item:not(:last-child) {
		margin-bottom: var(--space-small);
	}
}

/* Reset bottom border + padding for items in last row */
@media (min-width: 768px) and (max-width: 1023px) {
	.compact-teaser-grid__item:nth-last-child(2):not(:nth-child(even)),
	.compact-teaser-grid__item:last-child {
		border-bottom: 0;
		margin-bottom: 0;
		padding-bottom: 0;
	}
}

@media (min-width: 1024px) {
	.compact-teaser-grid__item:nth-last-child(3):not(:nth-child(3n + 2)):not(:nth-child(3n + 3)),
	.compact-teaser-grid__item:nth-last-child(2):not(:nth-child(3n + 3)),
	.compact-teaser-grid__item:last-child {
		border-bottom: 0;
		margin-bottom: 0;
		padding-bottom: 0;
	}
}
