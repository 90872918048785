.card-row {
	width: 100%;
}

/* Fallback flex styles */
@media (min-width: 1024px) {
	.card-row {
		display: flex;
		justify-content: flex-start;
		align-items: stretch;
	}
}
/* Default: 3 items in a row */
@supports (grid-template-columns: 1fr 1fr) {
	@media (min-width: 768px) {
		.card-row {
			display: grid;
			grid-gap: 1.5rem;
			grid-template-rows: 1fr 1fr;
			grid-template-columns: 1fr 1fr;
		}
	}

	@media (min-width: 1024px) {
		.card-row {
			grid-template-rows: 1fr;
			grid-template-columns: 1fr 1fr 1fr;
		}
	}
}
/* 4 items in a row */
@supports (grid-template-columns: 1fr 1fr) {
	@media (min-width: 1024px) {
		.card-row.card-row--four-items {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}
	}
}
/* 3 items in a row with 1 extra wide item */
@supports (grid-template-columns: 1fr 1fr) and (grid-column: span 2) {
	@media (min-width: 1024px) {
		.card-row.card-row--has-extra-wide-item {
			grid-template-rows: 1fr;
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}
	}
}
/* 2 items in a row */
@supports (grid-template-columns: 1fr 1fr) {
	@media (min-width: 768px) {
		.card-row.card-row--two-items {
			grid-template-rows: 1fr;
		}
	}

	@media (min-width: 1024px) {
		.card-row.card-row--two-items {
			grid-template-columns: 1fr 1fr;
		}
	}
}

@media (min-width: 1024px) {
	.card-row__item {
		flex-basis: calc(33.3333% - 1rem);
		flex-grow: 1;
		flex-shrink: 1;
	}
}

@media (min-width: 1024px) {
	.card-row--four-items .card-row__item {
		flex-basis: calc(25% - 1.125rem);
	}
}

@media (min-width: 1024px) {
	.card-row__item.card-row__item--extra-wide {
		flex-basis: calc(66.6666% - 1rem);
	}
}

@supports (grid-template-columns: 1fr 1fr) and (grid-column: span 2) {
	@media (min-width: 768px) {
		.card-row__item.card-row__item--extra-wide {
			grid-column: span 2;
		}
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.card-row__item--extra-wide + .card-row__item:last-child {
		order: -1;
	}
}

@supports (grid-template-columns: 1fr 1fr) {
	.card-row__item {
		flex: none;
	}
}

@media (max-width: 1023px) {
	.card-row__item:not(:last-child) {
		margin-bottom: 1.5rem;
	}
}

@media (min-width: 1024px) {
	.card-row__item:not(:last-child) {
		margin-right: 1.5rem;
	}
}

@supports (grid-template-columns: 1fr 1fr) {
	@media (min-width: 768px) {
		.card-row__item:not(:last-child) {
			margin-bottom: 0;
		}
	}

	@media (min-width: 1024px) {
		.card-row__item:not(:last-child) {
			margin-right: 0;
		}
	}
}
