.type-basic {
	font-family: var(--font-serif);
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.63;
}

@media (min-width: 768px) {
	.type-basic {
		font-size: 1.125rem;
	}
}

@media (min-width: 1024px) {
	.type-basic {
		line-height: 1.78;
	}
}
