/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.l-knowledge-base {
}

.l-knowledge-base__header .header .header__wrapper::before {
	display: none;
}

.l-knowledge-base__header .header__section-intro {
	padding-top: 0;
}

.l-knowledge-base__header .header__section-intro-hr {
	display: none;
}

.l-knowledge-base__table-of-contents {
	width: 100%;
}

@media (min-width: 1024px) {
	.l-knowledge-base__table-of-contents {
		width: calc(50% - 1rem);
	}
}

.l-knowledge-base__grid {
	display: grid;
	row-gap: 25px;
	column-gap: 25px;
	grid-template-columns: 1fr;
}

@media (min-width: 768px) {
	.l-knowledge-base__grid {
		grid-template-columns: 1fr 1fr;
	}
}

@media (min-width: 1024px) {
	.l-knowledge-base__grid {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
}

.l-knowledge-base__categories {
	margin-top: 2.5rem;
}

@media (min-width: 768px) {
	.l-knowledge-base__categories {
		margin-top: 3rem;
		padding-left: 15%;
	}
}

@media (min-width: 1440px) {
	.l-knowledge-base__categories {
		margin-top: 5rem;
		padding-left: 25%;
	}
}

.l-knowledge-base__content > .section:first-child {
	margin-top: 0;
}

/* mq-up-to-medium */
@media (--mq-up-to-medium) {
	.l-knowledge-base__content :is(p, li) {
		hyphens: auto;
	}
}

/* #region Content separator */
.l-knowledge-base__content hr.separator {
	margin-top: var(--space-x-small);
	margin-bottom: var(--space-x-small);
}

@media (min-width: 768px) {
	.l-knowledge-base__content hr.separator {
		margin-top: 3.75rem;
		margin-bottom: 3.75rem;
		position: relative;
	}

	.l-knowledge-base__content hr.separator::before,
	.l-knowledge-base__content hr.separator::after {
		background-color: var(--color-foreground);
		content: "";
		display: block;
		position: absolute;
		width: calc((100vw - 100%) / 2);
		height: 1px;
	}

	.l-knowledge-base__content hr.separator::before {
		left: calc((100vw - 100%) / -2 + 1.875rem);
	}

	.l-knowledge-base__content hr.separator::after {
		right: calc((100vw - 100%) / -2 + 1.875rem);
	}
}

@media (min-width: 1440px) {
	.l-knowledge-base__content hr.separator::before {
		left: calc((100vw - 100%) / -2 + calc((100vw - 1320px) / 2));
	}

	.l-knowledge-base__content hr.separator::after {
		right: calc((100vw - 100%) / -2 + calc((100vw - 1320px) / 2));
	}
}
/* #endregion Content separator */

.l-knowledge-base__banner {
	width: 100%;
}

/* #region Wide blocks */
@media (min-width: 768px) {
	.l-knowledge-base__content .mr-table-scroll-container,
	.l-knowledge-base__content .tableau,
	.l-knowledge-base__content mr-blocked-content[group="Tableau"] .blocked-content {
		margin-right: -1.77%;
		margin-left: -1.77%;
		width: auto;
	}
}

@media (min-width: 1024px) {
	.fullscreen-toggle-container[data-has-wide-content] .fullscreen-toggle-container__toggle, /* Do not add to smallest breakpoint, we don't need auto width */
	.l-knowledge-base__content .mr-table-scroll-container,
	.l-knowledge-base__content .tableau,
	.l-knowledge-base__content mr-blocked-content[group="Tableau"] .blocked-content {
		margin-right: -3.07%;
		margin-left: -3.07%;
	}
}

@media (min-width: 1280px) {
	.fullscreen-toggle-container[data-has-wide-content] .fullscreen-toggle-container__toggle,
	.l-knowledge-base__content .mr-table-scroll-container,
	.l-knowledge-base__content .tableau,
	.l-knowledge-base__content mr-blocked-content[group="Tableau"] .blocked-content {
		margin-right: -3.84%;
		margin-left: -3.84%;
	}
}

@media (min-width: 1440px) {
	.fullscreen-toggle-container[data-has-wide-content] .fullscreen-toggle-container__toggle,
	.l-knowledge-base__content .mr-table-scroll-container,
	.l-knowledge-base__content .tableau,
	.l-knowledge-base__content mr-blocked-content[group="Tableau"] .blocked-content {
		margin-right: -25.8%;
		margin-left: -25.8%;
	}
}
/* #endregion Wide blocks */
