.video-teaser-grid {
	width: 100%;
}

@media (min-width: 768px) {
	.video-teaser-grid {
		display: flex;
		flex-basis: 100%;
		flex-grow: 0;
		flex-shrink: 0;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: stretch;
	}
}

@supports (grid-template-columns: 1fr 1fr 1fr 1fr) {
	@media (min-width: 1024px) {
		.video-teaser-grid {
			display: grid;
			row-gap: var(--space-x-small);
			column-gap: var(--space-x-small);
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}
	}

	@media (min-width: 1440px) {
		.video-teaser-grid {
			row-gap: 2.25rem;
		}
	}
}

@media (max-width: 767px) {
	.video-teaser-grid:not(:last-child) {
		margin-bottom: var(--space-small);
	}
}

@media (min-width: 768px) {
	.video-teaser-grid__item {
		flex-basis: calc(50% - var(--space-x-small));
		flex-grow: 0;
		flex-shrink: 0;
		margin-bottom: var(--space-x-small);
	}
}

@media (min-width: 1024px) {
	.video-teaser-grid__item {
		flex-basis: calc(25% - 1.75rem);
		flex-grow: 0;
		flex-shrink: 0;
	}
}

@media (min-width: 1024px) {
	.video-teaser-grid--large-items .video-teaser-grid__item {
		flex-basis: calc(33.3333% - 1.125rem);
		flex-grow: 0;
		flex-shrink: 0;
	}
}

@supports (grid-template-columns: 1fr 1fr 1fr 1fr) {
	@media (min-width: 1024px) {
		.video-teaser-grid__item {
			flex: none;
			margin-bottom: 0;
		}
	}
}

@media (max-width: 767px) {
	.video-teaser-grid__item:not(:last-child) {
		margin-bottom: var(--space-x-small);
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.video-teaser-grid__item:nth-child(odd) {
		margin-right: 2.25rem;
	}
}

@media (min-width: 1024px) {
	.video-teaser-grid__item:not(:nth-child(4n + 4)) {
		margin-right: 2.25rem;
	}
}

@supports (grid-template-columns: 1fr 1fr 1fr 1fr) {
	@media (min-width: 1024px) {
		.video-teaser-grid__item:not(:nth-child(4n + 4)) {
			margin-right: 0;
		}
	}
}
