.text-teaser-list {
	overflow: hidden;
	position: relative;
	width: 100%;
}

@media (min-width: 768px) and (max-width: 1023px) {
	.l-text-lists__aside .text-teaser-list {
		display: flex;
		flex-basis: 100%;
		flex-grow: 0;
		flex-shrink: 0;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: stretch;
	}
}

@supports (grid-auto-rows: 1fr) and (grid-template-columns: 1fr 1fr) {
	@media (min-width: 768px) and (max-width: 1023px) {
		.l-text-lists__aside .text-teaser-list {
			display: grid;
			grid-row-gap: var(--space-medium);
			grid-column-gap: var(--space-x-small);
			grid-auto-rows: 1fr;
			grid-template-columns: 1fr 1fr;
		}
	}
}

.text-teaser-list__item:not(:last-child) {
	margin-bottom: var(--space-x-small);
}

@media (min-width: 768px) {
	.text-teaser-list__item:not(:last-child) {
		border-bottom: 1px solid var(--color-alpha-foreground-25);
		margin-bottom: var(--space-medium);
		padding-bottom: var(--space-medium);
	}
}

@media (min-width: 768px) {
	.text-teaser-list--featured .text-teaser-list__item:not(:last-child) {
		border-bottom: 0;
		padding-bottom: 0;
	}
}

@media (min-width: 1440px) {
	.text-teaser-list--featured .text-teaser-list__item:not(:last-child) {
		margin-bottom: var(--space-large);
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.l-text-lists__aside .text-teaser-list__item {
		flex-basis: calc(50% - 0.75rem);
		flex-grow: 0;
		flex-shrink: 0;
	}
}

@supports (grid-auto-rows: 1fr) and (grid-template-columns: 1fr 1fr) {
	@media (min-width: 768px) and (max-width: 1023px) {
		.l-text-lists__aside .text-teaser-list__item {
			flex: none;
			margin-bottom: 0;
		}
	}
}

/* Reset bottom border + padding for items in last row */
@media (min-width: 768px) and (max-width: 1023px) {
	.l-text-lists__aside .text-teaser-list__item:nth-last-child(2):not(:nth-child(even)),
	.l-text-lists__aside .text-teaser-list__item:last-child {
		border-bottom: 0;
		padding-bottom: 0;
	}
}

@media (max-width: 767px) {
	.l-text-lists__aside .text-teaser-list__item:nth-child(3) {
		margin-bottom: 0;
	}
}

@media (max-width: 767px) {
	.l-text-lists__aside .text-teaser-list__item:nth-child(1n + 4) {
		display: none;
	}
}
