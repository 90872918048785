:root {
	--color-background: #ffffff;
	--color-background-light: #f2f2f2; /* table-row */
	--color-background-fullscreen: #f2f2f2;
	/*  */
	--color-alpha-background-0: rgb(255 255 255 / 0);
	--color-alpha-background-10: rgb(255 255 255 / 0.1);
	--color-alpha-background-25: rgb(255 255 255 / 0.25);
	--color-alpha-background-50: rgb(255 255 255 / 0.5);
	--color-alpha-background-90: rgb(255 255 255 / 0.9);
	--color-alpha-background-100: rgb(255 255 255 / 1);
	/*  */
	--color-foreground: #000000;
	--color-foreground-x-light: #eaeaea; /* Blockquote border-left */
	--color-foreground-light: #cccccc; /* background-color: rgba(0, 0, 0, 0.25) OR rgba(255, 255, 255, 0.75) */
	--color-foreground-medium: #808080; /* background-color: rgba(0, 0, 0, 0.5) OR rgba(255, 255, 255, 0.5) */
	--color-foreground-dark: #4d4d4d; /* background-color: rgba(0, 0, 0, 0.75) OR rgba(255, 255, 255, 0.25) */
	/*  */
	--color-alpha-foreground-0: rgb(0 0 0 / 0);
	--color-alpha-foreground-10: rgb(0 0 0 / 0.1);
	--color-alpha-foreground-20: rgb(0 0 0 / 0.2);
	--color-alpha-foreground-25: rgb(0 0 0 / 0.25);
	--color-alpha-foreground-50: rgb(0 0 0 / 0.5);
	--color-alpha-foreground-80: rgb(0 0 0 / 0.8);
	--color-alpha-foreground-100: rgb(0 0 0 / 1);
	/*  */
	--color-error: #ff0000;
	/*  */
	--color-black: #000000;
	--color-white: #ffffff;
	/*  */
	--color-alpha-black-0: rgb(0 0 0 / 0);
	--color-alpha-black-10: rgb(0 0 0 / 0.1);
	--color-alpha-black-25: rgb(0 0 0 / 0.25);
	--color-alpha-black-60: rgb(0 0 0 / 0.6);
	/*  */
	--color-alpha-white-0: rgb(255 255 255 / 0);
	--color-alpha-white-10: rgb(255 255 255 / 0.1);
	--color-alpha-white-25: rgb(255 255 255 / 0.25);
	--color-alpha-white-50: rgb(255 255 255 / 0.5);
	/*  */
	--color-theme-brown: #c25f28;
	--color-theme-crimson: #740f26;
	--color-theme-dark-blue: #223a5e;
	--color-theme-dark-green: #084a3e;
	--color-theme-fuchsia: #e02b58;
	--color-theme-grey: #f2f2f2;
	--color-theme-light-blue: #6699cc;
	--color-theme-light-green: #9c973f;
	--color-theme-orange: #fd823d;
	--color-theme-pink: #f6abc7;
	--color-theme-red: #ee2a27;
	--color-theme-yellow: #ffcc66;
	/*  */
	--font-nib: "Nib Pro", sans-serif;
	--font-sans: "Suisse Intl", sans-serif;
	--font-serif: "Suisse Works", serif;
	--font-mono: "Suisse Intl Mono", serif;
	--font-book: "Suisse Intl Book", serif;
	/*  */
	--font-weight-medium: 500;
	--font-weight-semibold: 600;
	--font-weight-bold: 700;
	/*  */
	--font-style-italic: italic;
	/*  */
	--space-xxx-small: 0.5rem;
	--space-xx-small: 1rem;
	--space-x-small: 1.5rem;
	--space-small: 2rem;
	--space-medium: 2.5rem;
	--space-large: 4.5rem;
	--space-x-large: 5rem;
	/*  */
	--small-screen-site-header-height: 50px;
	--wide-screen-initial-site-header-height: 120px;
	--wide-screen-site-header-height: 56px;
	/*  */
	--teaser-hover-opacity: 0.75;
}

@media (prefers-color-scheme: dark) {
	:root {
		--color-background: #000000;
		--color-background-light: rgb(38 38 38); /* table-row */
		--color-background-fullscreen: #000000;
		/*  */
		--color-alpha-background-0: rgb(0 0 0 / 0);
		--color-alpha-background-10: rgb(0 0 0 / 0.1);
		--color-alpha-background-25: rgb(0 0 0 / 0.25);
		--color-alpha-background-50: rgb(0 0 0 / 0.5);
		--color-alpha-background-90: rgb(0 0 0 / 0.9);
		--color-alpha-background-100: rgb(0 0 0 / 1);
		/*  */
		--color-foreground: #ffffff;
		--color-foreground-x-light: #525252; /* Blockquote border-left */
		--color-foreground-light: #4d4d4d; /* background-color: rgba(0, 0, 0, 0.25) OR rgba(255, 255, 255, 0.75) */
		--color-foreground-medium: #808080; /* background-color: rgba(0, 0, 0, 0.5) OR rgba(255, 255, 255, 0.5) */
		--color-foreground-dark: #cccccc; /* background-color: rgba(0, 0, 0, 0.75) OR rgba(255, 255, 255, 0.25) */
		/*  */
		--color-alpha-foreground-0: rgb(255 255 255 / 0);
		--color-alpha-foreground-20: rgb(255 255 255 / 0.2);
		--color-alpha-foreground-10: rgb(255 255 255 / 0.1);
		--color-alpha-foreground-25: rgb(255 255 255 / 0.25);
		--color-alpha-foreground-50: rgb(255 255 255 / 0.5);
		--color-alpha-foreground-80: rgb(255 255 255 / 0.8);
		--color-alpha-foreground-100: rgb(255 255 255 / 1);

		/* Same color but mingled with 25% alpha black overlayed */
		--color-theme-brown: #904724;
		--color-theme-crimson: #560d1e;
		--color-theme-dark-blue: #1a2c46;
		--color-theme-dark-green: #0a372e;
		--color-theme-fuchsia: #a82141;
		--color-theme-grey: #3a3a3a;
		--color-theme-light-blue: #4e7397;
		--color-theme-light-green: #757034;
		--color-theme-orange: #bc6135;
		--color-theme-pink: #b78195;
		--color-theme-red: #b12224;
		--color-theme-yellow: #be9852;
	}
}
