.banner {
	overflow: hidden;
	padding-top: var(--space-medium);
	padding-bottom: var(--space-medium);
	position: relative;
	text-align: center;
}

@media (min-width: 1440px) {
	.banner {
		padding-top: var(--space-x-large);
		padding-bottom: var(--space-x-large);
	}
}

.banner.banner--media-background {
	/* Double the paddings. */
	padding-top: var(--space-x-large);
	padding-bottom: var(--space-x-large);
}

@media (min-width: 768px) {
	.banner.banner--media-background {
		padding-top: 10rem;
		padding-bottom: 10rem;
	}
}

.banner.banner--media-left,
.banner.banner--media-right {
	text-align: left;
}

.l-single__main .is-style-large:not(:last-child) > .banner {
	margin-bottom: var(--space-medium);
}

.banner__wrapper {
	box-sizing: border-box;
	padding-right: 1rem;
	padding-right: calc(1rem + env(safe-area-inset-right));
	padding-left: 1rem;
	padding-left: calc(1rem + env(safe-area-inset-left));
	width: 100%;
}

@media (min-width: 768px) {
	.banner__wrapper {
		padding-right: 1.875rem;
		padding-right: calc(1.875rem + env(safe-area-inset-right));
		padding-left: 1.875rem;
		padding-left: calc(1.875rem + env(safe-area-inset-left));
	}
}

@media (min-width: 1440px) {
	.banner__wrapper {
		padding-right: calc((100% - 1320px) / 2);
		padding-left: calc((100% - 1320px) / 2);
	}
}

.is-style-large > .banner .banner__wrapper {
	padding-right: var(--space-small);
	padding-right: calc(var(--space-small) + env(safe-area-inset-right));
	padding-left: var(--space-small);
	padding-left: calc(var(--space-small) + env(safe-area-inset-left));
}

@media (min-width: 768px) {
	.is-style-large > .banner .banner__wrapper {
		padding-right: calc(var(--space-medium) + env(safe-area-inset-right));
		padding-left: calc(var(--space-medium) + env(safe-area-inset-left));
	}
}

@media (min-width: 1440px) {
	.is-style-large > .banner .banner__wrapper {
		padding-right: var(--space-x-large);
		padding-left: var(--space-x-large);
	}
}

@media (min-width: 768px) {
	.banner--media-left .banner__wrapper,
	.banner--media-right .banner__wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.banner__attachment-container {
	width: 100%;
}

.banner--media-background .banner__attachment-container {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	z-index: 1;
}

@media (min-width: 768px) {
	.banner--media-left .banner__attachment-container,
	.banner--media-right .banner__attachment-container {
		flex-basis: calc(50% - 1.625rem);
		flex-grow: 0;
		flex-shrink: 0;
		width: auto;
	}
}

@media (min-width: 768px) {
	.banner--media-left .banner__attachment-container:not(:only-child) {
		order: 1;
	}
}

@media (min-width: 768px) {
	.banner--media-right .banner__attachment-container:not(:only-child) {
		order: 2;
	}
}

@media (max-width: 767px) {
	.banner:not(.banner--media-background) .banner__attachment-container {
		margin-bottom: var(--space-x-small);
	}
}

.banner__attachment {
	display: block;
	width: 100%;
}

.banner--media-background .banner__attachment {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	height: auto;
}

@supports (object-fit: cover) {
	.banner--media-background .banner__attachment {
		object-fit: cover;
		position: relative;
		top: auto;
		left: auto;
		transform: none;
		height: 100%;
	}
}

@media (min-width: 768px) {
	.banner__body {
		margin-right: auto;
		margin-left: auto;
		width: 80%;
	}
}

@media (min-width: 1024px) {
	.banner__body {
		width: 66.6666%;
	}
}

.banner--media-background .banner__body {
	position: relative;
	z-index: 2;
}

@media (min-width: 768px) {
	.banner--media-left .banner__body,
	.banner--media-right .banner__body {
		flex-basis: calc(50% - 1.625rem);
		flex-grow: 0;
		flex-shrink: 0;
		margin-right: 0;
		margin-left: 0;
		width: auto;
	}
}

@media (min-width: 1024px) {
	.banner--media-left .banner__body,
	.banner--media-right .banner__body {
		flex-basis: 40%;
	}
}

@media (min-width: 768px) {
	.banner--media-left .banner__body {
		order: 2;
	}
}

@media (min-width: 768px) {
	.banner--media-right .banner__body {
		order: 1;
	}
}

.banner__description:not(:last-child),
.banner__title:not(:last-child) {
	margin-bottom: 1.25rem;
}

@media (min-width: 1440px) {
	.banner__description:not(:last-child),
	.banner__title:not(:last-child) {
		margin-bottom: var(--space-medium);
	}
}

.banner__mobile-header {
	margin-bottom: 1.25rem;
}

@media (min-width: 768px) {
	.banner__mobile-header {
		display: none;
	}
}

.banner--media-background .banner__mobile-header {
	display: none;
}

@media (max-width: 767px) {
	.banner:not(.banner--media-background) .banner__mobile-header ~ .banner__body .banner__title {
		display: none;
	}
}

.banner__description ol,
.banner__description ul {
	display: inline-block;
}
