.l-columns {
	display: flex;
	flex-wrap: wrap;
}

.l-columns__column {
	flex-basis: calc(50% - 1rem);
	flex-grow: 1; /* necessary hack for ie10/11 to support flex-basis with calc */
	flex-shrink: 0; /* necessary hack for ie10/11 to support flex-basis with calc */
	margin-right: 1rem;
	margin-bottom: 0;
}

@media (min-width: 1440px) {
	.l-columns__column {
		flex-basis: calc(50% - 1.5rem);
		margin-right: 1.5rem;
	}
}

.l-columns__column:last-child {
	margin-right: 0;
}

.l-columns--3 .l-columns__column {
	flex-basis: calc(33.33333% - 1rem);
}

@media (min-width: 1440px) {
	.l-columns--3 .l-columns__column {
		flex-basis: calc(33.33333% - 1.5rem);
	}
}

@media (max-width: 767px) {
	.l-columns--from-small .l-columns__column {
		flex-basis: 100%;
		margin-right: 0;
		margin-bottom: var(--space-medium);
	}
}

@media (max-width: 1023px) {
	.l-columns--from-medium .l-columns__column {
		flex-basis: 100%;
		margin-right: 0;
		margin-bottom: var(--space-medium);
	}
}

@media (max-width: 1439px) {
	.l-columns--from-wide .l-columns__column {
		flex-basis: 100%;
		margin-right: 0;
		margin-bottom: var(--space-medium);
	}
}

@media (max-width: 767px) {
	.l-columns--from-small .l-columns__column:last-child,
	.l-columns--from-medium .l-columns__column:last-child,
	.l-columns--from-wide .l-columns__column:last-child {
		margin-bottom: 0;
	}
}
