.featured-thumb {
	position: relative;
}

.featured-thumb__link + .featured-thumb__text {
	position: absolute;
	bottom: 0;
	left: 0;
	transform: translateY(100%) translateY(var(--space-x-small));
}

.featured-thumb__text .featured-thumb__text--text-align-left {
	text-align: left;
}

.featured-thumb__text .featured-thumb__text--text-align-center {
	text-align: center;
}

.featured-thumb__text .featured-thumb__text--text-align-right {
	text-align: right;
}

.featured-thumb__link[href] {
	background-color: currentColor;
	color: currentColor;
	display: block;
	text-decoration: none;
}

.featured-thumb__link[href]:focus {
	outline: 0;
}

.featured-thumb__link[href] .featured-thumb__media {
	opacity: 1;
	transition: opacity 96ms linear;
}

@media (prefers-reduced-motion: reduce) {
	.featured-thumb__link[href] .featured-thumb__media {
		transition: none;
	}
}

.featured-thumb__link[href] .featured-thumb__media:focus,
.featured-thumb__link[href] .featured-thumb__media:hover {
	opacity: var(--teaser-hover-opacity);
}
