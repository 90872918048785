.compact-teaser {
	display: block;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.compact-teaser__link {
	color: currentColor;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	text-decoration: none;
	width: 100%;
}

@media (max-width: 1023px) {
	.compact-teaser--featured .compact-teaser__link {
		display: block;
	}
}

.compact-teaser__thumbnail-container {
	background-color: var(--color-alpha-foreground-25);
	flex-basis: 58px;
	flex-grow: 0;
	flex-shrink: 0;
	margin-right: 1.25rem;
	overflow: hidden;
	position: relative;
	transition: opacity 96ms linear;
	width: 58px;
	height: 58px;
}

@media (min-width: 768px) {
	.compact-teaser__thumbnail-container {
		flex-basis: 96px;
		width: 96px;
		height: 96px;
	}
}

@media (min-width: 1440px) {
	.compact-teaser__thumbnail-container {
		flex-basis: 112px;
		margin-right: var(--space-x-small);
		width: 112px;
		height: 112px;
	}
}

@media (prefers-reduced-motion: reduce) {
	.compact-teaser__thumbnail-container {
		transition: none;
	}
}

.compact-teaser__link:focus .compact-teaser__thumbnail-container,
.compact-teaser__link:hover .compact-teaser__thumbnail-container {
	opacity: var(--teaser-hover-opacity);
}

@media (max-width: 1023px) {
	.compact-teaser--featured .compact-teaser__thumbnail-container:not(:last-child) {
		margin-bottom: 1.25rem;
		padding-bottom: 56.26%;
		width: 100%;
		height: auto;
	}
}

.compact-teaser__thumbnail {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}

@supports (object-fit: cover) {
	.compact-teaser__thumbnail {
		object-fit: cover;
		height: 100%;
	}
}

.compact-teaser__video-icon {
	background-color: var(--color-alpha-black-60);
	border: 1px solid var(--color-alpha-white-50);
	color: var(--color-white);
	display: inline-flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	bottom: var(--space-xx-small);
	left: var(--space-xx-small);
	width: 2.25rem;
	height: 2.25rem;
	z-index: 2;
}

@supports (backdrop-filter: blur(2px)) {
	.compact-teaser__video-icon {
		backdrop-filter: blur(2px);
		background-color: var(--color-alpha-black-10);
	}
}

.compact-teaser__video-icon .icon {
	display: inline-block;
	fill: currentColor;
	width: 0.5625rem;
	height: 0.5625rem;
}

.compact-teaser__content {
	flex-basis: auto;
	flex-grow: 1;
	flex-shrink: 1;
	transform: translateY(-4px);
}

.compact-teaser__title {
	display: inline-block;
	hyphens: auto;
	word-break: auto-phrase;
}

.compact-teaser__title:not(:last-child) {
	margin-bottom: 0.3125rem;
}

@supports (-webkit-line-clamp: 3) {
	.compact-teaser__title {
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		display: block;
		display: -webkit-box;
		overflow: hidden;
	}
}

.compact-teaser__title__label {
	border-bottom: 1px solid var(--color-alpha-foreground-0);
	display: inline;
	padding-bottom: 1px;
	transition: border-bottom-color 64ms linear;
}

.compact-teaser__link:focus .compact-teaser__title__label,
.compact-teaser__link:hover .compact-teaser__title__label {
	border-bottom-color: var(--color-foreground);
}

@media (prefers-reduced-motion: reduce) {
	.compact-teaser__title__label {
		transition: none;
	}
}

.compact-teaser__meta {
	display: block;
	line-height: 1;
	overflow: hidden;
	position: relative;
	text-overflow: clip;
	white-space: nowrap;
	width: 100%;
	max-width: 200px;
}

@media (min-width: 375px) {
	.compact-teaser__meta {
		max-width: 250px;
	}
}

@media (min-width: 768px) {
	.compact-teaser__meta {
		max-width: 225px;
	}
}

@media (min-width: 1024px) {
	.compact-teaser__meta {
		max-width: 175px;
	}
}

@media (min-width: 1280px) {
	.compact-teaser__meta {
		max-width: 250px;
	}
}

@media (min-width: 1440px) {
	.compact-teaser__meta {
		max-width: 275px;
	}
}

.compact-teaser__meta::after {
	background: linear-gradient(90deg, var(--color-alpha-background-0) 0%, var(--color-alpha-background-100) 80%);
	content: "";
	position: absolute;
	top: -5%;
	right: -5px;
	width: 25px;
	height: 110%;
}

.compact-teaser__meta__item {
	display: inline-block;
	opacity: 0.5;
	overflow: hidden;
	text-overflow: ellipsis;
	user-select: none;
	white-space: nowrap;
	max-width: 120px;
}

.compact-teaser__meta__item:not(:last-child) {
	margin-right: 0.375rem;
}

@media (min-width: 568px) {
	.compact-teaser__meta__item:not(:last-child) {
		margin-right: 0.75rem;
	}
}
