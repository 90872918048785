/*
 * SITE CONTAINER STRUCTURE
 *
 * Components:
 *
 *    A → Logo (large screens only)
 *    B → Navigation
 *    - B1 → Menu
 *    - B2 → Featured content
 *    C → Header
 *    D → Body
 *
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 * Small screens (up to 1280px)
 *
 * |-------------------------|
 * |            C            |
 * |-------------------------|
 * |                         |
 * |                         |
 * |                         |
 * |            D            |
 * |                         |
 * |                         |
 * |                         |
 * |-------------------------|
 *
 * With menu open:
 *
 * |-------------------------|
 * |            C            |
 * |-------------------------|
 * |                         |
 * |            B1           |
 * |                         |
 * |-------------------------|
 * |                         |
 * |            B2           |
 * |                         |
 * |-------------------------|
 *
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 * Large screens (1280px and up)
 *
 * |-------------------------|
 * |            A            |
 * |-------------------------|
 * |            C            |
 * |-------------------------|
 * |                         |
 * |                         |
 * |            D            |
 * |                         |
 * |                         |
 * |-------------------------|
 *
 * With menu open:
 *
 * |----------------|--------|
 * |                |        |
 * |                |        |
 * |                |        |
 * |                |        |
 * |       B2       |   B1   |
 * |                |        |
 * |                |        |
 * |                |        |
 * |                |        |
 * |----------------|--------|
 *
 */

.l-site-container {
	position: relative;
	width: 100%;
}

/* -------- A: Logo -------- */
.l-site-container__logo-container {
	box-sizing: border-box;
	display: flex;
	overflow: hidden;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 100%;
	height: 64px;
	z-index: 3;
}

@media (max-width: 1279px) {
	.l-site-container__logo-container {
		display: none;
	}
}

.l-site-container__logo {
	color: var(--color-foreground);
	display: block;
	opacity: 1;
	text-decoration: none;
	transition: opacity 96ms linear;
	width: auto;
	height: auto;
}

.l-site-container__logo:focus,
.l-site-container__logo:hover {
	opacity: 0.5;
}

.l-site-container__logo:focus {
	outline: 0;
}

.l-site-container__logo:hover {
	cursor: pointer;
}

.l-site-container__logo .icon {
	display: block;
	fill: currentColor;
	transform: translate3d(0, 0, 0); /* Fix jitter on hover in Safari */
	width: 195px;
	height: 20px;
}

.l-site-container__logo .icon.icon-logo-fai-single-line {
	width: 349px;
}

/* Users with JS enabled will get a site-header with white color and a
	 * blurry background until they scroll down. The logo follows this state.
	 */

html:not(.no-js) .body--starts-with-dark-colors:not(.is-scrolled) .l-site-container__logo {
	color: var(--color-white);
}

/* --------- A end --------- */

/* ----- B: Navigation ----- */
.l-site-container__navigation {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}

@media (max-width: 1279px) {
	.l-site-container__navigation {
		box-sizing: border-box;
		padding-top: var(--small-screen-site-header-height);
		scroll-behavior: smooth;
		z-index: 9997;
	}
}

@media (min-width: 1280px) {
	.l-site-container__navigation {
		position: fixed;
		z-index: 9998;
	}
}

body.has-open-gallery .l-site-container .l-site-container__navigation {
	z-index: 2;
}
/* If pointer-events: none; is bit supported, use basic show and hide */
#site-container-navigation-toggle:checked ~ .l-site-container__navigation {
	display: block;
}

@media (min-width: 1280px) {
	#site-container-navigation-toggle:checked ~ .l-site-container__navigation {
		height: 100%;
	}
}
/* If pointer-events: none; is supported, we can use a nice transition */
@supports (pointer-events: none) {
	.l-site-container__navigation {
		display: block;
		opacity: 0;
		overflow: hidden;
		pointer-events: none;
		height: 0;
	}

	@media (max-width: 1279px) {
		.l-site-container__navigation {
			background-color: var(--color-background);
			transition: opacity 96ms linear;
		}
	}

	@media (min-width: 1280px) {
		.l-site-container__navigation {
			transition: opacity 96ms linear 512ms;
		}
	}

	#site-container-navigation-toggle:checked ~ .l-site-container__navigation {
		opacity: 1;
		pointer-events: auto;
		height: auto;
	}

	@media (min-width: 1280px) {
		#site-container-navigation-toggle:checked ~ .l-site-container__navigation {
			transition: opacity 96ms linear;
			height: 100%;
		}
	}
}

.l-site-container__navigation__scroll-container {
	width: 100%;
}

@media (max-width: 1279px) {
	.l-site-container__navigation__scroll-container {
		min-height: calc(100vh - var(--small-screen-site-header-height));
	}
}

@media (max-width: 1279px) and (hover: none) {
	.l-site-container__navigation__scroll-container {
		-webkit-overflow-scrolling: touch;
	}
}

@media (min-width: 1280px) {
	.l-site-container__navigation__scroll-container {
		overflow: hidden;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
	}
}

@supports (pointer-events: none) {
	@media (max-width: 1279px) {
		.l-site-container__navigation__featured,
		.l-site-container__navigation__menu {
			opacity: 0;
			transform: translate3d(0, 2.5rem, 0);
			transition:
				opacity 112ms linear 256ms,
				transform 400ms cubic-bezier(0.65, 0, 0, 1) 112ms;
		}
	}

	@media (max-width: 1279px) {
		#site-container-navigation-toggle:checked ~ .l-site-container__navigation .l-site-container__navigation__featured,
		#site-container-navigation-toggle:checked ~ .l-site-container__navigation .l-site-container__navigation__menu {
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}
	}
}

.l-site-container__navigation__featured {
	box-sizing: border-box;
	display: flex;
	padding: 1rem calc(1rem + env(safe-area-inset-left));
	justify-content: center;
	align-items: center;
	width: 100%;
}

@media (max-width: 1279px) {
	.l-site-container__navigation__featured {
		position: relative;
		min-height: 100vh;
	}
}

@media (max-width: 1023px) and (orientation: landscape) {
	.l-site-container__navigation__featured {
		min-height: 100vw;
	}
}

@media (min-width: 1280px) {
	.l-site-container__navigation__featured {
		box-sizing: border-box;
		padding: 5rem calc(33.3333% + 3.5rem) 5rem 3.5rem;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		z-index: 1;
	}
}

@media (max-width: 1279px) {
	.l-site-container__navigation__featured.l-site-container__navigation__featured--empty {
		display: none;
	}
}

@supports (pointer-events: none) {
	@media (min-width: 1280px) {
		.l-site-container__navigation__featured-inner,
		.l-site-container__navigation__featured-logo {
			opacity: 0;
			transition: opacity 96ms linear 512ms;
		}
	}

	@media (min-width: 1280px) {
		#site-container-navigation-toggle:checked ~ .l-site-container__navigation .l-site-container__navigation__featured-inner,
		#site-container-navigation-toggle:checked ~ .l-site-container__navigation .l-site-container__navigation__featured-logo {
			opacity: 1;
			transition: opacity 96ms linear 400ms;
		}
	}
}

.l-site-container__navigation__featured-logo {
	color: currentColor;
	display: block;
	position: absolute;
	top: 3.25rem;
	left: 3.25rem;
	width: auto;
	height: auto;
}

@media (max-width: 1279px) {
	.l-site-container__navigation__featured-logo {
		display: none;
	}
}

.l-site-container__navigation__featured-logo .icon {
	display: block;
	fill: currentColor;
	transition: opacity 96ms linear;
	width: 195px;
	height: 20px;
}

.l-site-container__navigation__featured-logo .icon.icon-logo-fai-single-line {
	width: 349px;
}

.l-site-container__navigation__featured-logo:focus .icon,
.l-site-container__navigation__featured-logo:hover .icon {
	opacity: 0.5;
}

.l-site-container__navigation__featured-inner {
	text-align: center;
}

.l-site-container__navigation__menu-container {
	background-color: var(--color-background);
	box-sizing: border-box;
	color: var(--color-foreground);
	position: relative;
	width: 100%;
	z-index: 1;
}

@media (max-width: 1279px) {
	.l-site-container__navigation__menu-container {
		overflow: hidden;
		height: auto;
	}
}

@media (min-width: 1280px) {
	.l-site-container__navigation__menu-container {
		overflow-x: hidden;
		overflow-y: scroll;
		padding: 6.25rem var(--space-medium) var(--space-medium) var(--space-medium);
		position: absolute;
		top: 0;
		right: 0;
		width: 33.3333%;
		height: 100%;
		z-index: 2;
	}
}

@media (min-width: 1280px) and (hover: none) {
	.l-site-container__navigation__menu-container {
		-webkit-overflow-scrolling: touch;
	}
}

@supports (pointer-events: none) {
	@media (min-width: 1280px) {
		.l-site-container__navigation__menu-container {
			backface-visibility: hidden;
			transform: translate3d(100%, 0, 0);
			transition: transform 560ms cubic-bezier(0.715, 0, 0.01, 1);
		}
	}

	@media (min-width: 1280px) {
		#site-container-navigation-toggle:checked ~ .l-site-container__navigation .l-site-container__navigation__menu-container {
			transform: translate3d(0, 0, 0);
			transition: transform 688ms cubic-bezier(0.755, 0, 0, 1) 112ms;
		}
	}
}

.l-site-container__navigation__close-button {
	color: var(--color-foreground);
	position: absolute;
	top: 47px;
	right: 40px;
	transition: color 96ms linear;
}

.l-site-container__navigation__close-button .icon {
	display: block;
	fill: currentColor;
	width: 14px;
	height: 14px;
	z-index: 2;
}

@media (max-width: 1279px) {
	.l-site-container__navigation__close-button {
		display: none;
	}
}

.l-site-container__navigation__close-button:focus,
.l-site-container__navigation__close-button:hover {
	color: var(--color-foreground-medium);
}

.l-site-container__navigation__close-button:focus {
	outline: 0;
}

.l-site-container__navigation__close-button:hover {
	cursor: pointer;
}

.l-site-container__navigation__languages {
	width: 100%;
}

@media (min-width: 768px) and (max-width: 1279px) {
	.l-site-container__navigation__languages {
		margin-right: auto;
		margin-left: auto;
		width: calc(100% - 3.75rem - env(safe-area-inset-left) - env(safe-area-inset-right));
	}
}

@media (max-width: 1279px) {
	.l-site-container__navigation__languages {
		background-color: var(--color-foreground);
		box-sizing: border-box;
		color: var(--color-background);
		padding: 1rem;
	}
}

@media (min-width: 1280px) {
	.l-site-container__navigation__languages {
		position: absolute;
		top: 45px;
		left: 40px;
		width: auto;
		z-index: 2;
	}
}

.l-site-container__navigation__menu {
	z-index: 1;
}

@media (max-width: 767px) {
	.l-site-container__navigation__menu {
		padding: 1.875rem calc(1rem + env(safe-area-inset-left));
	}
}

@media (min-width: 768px) and (max-width: 1279px) {
	.l-site-container__navigation__menu {
		padding: 1.875rem calc(1.875rem + env(safe-area-inset-left));
	}
}

/* --------- B end --------- */

/* ------- C: Header ------- */
.l-site-container__header {
	position: relative; /* Fallback for IE11 so z-index is used */
	position: sticky;
	top: -1px;
	z-index: 9998;
}

@media (min-width: 1280px) {
	.l-site-container__header {
		z-index: 9997;
	}
}

body.has-open-gallery .l-site-container .l-site-container__header {
	z-index: 2;
}
/* Don't use sticky header for screens that are too small or too zoomed in */
@media (max-height: 33.75rem) {
	.l-site-container__header {
		position: relative !important;
		top: auto; /* 540px */
	}
}

@media print {
	.l-site-container__header {
		display: none;
	}
}

/* --------- C end --------- */

/* -------- D: Body -------- */
.l-site-container__body {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	position: relative;
	min-height: calc(100vh - var(--small-screen-site-header-height));
	z-index: 1;
}

@media (min-width: 1280px) {
	.l-site-container__body {
		min-height: calc(100vh - var(--wide-screen-initial-site-header-height));
	}
}

body.has-open-gallery .l-site-container .l-site-container__body {
	z-index: 9998;
}

@media (min-width: 1280px) {
	body.has-open-gallery .l-site-container .l-site-container__body {
		z-index: 9997;
	}
}

@supports (pointer-events: none) {
	@media (max-width: 1279px) {
		.l-site-container__body {
			opacity: 1;
			transition: opacity 112ms linear 112ms;
		}
	}

	#site-container-navigation-toggle:checked ~ .l-site-container__body {
		overflow: hidden;
		max-height: calc(100vh - var(--wide-screen-initial-site-header-height));
	}

	@media (max-width: 1279px) {
		#site-container-navigation-toggle:checked ~ .l-site-container__body {
			opacity: 0;
			transition: opacity 112ms linear 144ms;
			max-height: calc(100vh - var(--small-screen-site-header-height));
		}
	}
}

.l-site-container__body__main {
	flex-basis: auto;
	flex-grow: 1;
	flex-shrink: 0;
	padding-top: var(--space-medium);
	padding-bottom: var(--space-medium);
	width: 100%;
}

@media (min-width: 1280px) {
	.l-site-container__body__main {
		padding-top: var(--space-x-large);
		padding-bottom: var(--space-x-large);
	}
}

.l-site-container__body__main.l-site-container__body__main--no-vertical-padding {
	padding-top: 0;
	padding-bottom: 0;
}

.l-site-container__body__main:empty {
	display: none;
}

.l-site-container__body__doormat {
	box-sizing: border-box;
	display: block;
	padding-top: var(--space-medium);
	padding-bottom: var(--space-medium);
	width: 100%;
}

@media (min-width: 1280px) {
	.l-site-container__body__doormat {
		padding-top: var(--space-x-large);
		padding-bottom: var(--space-x-large);
	}
}

.l-site-container__body__footer {
	flex-basis: auto;
	flex-grow: 0;
	flex-shrink: 0;
	width: 100%;
}

@media print {
	.l-site-container__body__footer {
		display: none;
	}
}

/* --------- D end --------- */

/* ---- Reduced motion ----- */
@media (prefers-reduced-motion: reduce) {
	.l-site-container__body,
	.l-site-container__navigation,
	.l-site-container__navigation__featured,
	.l-site-container__navigation__featured-inner,
	.l-site-container__navigation__menu,
	.l-site-container__navigation__menu-container {
		transition: none !important;
	}
}

/* #region Grey Background */
.l-site-container--grey-background {
	background-color: var(--color-theme-grey);
}

.l-site-container--grey-background hr,
.l-site-container--grey-background .header .header__wrapper::before {
	opacity: 1;
}
/* #endregion Grey Background */
