.spotlight-video-header {
	box-sizing: border-box;
	margin-top: calc(var(--small-screen-site-header-height) * -1);
	overflow: hidden;
	position: relative;
}

@media (min-width: 1280px) {
	.spotlight-video-header {
		margin-top: calc(var(--wide-screen-initial-site-header-height) * -1);
	}
}

.spotlight-video-header__link {
	box-sizing: border-box;
	color: currentColor;
	display: block;
	padding-top: var(--small-screen-site-header-height);
	padding-bottom: 2.25rem;
	text-decoration: none;
	width: 100%;
}

@media (min-width: 768px) {
	.spotlight-video-header__link {
		padding-top: calc(var(--small-screen-site-header-height) + 2.25rem);
		padding-right: 1.875rem;
		padding-right: calc(1.875rem + env(safe-area-inset-right));
		padding-left: 1.875rem;
		padding-left: calc(1.875rem + env(safe-area-inset-left));
	}
}

@media (min-width: 1024px) {
	.spotlight-video-header__link {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

@media (min-width: 1280px) {
	.spotlight-video-header__link {
		padding-top: calc(var(--wide-screen-initial-site-header-height) + 4.875rem);
		padding-bottom: 4.875rem;
	}
}

@media (min-width: 1440px) {
	.spotlight-video-header__link {
		padding-right: calc((100% - 1320px) / 2);
		padding-left: calc((100% - 1320px) / 2);
	}
}

.spotlight-video-header__thumbnail-container {
	position: relative;
}

@media (min-width: 1024px) {
	.spotlight-video-header__thumbnail-container {
		flex-basis: 60%;
		flex-grow: 0;
		flex-shrink: 0;
		margin-right: 4rem;
	}
}

@media (min-width: 1280px) {
	.spotlight-video-header__thumbnail-container {
		flex-basis: 63%;
	}
}

@media (--up-to-medium) {
	.spotlight-video-header__thumbnail-container:not(:last-child) {
		margin-bottom: var(--space-medium);
	}
}

.spotlight-video-header__link:focus .spotlight-video-header__thumbnail-container,
.spotlight-video-header__link:hover .spotlight-video-header__thumbnail-container {
	opacity: var(--teaser-hover-opacity);
}

@media (prefers-reduced-motion: reduce) {
	.spotlight-video-header__thumbnail-container {
		transition: none;
	}
}

.spotlight-video-header__duration {
	background-color: var(--color-alpha-black-60);
	border: 1px solid var(--color-alpha-white-50);
	color: var(--color-white);
	display: inline-flex;
	padding: 0.625rem 0.875rem;
	justify-content: flex-start;
	align-items: center;
	position: absolute;
	bottom: var(--space-xx-small);
	left: var(--space-xx-small);
	z-index: 2;
}

@supports (backdrop-filter: blur(2px)) {
	.spotlight-video-header__duration {
		backdrop-filter: blur(2px);
		background-color: var(--color-alpha-black-10);
	}
}

.spotlight-video-header__duration__icon {
	margin-right: 0.5625rem;
}

.spotlight-video-header__duration__icon .icon {
	display: inline-block;
	fill: currentColor;
	width: 0.5625rem;
	height: 0.5625rem;
}

@media (max-width: 767px) {
	.spotlight-video-header__content {
		padding-right: 1rem;
		padding-right: calc(1rem + env(safe-area-inset-right));
		padding-left: 1rem;
		padding-left: calc(1rem + env(safe-area-inset-left));
	}
}

@media (max-width: 1023px) {
	.spotlight-video-header__content {
		padding-top: 3rem;
	}
}

@media (min-width: 1024px) {
	.spotlight-video-header__content {
		flex-basis: auto;
		flex-grow: 1;
		flex-shrink: 1;
	}
}

@media (min-width: 1024px) {
	.spotlight-video-header__content__heading {
		flex-basis: auto;
		flex-grow: 1;
		flex-shrink: 1;
	}
}

.spotlight-video-header__content__heading:not(:last-child) {
	margin-bottom: 3rem;
}

@media (min-width: 1024px) {
	.spotlight-video-header__content__heading:not(:last-child) {
		margin-bottom: var(--space-xx-small);
	}
}

@media (min-width: 1024px) {
	.spotlight-video-header__content__excerpt {
		flex-basis: calc(50% - var(--space-x-small));
		flex-grow: 0;
		flex-shrink: 0;
		padding-bottom: 4px;
		transform: translateY(4px);
	}
}

@media (min-width: 1440px) {
	.spotlight-video-header__content__excerpt {
		padding-bottom: 6px;
		transform: translateY(6px);
	}
}

.spotlight-video-header__content__excerpt p:not(:last-child) {
	margin-bottom: 0.625rem;
}

@supports (-webkit-line-clamp: 3) {
	@media (min-width: 1024px) {
		.spotlight-video-header__content__excerpt {
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 4;
			display: block;
			display: -webkit-box;
			overflow: hidden;
		}
	}

	@media (min-width: 1440px) {
		.spotlight-video-header__content__excerpt {
			-webkit-line-clamp: 5;
		}
	}

	@media (min-width: 1680px) {
		.spotlight-video-header__content__excerpt {
			-webkit-line-clamp: 6;
		}
	}
}

.spotlight-video-header__title {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 0.625rem;
	width: 100%;
}

@supports (-webkit-line-clamp: 3) {
	@media (min-width: 1024px) {
		.spotlight-video-header__title {
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
			display: block;
			display: -webkit-box;
			overflow: hidden;
		}
	}
}

.spotlight-video-header__title__label {
	border-bottom: 2px solid var(--color-alpha-white-0);
	display: inline;
	padding-bottom: 1px;
	transition: border-bottom-color 64ms linear;
}

@media (min-width: 1024px) {
	.spotlight-video-header__title__label {
		border-bottom-width: 3px;
	}
}

@media (min-width: 1440px) {
	.spotlight-video-header__title__label {
		padding-bottom: 2px;
	}
}

.spotlight-video-header__link:focus .spotlight-video-header__title__label,
.spotlight-video-header__link:hover .spotlight-video-header__title__label {
	border-bottom-color: currentColor;
}

@media (prefers-reduced-motion: reduce) {
	.spotlight-video-header__title__label {
		transition: none;
	}
}

.spotlight-video-header__meta {
	display: inline-block;
	opacity: var(--teaser-hover-opacity);
	width: 100%;
}

@media (max-width: 767px) {
	.spotlight-video-header__meta__date-prefix {
		display: none;
	}
}
