.section {
	margin-top: 2rem;
	margin-bottom: 2rem;
	position: relative;
}

@media (min-width: 768px) {
	.section {
		margin-top: 3rem;
		margin-bottom: 3rem;
	}
}

@media (min-width: 1024px) {
	.section {
		margin-top: 4rem;
		margin-bottom: 4rem;
	}
}

@media (min-width: 1440px) {
	.section {
		margin-top: 5rem;
		margin-bottom: 5rem;
	}
}

.section + .section {
	margin-top: 0;
}

.section[class^="section theme-"]:not(.theme-white) {
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

@media (min-width: 768px) {
	.section[class^="section theme-"]:not(.theme-white) {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}
}

@media (min-width: 1024px) {
	.section[class^="section theme-"]:not(.theme-white) {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}
}

@media (min-width: 1440px) {
	.section[class^="section theme-"]:not(.theme-white) {
		padding-top: 5rem;
		padding-bottom: 5rem;
	}
}

.section[class^="section theme-"]:not(.theme-white) + .section[class^="section theme-"]:not(.theme-white) {
	padding-top: 0;
}

.l-site-container__body__main:not(.l-site-container__body__main--no-vertical-padding) > .section:first-child {
	margin-top: 0;
}

.l-site-container__body__main:not(.l-site-container__body__main--no-vertical-padding) > .section:last-child {
	margin-bottom: 0;
}
