.video-teaser {
	display: block;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.video-teaser__link {
	color: currentColor;
	display: block;
	text-decoration: none;
	width: 100%;
}

.video-teaser__thumbnail-container {
	background-color: var(--color-alpha-foreground-25);
	margin-bottom: var(--space-xx-small);
	overflow: hidden;
	padding-bottom: 66.6666%;
	position: relative;
	transition: opacity 96ms linear;
	width: 100%;
}

@media (prefers-reduced-motion: reduce) {
	.video-teaser__thumbnail-container {
		transition: none;
	}
}

.video-teaser__link:focus .video-teaser__thumbnail-container,
.video-teaser__link:hover .video-teaser__thumbnail-container {
	opacity: var(--teaser-hover-opacity);
}

.video-teaser__thumbnail {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1;
}

@supports (object-fit: cover) {
	.video-teaser__thumbnail {
		object-fit: cover;
		height: 100%;
	}
}

.video-teaser__duration {
	background-color: var(--color-alpha-black-60);
	border: 1px solid var(--color-alpha-white-50);
	color: var(--color-white);
	display: inline-flex;
	padding: 0.625rem 0.875rem;
	justify-content: flex-start;
	align-items: center;
	position: absolute;
	bottom: var(--space-xx-small);
	left: var(--space-xx-small);
	z-index: 2;
}

@supports (backdrop-filter: blur(2px)) {
	.video-teaser__duration {
		backdrop-filter: blur(2px);
		background-color: var(--color-alpha-black-10);
	}
}

.video-teaser__duration__icon {
	margin-right: 0.5625rem;
}

.video-teaser__duration__icon .icon {
	display: inline-block;
	fill: currentColor;
	width: 0.5625rem;
	height: 0.5625rem;
}

.video-teaser__title {
	display: inline-block;
	hyphens: auto;
	margin-top: 0;
	margin-bottom: 0.25rem;
	word-break: auto-phrase;
}

@supports (-webkit-line-clamp: 3) {
	@media (min-width: 768px) {
		.video-teaser__title {
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
			display: block;
			display: -webkit-box;
			overflow: hidden;
		}
	}
}

.video-teaser__title__label {
	border-bottom: 1px solid var(--color-alpha-foreground-0);
	display: inline;
	padding-bottom: 1px;
	transition: border-bottom-color 64ms linear;
}

.video-teaser__link:focus .video-teaser__title__label,
.video-teaser__link:hover .video-teaser__title__label {
	border-bottom-color: var(--color-foreground);
}

@media (prefers-reduced-motion: reduce) {
	.video-teaser__title__label {
		transition: none;
	}
}

.video-teaser__meta__item {
	display: inline-block;
	opacity: 0.5;
	overflow: hidden;
	text-overflow: ellipsis;
	user-select: none;
	white-space: nowrap;
	max-width: calc((1320px / 4) - (var(--space-x-small) * 3));
}

/* based on teaser-row */

.video-teaser__meta__item:not(:last-child) {
	margin-right: 0.75rem;
}

@media (min-width: 768px) {
	.teaser--featured .video-teaser__meta__item {
		display: none;
	}
}
