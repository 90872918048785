.fullscreen-toggle-container {
	position: relative;
}

.fullscreen-toggle-container .fullscreen-toggle-container__toggle {
	-mrh-resets: button;
	background-color: var(--color-theme-dark-green);
	border-radius: calc(3.125rem / 2);
	display: none;
	place-content: center;
	position: absolute;
	top: 50px;
	right: -25px;
	transform: translateY(-50%);
	width: 3.125rem;
	height: 3.125rem;
}

/* mq-from-medium */
@media (--mq-from-medium) {
	.fullscreen-toggle-container[data-request-fullscreen-supported] .fullscreen-toggle-container__toggle {
		display: grid;
	}
}

.fullscreen-toggle-container .fullscreen-toggle-container__toggle:hover {
	background-color: var(--color-black);
}

.fullscreen-toggle-container .icon-fullscreen {
	fill: var(--color-white);
	width: 0.875rem;
	height: 0.875rem;
}

.fullscreen-toggle-container mr-fullscreen-toggle:fullscreen > * {
	margin: 0;
}

.fullscreen-toggle-container mr-fullscreen-toggle:fullscreen {
	background-color: var(--color-background-fullscreen);
	overflow-y: scroll;
}
