@media (min-width: 1024px) {
	.checklist-teaser-grid {
		display: flex;
		flex-wrap: wrap;
	}
}

@media (min-width: 1024px) {
	.checklist-teaser-grid__item {
		flex-basis: calc(50% - 0.5625rem);
		flex-shrink: 0;
	}
}

@media (min-width: 1440px) {
	.checklist-teaser-grid__item {
		flex-basis: calc(50% - 0.75rem);
	}
}

@media (min-width: 1024px) {
	.checklist-teaser-grid__item:nth-child(odd) {
		margin-right: 1.125rem;
	}
}

@media (min-width: 1440px) {
	.checklist-teaser-grid__item:nth-child(odd) {
		margin-right: 1.5rem;
	}
}

.checklist-teaser-grid__item:not(:last-child) {
	margin-bottom: 1.125rem;
}

@media (min-width: 1440px) {
	.checklist-teaser-grid__item:not(:last-child) {
		margin-bottom: 1.5rem;
	}
}

@media (min-width: 1024px) {
	.checklist-teaser-grid__item:nth-last-child(2):nth-child(odd) {
		margin-bottom: 0;
	}
}
