.google-slides {
	break-inside: avoid;
	display: block;
	overflow: hidden;
	padding-bottom: calc(100% * (569 / 960)); /* Follow default 960 x 569 ratio. */
	position: relative;
	width: 100%;
}

.google-slides__iframe {
	border: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
