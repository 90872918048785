.type-c {
	font-family: var(--font-sans);
	font-size: 1.375rem;
	font-weight: var(--font-weight-medium);
	line-height: 1.45;
}

@media (min-width: 1024px) {
	.type-c {
		font-size: 1.5rem;
		line-height: 1.495;
	}
}

@media (min-width: 1440px) {
	.type-c {
		font-size: 1.625rem;
		line-height: 1.54;
	}
}

.type-c a:not([class]) {
	border-bottom-width: 2px;
}
