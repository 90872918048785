.header {
	box-sizing: border-box;
	margin-top: calc(var(--small-screen-site-header-height) * -1);
	overflow: hidden;
	padding-top: var(--small-screen-site-header-height);
	position: relative;
}

@media (min-width: 1280px) {
	.header {
		margin-top: calc(var(--wide-screen-initial-site-header-height) * -1);
		padding-top: var(--wide-screen-initial-site-header-height);
	}
}

.header.header--has-bg-thumb {
	display: flex;
	align-items: flex-end;
	position: relative;
	min-height: 47.5vw;
}

@media (min-width: 1280px) {
	.header.header--has-bg-thumb {
		min-height: 675px;
	}
}

.header.header--has-dark-bg {
	background-color: var(--color-black);
	color: var(--color-white);
}

.header.header--has-breadcrumb {
	flex-direction: column;
	justify-content: space-between;
}

.header__wrapper {
	padding-top: var(--space-medium);
	padding-bottom: var(--space-medium);
}

@media (min-width: 1280px) {
	.header__wrapper {
		padding-top: var(--space-x-large);
		padding-bottom: var(--space-x-large);
	}
}

.header:not(.header--has-bg-thumb) .header__wrapper {
	position: relative;
}

.header:not(.header--has-bg-thumb) .header__wrapper::before {
	background-color: currentColor;
	content: "";
	opacity: 0.25;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 1px;
}

.header--has-bg-thumb .header__wrapper {
	position: relative;
	z-index: 3;
}

@media (min-width: 1024px) {
	.header--50-50 .header__wrapper,
	.header--66-33 .header__wrapper {
		display: flex;
	}
}

@media (min-width: 1024px) {
	.header--align-top .header__wrapper {
		align-items: flex-start;
	}
}

@media (min-width: 1024px) {
	.header--align-center .header__wrapper {
		align-items: center;
	}
}

@media (min-width: 1024px) {
	.header--align-bottom .header__wrapper {
		align-items: flex-end;
	}
}

.header--has-bg-caption .header__wrapper {
	padding-bottom: var(--space-x-small);
}

@media (min-width: 1280px) {
	.header--has-bg-caption .header__wrapper {
		padding-bottom: var(--space-small);
	}
}

.header__column img {
	/* TODO : @Wout */

	/* Images in 50-50 header had a small gap on the right */
	width: 100%;
}

@media (min-width: 1024px) {
	.header--50-50 .header__column:not(:only-child) {
		flex-basis: calc((100% - var(--space-medium)) / 2);
	}
}

@media (min-width: 1024px) {
	.header--66-33 .header__column:not(:only-child):first-child {
		flex-basis: calc((100% - var(--space-medium)) / 3 * 2);
	}
}

@media (min-width: 1024px) {
	.header--66-33 .header__column:not(:only-child):last-child {
		flex-basis: calc((100% - var(--space-medium)) / 3);
	}
}

@media (max-width: 1023px) {
	.header__column + .header__column {
		margin-top: var(--space-x-small);
	}
}

@media (min-width: 1024px) {
	.header__column + .header__column {
		margin-left: var(--space-medium);
	}
}

.header__title {
	overflow: hidden;
}

@media (min-width: 768px) {
	.header--has-bg-thumb .header__title {
		padding-right: 12.5%;
	}
}

@media (min-width: 1024px) {
	.header--has-bg-thumb .header__title {
		padding-right: 25%;
	}
}

@media (min-width: 1280px) {
	.header--has-bg-thumb .header__title {
		padding-right: 30%;
	}
}

@media (max-width: 1023px) {
	.header__title__label {
		hyphens: auto;
		/* stylelint-disable-next-line declaration-property-value-keyword-no-deprecated */
		word-break: break-word;
	}
}

.header__caption {
	opacity: 0.35;
}

.header__text {
	display: block;
	margin-top: var(--space-x-small);
}

.header__media__caption {
	display: block;
	margin-top: var(--space-x-small);
	opacity: 0.5;
}

.header__meta {
	margin-top: var(--space-xxx-small);
}

.header__meta__group + .header__meta__group {
	margin-top: var(--space-x-small);
}

.header__meta__row {
}

.header__background {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: auto;
	max-width: none;
	height: 100%;
	z-index: 1;
}

@supports (object-fit: cover) {
	.header__background {
		object-fit: cover;
		width: 100%;
	}
}

.header__background-shadow {
	background: linear-gradient(0deg, var(--color-alpha-white-50) 10%, var(--color-alpha-white-0) 45%);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
}

.header--has-dark-bg .header__background-shadow {
	background: linear-gradient(0deg, var(--color-alpha-black-60) 10%, var(--color-alpha-black-0) 45%);
}

/* #region Times + Feedback link */
.header__time-feedback {
	color: var(--color-foreground-dark);
	margin-top: var(--space-x-small);
}

.header__time-feedback a {
	color: inherit;
}

.header__time-feedback a:hover {
	color: var(--color-foreground);
}
/* #endregion Times + Feedback link */

/* see knowledge base hero's */
.header__section-intro {
	padding-top: var(--space-medium);
	padding-bottom: var(--space-medium);
}

@media (min-width: 1024px) {
	.header__section-intro {
		padding-top: var(--space-x-large);
		padding-bottom: var(--space-x-large);
	}
}
