.shortcuts-row:not(.theme-white) {
	padding-top: var(--space-small);
	padding-bottom: var(--space-small);
	position: relative;
}

@media (min-width: 768px) {
	.shortcuts-row:not(.theme-white) {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}
}

@media (min-width: 1024px) {
	.shortcuts-row:not(.theme-white) {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}
}

@media (min-width: 1440px) {
	.shortcuts-row:not(.theme-white) {
		padding-top: 5rem;
		padding-bottom: 5rem;
	}
}

@media (min-width: 1024px) {
	.shortcuts-row__inner {
		display: flex;
		justify-content: flex-end;
		align-items: flex-start;
	}
}

@media (min-width: 1024px) {
	.shortcuts-row__header {
		flex-basis: auto;
		flex-grow: 1;
		flex-shrink: 1;
	}
}

.shortcuts-row__header__title {
	font-weight: 400;
}

@media (min-width: 768px) {
	.shortcuts-row__header__title {
		display: inline-block;
		max-width: 423px;
	}
}

@media (min-width: 1024px) and (max-width: 1439px) {
	.shortcuts-row__header__title {
		max-width: 320px;
	}
}

@media (min-width: 1440px) {
	.shortcuts-row__header__title {
		width: 66.6666%;
	}
}

.shortcuts-row__list {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin: 0;
	padding: 0;
	justify-content: flex-start;
	align-items: stretch;
}

@media (min-width: 1024px) {
	.shortcuts-row__list {
		flex-basis: calc(60% - 0.75rem);
		flex-grow: 0;
		flex-shrink: 0;
	}
}

@media (min-width: 1440px) {
	.shortcuts-row__list {
		flex-basis: calc(50% - 0.75rem);
	}
}

@media (max-width: 1023px) {
	.shortcuts-row__header + .shortcuts-row__list {
		margin-top: var(--space-small);
	}
}

@media (min-width: 1024px) {
	.shortcuts-row__header + .shortcuts-row__list {
		margin-left: var(--space-small);
	}
}

.shortcuts-row__list__item {
	border-bottom: 1px solid var(--color-alpha-foreground-25);
}

@media (max-width: 767px) {
	.shortcuts-row__list__item {
		width: 100%;
	}
}

@media (min-width: 768px) {
	.shortcuts-row__list__item {
		flex-basis: calc(50% - 0.75rem);
		flex-grow: 0;
		flex-shrink: 0;
	}
}

.shortcuts-row__list__item:first-child {
	border-top: 1px solid var(--color-alpha-foreground-25);
}

@media (min-width: 768px) {
	.shortcuts-row__list__item:nth-child(2) {
		border-top: 1px solid var(--color-alpha-foreground-25);
	}
}

@media (min-width: 768px) {
	.shortcuts-row__list__item:nth-child(odd) {
		margin-right: var(--space-x-small);
	}
}

.shortcuts-row__link {
	box-sizing: border-box;
	color: currentColor;
	display: block;
	overflow: hidden;
	padding-top: var(--space-x-small);
	padding-right: var(--space-small);
	padding-bottom: var(--space-x-small);
	position: relative;
	text-decoration: none;
	width: 100%;
}

@media (min-width: 768px) {
	.shortcuts-row__link {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		height: 100%;
	}
}

@media (min-width: 1024px) {
	.shortcuts-row__link {
		padding-top: var(--space-small);
		padding-bottom: var(--space-small);
	}
}

@media (min-width: 1440px) {
	.shortcuts-row__link {
		padding-top: var(--space-medium);
		padding-bottom: var(--space-medium);
	}
}

.shortcuts-row__link .icon {
	display: block;
	fill: currentColor;
	position: absolute;
	top: 50%;
	right: 0;
	transform: translate(0, -50%) translateY(-1px) translateZ(0);
	width: 0.75rem;
	height: 0.75rem;
}

.shortcuts-row__link__title {
	display: inline-block;
	hyphens: auto;
	position: relative;
	word-break: auto-phrase;
}

@media (min-width: 768px) {
	@supports (-webkit-line-clamp: 3) {
		.shortcuts-row__link__title {
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
			display: block;
			display: -webkit-box;
			overflow: hidden;
		}
	}
}

.shortcuts-row__link__title__label {
	border-bottom: 1px solid var(--color-alpha-foreground-0);
	display: inline;
	padding-bottom: 1px;
	transition: border-bottom-color 64ms linear;
}

.shortcuts-row__link:focus .shortcuts-row__link__title__label,
.shortcuts-row__link:hover .shortcuts-row__link__title__label {
	border-bottom-color: var(--color-foreground);
}

@media (prefers-reduced-motion: reduce) {
	.shortcuts-row__link__title__label {
		transition: none;
	}
}
