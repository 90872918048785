/* ⚠️ type styles are set in typography.css */

.button {
	appearance: none;
	background: none;
	background-color: var(--color-alpha-foreground-0);
	border: 1px solid var(--color-foreground);
	border-radius: 0;
	box-shadow: none;
	box-sizing: border-box;
	color: var(--color-foreground);
	display: inline-block;
	outline: 0;
	padding: 0.5rem 0.875rem;
	text-align: left;
	text-decoration: none;
	transition:
		background-color 64ms linear,
		color 64ms linear;
	max-width: 100%;
}

@media (prefers-reduced-motion: reduce) {
	.button {
		transition: none;
	}
}

@media (min-width: 1024px) {
	.button {
		padding-top: 0.4375rem;
		padding-bottom: 0.4375rem;
	}
}

.button:focus,
.button:hover {
	background-color: var(--color-alpha-foreground-100);
	color: var(--color-background);
}

.button:focus {
	outline: 0;
}

.button:hover {
	cursor: pointer;
}

.button.button--inverted {
	background-color: var(--color-foreground);
	border: 1px solid var(--color-foreground);
	color: var(--color-background);
}

.button.button--inverted:focus,
.button.button--inverted:hover {
	background-color: var(--color-background);
	color: var(--color-foreground);
}

.button.button--blurred {
	background-color: var(--color-alpha-foreground-25);
	border: 1px solid currentColor;
	color: currentColor;
}

@supports (backdrop-filter: blur(2px)) {
	.button.button--blurred {
		backdrop-filter: blur(2px);
		background-color: var(--color-alpha-foreground-10);
	}
}

.button.button--blurred:focus,
.button.button--blurred:hover {
	background-color: var(--color-background);
	border-color: var(--color-background);
	color: var(--color-foreground);
}

.button.button--blurred.button--inverted {
	background-color: var(--color-alpha-background-25);
	border: 1px solid var(--color-foreground);
	color: var(--color-foreground);
}

@supports (backdrop-filter: blur(2px)) {
	.button.button--blurred.button--inverted {
		backdrop-filter: blur(2px);
		background-color: var(--color-alpha-background-10);
	}
}

.button.button--blurred.button--inverted:focus,
.button.button--blurred.button--inverted:hover {
	background-color: var(--color-foreground);
	color: var(--color-background);
}

.button.button--small-rounded {
	border-radius: 0.75rem;
	padding: 0.125rem 0.6875rem;
}

@media (min-width: 1024px) {
	.button.button--small-rounded {
		padding-top: 0.0625rem;
		padding-bottom: 0.0625rem;
	}
}

.button.button--secondary {
	padding: 0.4375rem 1rem;
}

@media (max-width: 1023px) {
	.button.button--secondary {
		text-align: center;
		width: 100%;
	}
}

@media (min-width: 1024px) {
	.button.button--secondary {
		padding: 0.625rem 2rem;
	}
}

.button.button--text {
	background: none !important;
	border-color: transparent;
	color: var(--color-foreground-medium);
	padding: 0;
}

.button.button--text:focus,
.button.button--text:hover {
	color: var(--color-foreground);
}

.button.button--round-icon {
	border-color: var(--color-foreground-light);
	border-radius: 3.125rem;
	overflow: hidden;
	position: relative;
	width: 3.125rem;
	height: 3.125rem;
}

.button.button--round-icon:focus,
.button.button--round-icon:hover {
	border-color: var(--color-foreground);
}

.button.button--round-icon.button--inverted {
	border-color: var(--color-foreground);
}

/*
	 * Icon styles
	 */

.button .icon {
	display: inline-block;
	fill: currentColor;
	width: 0.4375rem;
	height: 0.4375rem;
}

@media (min-width: 1024px) {
	.button .icon {
		width: 0.5rem;
		height: 0.5rem;
	}
}

.button.button--text .icon {
	width: 0.625rem;
	height: 0.625rem;
}

@media (min-width: 1024px) {
	.button.button--text .icon {
		width: 0.6875rem;
		height: 0.6875rem;
	}
}

.button.button--text .icon:first-child:not(:only-child) {
	margin-right: 1.5rem;
}

.button.button--text .icon:last-child:not(:only-child) {
	margin-left: 1.5rem;
}

.button.button--secondary .icon {
	width: 0.5625rem;
	height: 0.5625rem;
}

@media (min-width: 1024px) {
	.button.button--secondary .icon:not(:only-child) {
		transform: translateY(-1px);
	}
}

.button.button--secondary .icon:first-child:not(:only-child) {
	margin-right: 0.25rem;
}

.button.button--secondary .icon:last-child:not(:only-child) {
	margin-left: 0.25rem;
}

.button.button--round-icon .icon {
	position: absolute;
	top: 1.0625rem;
	left: 1.0625rem;
	width: 0.875rem;
	height: 0.875rem;
}

.button.button--transparent {
	background-color: transparent;
}

.theme-black .button:not(.button--inverted),
.theme-brown .button:not(.button--inverted),
.theme-crimson .button:not(.button--inverted),
.theme-dark-blue .button:not(.button--inverted),
.theme-dark-green .button:not(.button--inverted),
.theme-light-blue .button:not(.button--inverted),
.theme-light-green .button:not(.button--inverted),
.theme-red .button:not(.button--inverted),
.theme-fuchsia-with-white .button:not(.button--inverted) {
	border: 1px solid var(--color-white);
	color: var(--color-white);
}

.theme-black .button:not(.button--inverted):not(.button--transparent),
.theme-black .button:not(.button--inverted):focus(.button--transparent),
.theme-black .button:not(.button--inverted):hover(.button--transparent),
.theme-brown .button:not(.button--inverted):not(.button--transparent),
.theme-brown .button:not(.button--inverted):focus(.button--transparent),
.theme-brown .button:not(.button--inverted):hover(.button--transparent),
.theme-crimson .button:not(.button--inverted):not(.button--transparent),
.theme-crimson .button:not(.button--inverted):focus(.button--transparent),
.theme-crimson .button:not(.button--inverted):hover(.button--transparent),
.theme-dark-blue .button:not(.button--inverted):not(.button--transparent),
.theme-dark-blue .button:not(.button--inverted):focus(.button--transparent),
.theme-dark-blue .button:not(.button--inverted):hover(.button--transparent),
.theme-dark-green .button:not(.button--inverted):not(.button--transparent),
.theme-dark-green .button:not(.button--inverted):focus(.button--transparent),
.theme-dark-green .button:not(.button--inverted):hover(.button--transparent),
.theme-light-blue .button:not(.button--inverted):not(.button--transparent),
.theme-light-blue .button:not(.button--inverted):focus(.button--transparent),
.theme-light-blue .button:not(.button--inverted):hover(.button--transparent),
.theme-light-green .button:not(.button--inverted):not(.button--transparent),
.theme-light-green .button:not(.button--inverted):focus(.button--transparent),
.theme-light-green .button:not(.button--inverted):hover(.button--transparent),
.theme-red .button:not(.button--inverted):not(.button--transparent),
.theme-red .button:not(.button--inverted):focus(.button--transparent),
.theme-red .button:not(.button--inverted):hover(.button--transparent),
.theme-fuchsia-with-white .button:not(.button--inverted):not(.button--transparent),
.theme-fuchsia-with-white .button:not(.button--inverted):focus(.button--transparent),
.theme-fuchsia-with-white .button:not(.button--inverted):hover(.button--transparent) {
	background-color: inherit;
}

.theme-black .button:not(.button--inverted):focus,
.theme-black .button:not(.button--inverted):hover,
.theme-brown .button:not(.button--inverted):focus,
.theme-brown .button:not(.button--inverted):hover,
.theme-crimson .button:not(.button--inverted):focus,
.theme-crimson .button:not(.button--inverted):hover,
.theme-dark-blue .button:not(.button--inverted):focus,
.theme-dark-blue .button:not(.button--inverted):hover,
.theme-dark-green .button:not(.button--inverted):focus,
.theme-dark-green .button:not(.button--inverted):hover,
.theme-light-blue .button:not(.button--inverted):focus,
.theme-light-blue .button:not(.button--inverted):hover,
.theme-light-green .button:not(.button--inverted):focus,
.theme-light-green .button:not(.button--inverted):hover,
.theme-red .button:not(.button--inverted):focus,
.theme-red .button:not(.button--inverted):hover,
.theme-fuchsia-with-white .button:not(.button--inverted):focus,
.theme-fuchsia-with-white .button:not(.button--inverted):hover {
	background-color: var(--color-white);
	border-color: var(--color-white);
	color: var(--color-black);
}
