.social-navigation {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin: 0;
	padding: 0;
	justify-content: flex-start;
	align-items: center;
}

.social-navigation__toggle {
	display: none;
	pointer-events: none;
}

.social-navigation__item {
	line-height: 1;
	margin-top: 0.625rem;
	margin-bottom: 0.625rem;
}

.social-navigation__item:not(:last-child) {
	margin-right: var(--space-small);
}

.social-navigation__item:first-child {
	order: 1;
}

.social-navigation__item:nth-child(2) {
	order: 2;
}

.social-navigation__item:nth-child(3) {
	order: 3;
}

.social-navigation__item.social-navigation__item--more-toggle {
	order: 4;
}

.social-navigation__item.social-navigation__item--initially-hidden {
	display: none;
	order: 5;
}

#social-navigation-toggle:checked ~ .social-navigation .social-navigation__item.social-navigation__item--initially-hidden {
	display: block;
}

#social-navigation-toggle:checked ~ .social-navigation .social-navigation__item.social-navigation__item--more-toggle {
	display: none;
}

.social-navigation__link {
	display: block;
	transition: opacity 96ms linear;
}

@media (prefers-reduced-motion: reduce) {
	.social-navigation__link {
		transition: none;
	}
}

.social-navigation__link::before {
	background-image: url("../images/otherlink.svg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	content: "";
	display: block;
	width: 17px;
	height: 17px;
}

@media (prefers-color-scheme: dark) {
	.social-navigation__link::before {
		filter: invert(1);
	}
}

.social-navigation__link.social-navigation__link--newsletter::before {
	background-image: url("../images/mail.svg");
}

.social-navigation__link[href*="facebook.com"]::before {
	background-image: url("../images/facebook.svg");
}

.social-navigation__link[href*="instagram.com"]::before {
	background-image: url("../images/instagram.svg");
}

.social-navigation__link[href*="threads.net"]::before {
	background-image: url("../images/threads.svg");
}

.social-navigation__link[href*="linkedin.com"]::before {
	background-image: url("../images/linkedin.svg");
}

.social-navigation__link[href*="blog.kunsten.be"]::before,
.social-navigation__link[href*="medium.com"]::before {
	background-image: url("../images/medium.svg");
}

.social-navigation__link[href*="soundcloud.com"]::before {
	background-image: url("../images/soundcloud.svg");
}

.social-navigation__link[href*="twitter.com"]::before {
	background-image: url("../images/twitter.svg");
}

.social-navigation__link[href*="vimeo.com"]::before {
	background-image: url("../images/vimeo.svg");
}

.social-navigation__link[href*="youtube.com"]::before {
	background-image: url("../images/youtube.svg");
}

.social-navigation__link:focus,
.social-navigation__link:hover {
	opacity: 0.5;
}

.social-navigation__link:focus {
	outline: 0;
}

.social-navigation__link:hover {
	cursor: pointer;
}

.social-navigation__more-toggle {
	display: block;
	font-family: var(--font-sans);
	font-size: 0.75rem;
	font-style: normal;
	font-weight: 400;
	line-height: 17px;
	opacity: 0.5;
	transition: opacity 96ms linear;
	height: 17px;
}

@media (prefers-reduced-motion: reduce) {
	.social-navigation__more-toggle {
		transition: none;
	}
}

@media (min-width: 1440px) {
	.social-navigation__more-toggle {
		font-size: 0.875rem;
	}
}

.social-navigation__more-toggle .icon {
	display: inline-block;
	fill: currentColor;
	margin-left: 0.625rem;
	width: 0.5rem;
	height: 0.5rem;
}

.social-navigation__more-toggle:focus,
.social-navigation__more-toggle:hover {
	opacity: 1;
}

.social-navigation__more-toggle:focus {
	outline: 0;
}

.social-navigation__more-toggle:hover {
	cursor: pointer;
}
