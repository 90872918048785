/*
 * Gutenberg Stylings
 */

.wp-block-image {
	display: block;
	margin-top: 0;
	margin-right: 0;
	margin-bottom: var(--space-medium);
	margin-left: 0;
}

.wp-block-image:last-child {
	margin-bottom: 0;
}

.wp-block-image:only-child {
	margin-bottom: 0;
}

@media (min-width: 768px) {
	.wp-block-image.is-style-xlarge {
		margin-top: var(--space-large);
		margin-bottom: var(--space-large);
	}
}

.wp-block-image figure {
	clear: both;
	margin-top: 0;
	margin-right: 0;
	margin-bottom: var(--space-medium);
	margin-left: 0;
}

@media (min-width: 768px) {
	.wp-block-image figure.alignleft {
		float: left;
		margin-right: var(--space-x-small);
		margin-bottom: var(--space-x-small);
		width: calc(50% - var(--space-x-small));
	}
}

@media (min-width: 768px) {
	.wp-block-image figure.alignright {
		float: right;
		margin-bottom: var(--space-x-small);
		margin-left: var(--space-x-small);
		width: calc(50% - var(--space-x-small));
	}
}

@media (min-width: 768px) {
	.wp-block-image figure.aligncenter {
		margin-right: auto;
		margin-left: auto;
		width: 75%;
	}
}

.wp-block-image img {
	width: 100%;
}

.wp-block-image figcaption,
.wp-block-embed figcaption,
.wp-block-table figcaption {
	/* ⚠️ typography styles defined in typography.css */
	color: var(--color-foreground-medium);
	display: block;
	margin-top: var(--space-xx-small);
}

.wp-block-image figcaption a,
.wp-block-embed figcaption a,
.wp-block-table figcaption a {
	color: var(--color-foreground-dark);
	transition: color 96ms linear;
}

.wp-block-image figcaption a:focus,
.wp-block-image figcaption a:hover,
.wp-block-embed figcaption a:focus,
.wp-block-embed figcaption a:hover,
.wp-block-table figcaption a:focus,
.wp-block-table figcaption a:hover {
	color: var(--color-foreground);
}

.wp-block-embed {
	margin: 0 0 var(--space-medium);
}

.wp-block-embed iframe {
	width: 100%;
	height: auto;
}

.wp-block-embed .issuuembed iframe,
.wp-block-embed.wp-has-aspect-ratio iframe {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
}

.wp-block-embed__wrapper {
	display: block;
	width: 100%;
}

.wp-has-aspect-ratio .wp-block-embed__wrapper {
	position: relative;
}

.wp-embed-aspect-1-1 .wp-block-embed__wrapper {
	padding-top: 100%;
}

.wp-embed-aspect-16-9 .wp-block-embed__wrapper {
	padding-top: 56.25%;
}

.wp-embed-aspect-4-3 .wp-block-embed__wrapper {
	padding-top: 75%;
}

.wp-embed-aspect-21-9 .wp-block-embed__wrapper {
	padding-top: 42.857%;
}

.wp-block-embed__wrapper .issuuembed {
	padding-top: 100%;
	position: relative;
	width: 100% !important;
	height: auto !important;
}

/* Source: https://css-tricks.com/snippets/css/drop-caps/ */
.has-drop-cap::first-letter {
	float: left;
	font-size: 3.125rem;
	line-height: 3rem;
	margin-top: 0.1875rem;
	padding-right: 0.75rem;
}

@media (min-width: 768px) {
	.has-drop-cap::first-letter {
		font-size: 3.5rem;
		line-height: 3.5625rem;
		margin-top: 0.375rem;
	}
}
