.teaser {
	display: block;
	overflow: hidden;
	position: relative;
	width: 100%;
}

@media (min-width: 1024px) {
	.featured-content__main .teaser.teaser--featured {
		height: 100%;
	}
}

.teaser__link {
	color: currentColor;
	display: block;
	text-decoration: none;
	width: 100%;
}

@media (min-width: 1024px) {
	.featured-content__main .teaser--featured .teaser__link {
		height: 100%;
	}
}

.teaser__thumbnail-container {
	background-color: var(--color-alpha-foreground-25);
	margin-bottom: var(--space-xx-small);
	overflow: hidden;
	padding-bottom: 66.6666%;
	position: relative;
	transition: opacity 96ms linear;
	width: 100%;
}

@media (prefers-reduced-motion: reduce) {
	.teaser__thumbnail-container {
		transition: none;
	}
}

.teaser__link:focus .teaser__thumbnail-container,
.teaser__link:hover .teaser__thumbnail-container {
	opacity: var(--teaser-hover-opacity);
}

@media (min-width: 768px) {
	.teaser--featured .teaser__thumbnail-container {
		margin-bottom: 0;
		padding-bottom: 56.25%;
	}
}

@media (min-width: 1024px) {
	.featured-content__main .teaser--featured .teaser__thumbnail-container {
		padding-bottom: 0;
		height: 100%;
	}
}

.teaser__thumbnail {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1;
}

@supports (object-fit: cover) {
	.teaser__thumbnail {
		object-fit: cover;
		height: 100%;
	}
}

@media (min-width: 768px) {
	.teaser--featured .teaser__content {
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		overflow: hidden;
		padding: var(--space-small) 20% var(--space-small) var(--space-small);
		justify-content: flex-end;
		align-items: flex-start;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
	}
}

@media (min-width: 768px) {
	.teaser--featured .teaser__content::after {
		background: linear-gradient(0deg, var(--color-alpha-black-60) 45%, var(--color-alpha-black-0) 77%);
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
	}
}

.teaser__title {
	display: inline-block;
	hyphens: auto;
	word-break: auto-phrase;
}

.teaser__title:not(:last-child) {
	margin-bottom: 0.1875rem;
}

.teaser--featured .teaser__title {
	position: relative;
	width: 100%;
	z-index: 3;
}

/* Designs mix and match type styles here:
		 * → up-to-small → type-f
		 * → from-small  → type-b
		 *
		 * The "type-f"-class is set in the html, so we only need to overwrite
		 * some styles here for the "from-small" breakpoint.
		 */

@media (min-width: 768px) {
	.teaser--featured .teaser__title {
		color: var(--color-white);
		font-size: 1.75rem;
		font-weight: 400;
		line-height: 1.21;
		margin-bottom: 0;
	}
}

@media (min-width: 1024px) {
	.teaser--featured .teaser__title {
		font-size: 2.125rem;
		line-height: 1.355;
	}
}

@media (min-width: 1440px) {
	.teaser--featured .teaser__title {
		font-size: 2.5rem;
		line-height: 1.5;
	}
}

@supports (-webkit-line-clamp: 3) {
	@media (min-width: 768px) {
		.teaser__title {
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
			display: block;
			display: -webkit-box;
			overflow: hidden;
		}
	}
}

.teaser__title__label {
	border-bottom: 1px solid var(--color-alpha-foreground-0);
	display: inline;
	padding-bottom: 1px;
	transition: border-bottom-color 64ms linear;
}

.teaser__link:focus .teaser__title__label,
.teaser__link:hover .teaser__title__label {
	border-bottom-color: var(--color-foreground);
}

@media (prefers-reduced-motion: reduce) {
	.teaser__title__label {
		transition: none;
	}
}

@media (min-width: 768px) {
	.teaser--featured .teaser__title__label {
		border-bottom-color: var(--color-alpha-white-0);
		border-bottom-width: 2px;
	}
}

@media (min-width: 1024px) {
	.teaser--featured .teaser__title__label {
		border-bottom-width: 3px;
	}
}

@media (min-width: 1440px) {
	.teaser--featured .teaser__title__label {
		padding-bottom: 2px;
	}
}

@media (min-width: 768px) {
	.teaser--featured .teaser__link:focus .teaser__title__label,
	.teaser--featured .teaser__link:hover .teaser__title__label {
		border-bottom-color: var(--color-white);
	}
}

.teaser__excerpt {
	opacity: var(--teaser-hover-opacity);
}

.teaser__excerpt:not(:last-child) {
	margin-bottom: 0.25rem;
}

@supports (-webkit-line-clamp: 3) {
	@media (min-width: 768px) {
		.teaser__excerpt {
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
			display: block;
			display: -webkit-box;
			overflow: hidden;
		}
	}
}

.teaser--featured .teaser__excerpt {
	display: none;
}

.teaser__meta__item {
	display: inline-block;
	opacity: 0.5;
	overflow: hidden;
	text-overflow: ellipsis;
	user-select: none;
	white-space: nowrap;
	max-width: 120px;
}

.teaser__meta__item:not(:last-child) {
	margin-right: 0.75rem;
}

@media (min-width: 768px) {
	.teaser--featured .teaser__meta__item {
		display: none;
	}
}

.teaser__video-link {
	background-color: var(--color-alpha-black-60);
	border: 1px solid var(--color-alpha-white-50);
	color: var(--color-white);
	display: inline-flex;
	padding: 0.625rem 0.875rem;
	justify-content: flex-start;
	align-items: center;
	z-index: 2;
}

@supports (backdrop-filter: blur(2px)) {
	.teaser__video-link {
		backdrop-filter: blur(2px);
		background-color: var(--color-alpha-black-10);
	}
}

.teaser__thumbnail-container .teaser__video-link {
	position: absolute;
	bottom: var(--space-xx-small);
	left: var(--space-xx-small);
}

@media (min-width: 768px) {
	.teaser__thumbnail-container .teaser__video-link {
		display: none;
	}
}

.teaser__content .teaser__video-link {
	position: relative;
	z-index: 3;
}

@media (max-width: 767px) {
	.teaser__content .teaser__video-link {
		display: none;
	}
}

@media (min-width: 768px) {
	.teaser__content .teaser__video-link:not(:first-child) {
		margin-top: var(--space-x-small);
	}
}

.teaser__video-link__icon .icon {
	display: inline-block;
	fill: currentColor;
	width: 0.5625rem;
	height: 0.5625rem;
}

.teaser__video-link__label:not(:only-child) {
	margin-left: 0.5625rem;
}
