@media (min-width: 768px) {
	.quote-with-author {
		display: flex;
		margin-top: var(--space-large);
		margin-bottom: var(--space-large);
	}

	.quote-with-author p:last-child {
		margin-bottom: 0;
	}
}

@media (min-width: 768px) {
	.quote-with-author__content {
		flex-grow: 7;
	}
}

@media (min-width: 768px) {
	.quote-with-author__cite {
		border-left: 1px solid var(--color-foreground-x-light);
		flex-basis: 230px;
		flex-grow: 3;
		flex-shrink: 0;
		margin-left: var(--space-x-small);
		padding-left: var(--space-x-small);
	}
}

@media (min-width: 768px) {
	.quote-with-author__cite__wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
	}
}

a.quote-with-author__cite__wrapper {
	color: currentColor;
	text-decoration: none;
}

a.quote-with-author__cite__wrapper:focus {
	outline: 0;
}
