.kunstenpunt-video {
	break-inside: avoid;
}

.kunstenpunt-video:not(:last-child) {
	margin-bottom: var(--space-medium);
}

.kunstenpunt-video__video-container:not(:last-child) {
	margin-bottom: var(--space-xx-small);
}

.kunstenpunt-video__meta {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.kunstenpunt-video__meta__title {
	color: var(--color-alpha-foreground-50);
	display: block;
	flex-basis: auto;
	flex-grow: 0;
	flex-shrink: 1;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.kunstenpunt-video__meta__separator {
	margin-right: var(--space-xx-small);
	margin-left: var(--space-xx-small);
}

.kunstenpunt-video__meta__link {
	color: var(--color-foreground);
	display: block;
	flex-basis: auto;
	flex-grow: 1;
	flex-shrink: 0;
}
