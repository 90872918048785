.table-of-content {
	padding: 1.25rem;
}

@media (prefers-color-scheme: dark) {
	.table-of-content.theme-white {
		background-color: var(--color-theme-grey);
	}
}

.table-of-content__heading {
	font-family: var(--font-mono);
	font-size: 0.8125rem;
	font-weight: 400;
	line-height: 1.54;
	margin-bottom: 1rem;
	text-transform: capitalize;
}

.table-of-content__index {
	font-family: var(--font-sans);
	font-size: 1.125rem;
	letter-spacing: normal;
	line-height: 1.67;
}

.table-of-content ol {
	counter-reset: item;
	padding-left: var(--space-x-small);
}

.table-of-content li {
	display: block;
	position: relative;
}

.table-of-content li::before {
	content: counters(item, ".") ".";
	counter-increment: item;
	margin-right: 10px;
	text-align: left;
}
