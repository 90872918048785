.team-member-header {
	box-sizing: border-box;
	margin-top: calc(var(--small-screen-site-header-height) * -1);
	overflow: hidden;
	padding-top: var(--small-screen-site-header-height);
	position: relative;
}

@media (min-width: 1280px) {
	.team-member-header {
		margin-top: calc(var(--wide-screen-initial-site-header-height) * -1);
		padding-top: var(--wide-screen-initial-site-header-height);
	}
}

.team-member-header__wrapper {
	border-bottom: 1px solid var(--color-alpha-foreground-25);
	padding-top: var(--space-medium);
	padding-bottom: var(--space-medium);
}

@media (min-width: 768px) {
	.team-member-header__wrapper {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
}

@media (min-width: 1280px) {
	.team-member-header__wrapper {
		padding-top: var(--space-x-large);
		padding-bottom: var(--space-x-large);
	}
}

@media (max-width: 767px) {
	.team-member-header__content {
		margin-bottom: var(--space-medium);
	}
}

@media (min-width: 768px) {
	.team-member-header__content {
		flex-basis: calc(50% - var(--space-x-small));
		flex-grow: 0;
		flex-shrink: 0;
		margin-right: var(--space-x-small);
	}
}

.team-member-header__title {
	display: inline-block;
	hyphens: auto;
	margin-bottom: var(--space-xx-small);
	word-break: auto-phrase;
}

@media (min-width: 1024px) {
	.team-member-header__title {
		margin-bottom: var(--space-small);
	}
}

.team-member-header__title__label {
	margin-bottom: 0;
}

@media (min-width: 768px) {
	.team-member-header__thumbnail {
		flex-basis: 50%;
		flex-grow: 0;
		flex-shrink: 0;
		position: relative;
		text-align: right;
	}
}

.team-member-header__thumbnail__toggle-checkbox {
	display: none;
}

.team-member-header__thumbnail__toggle-label {
	background-color: var(--color-theme-dark-green);
	border-radius: 100%;
	display: flex;
	overflow: hidden;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: var(--space-xx-small);
	right: 0;
	width: 3.125rem;
	height: 3.125rem;
	z-index: 2;
}

@media (max-width: 767px) {
	.team-member-header__thumbnail__toggle-label {
		display: none;
	}
}

@media (hover: hover) {
	.team-member-header__thumbnail__toggle-label:focus,
	.team-member-header__thumbnail__toggle-label:hover {
		background-color: var(--color-black);
	}
}

.team-member-header__thumbnail__toggle-label:hover {
	cursor: pointer;
}

.team-member-header__thumbnail__toggle-label .icon {
	display: block;
	fill: var(--color-white);
	width: 0.875rem;
	height: 0.875rem;
}

.team-member-header__thumbnail__asset {
	backface-visibility: hidden;
	display: block;
	position: relative;
	width: 100%;
	z-index: 1;
}

@media (min-width: 768px) {
	.team-member-header__thumbnail__asset {
		background-color: var(--color-alpha-foreground-25);
		display: inline-block;
		margin-right: 1.5625rem;
		transition: width 512ms cubic-bezier(0.9, 0, 0.25, 1); /* width transition is an exception! Can't use scale here */
		width: calc(200px - 1.5625rem);
		min-width: calc(200px - 1.5625rem);
		min-height: calc(200px - 1.5625rem);
	}

	@media (prefers-reduced-motion: reduce) {
		.team-member-header__thumbnail__asset {
			transition: none;
		}
	}
}

@media (min-width: 768px) {
	.team-member-header__thumbnail__toggle-checkbox:checked ~ .team-member-header__thumbnail__asset {
		width: calc(100% - 1.5625rem);
		max-width: none;
	}
}
