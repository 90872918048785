.chapter-progress__label {
	display: inline-block;
	margin-bottom: 0.125rem;
}

.chapter-progress__bar {
	/**
	 * This uses some none-auto-prefixed prefixes.
	 * Just like with ::placeholder, the prefix selector's can't be combined.
	 * Because of this we use some component-specific custom properties so
	 * we can still easily tweak things.
	 */
	--progress-border-radius: 0.75rem;
	--progress-border-radius-from-wide: 1.0625rem;
	--progress-background: var(--color-alpha-foreground-20);
	--progress-foreground: var(--color-foreground);

	appearance: none;
	background: none;
	border: none;
	color: var(--progress-foreground); /* IE uses these for styling. */
	overflow: hidden;
	width: 100%;
	height: 0.75rem;
}

@media (min-width: 1024px) {
	.chapter-progress__bar {
		height: 1.0625rem;
	}
}

/* Webkit browsers (selector can't combine prefixes). */

.chapter-progress__bar::-webkit-progress-bar,
.chapter-progress__bar::-webkit-progress-value {
	border-radius: var(--progress-border-radius);
}

@media (min-width: 1024px) {
	.chapter-progress__bar::-webkit-progress-bar,
	.chapter-progress__bar::-webkit-progress-value {
		border-radius: var(--progress-border-radius-from-wide);
	}
}

.chapter-progress__bar::-webkit-progress-bar {
	background-color: var(--progress-background);
}

.chapter-progress__bar::-webkit-progress-value {
	background-color: var(--color-foreground);
}

/* Firefox. */

.chapter-progress__bar::-moz-progress-bar,
.chapter-progress__bar::-moz-progress-value {
	border-radius: var(--progress-border-radius);
}

@media (min-width: 1024px) {
	.chapter-progress__bar::-moz-progress-bar,
	.chapter-progress__bar::-moz-progress-value {
		border-radius: var(--progress-border-radius-from-wide);
	}
}

.chapter-progress__bar::-moz-progress-bar {
	background-color: var(--progress-background);
}

.chapter-progress__bar::-moz-progress-value {
	background-color: var(--color-foreground);
}
