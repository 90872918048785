.top-scroll-waypoint {
	display: block;
	opacity: 0;
	pointer-events: none;
	position: absolute;
	bottom: 0; /* Place at bottom of parent element */
	left: 0;
	width: 4rem;
	height: 4rem;
	z-index: 0;
}

body > .top-scroll-waypoint {
	/* If direct child of body, place at top of page */
	top: 0;
	bottom: auto;
}
