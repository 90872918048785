.type-i {
	font-family: var(--font-mono);
	font-size: 0.75rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
}

@media (min-width: 1024px) {
	.type-i {
		font-size: 0.8125rem;
		line-height: 1.54;
	}
}
