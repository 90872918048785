.checklist-question__header {
	appearance: none;
	background: none;
	border: 0;
	box-shadow: none;
	cursor: pointer;
	display: flex;
	padding: 0;
	justify-content: flex-start;
	align-items: flex-start;
	text-align: left;
	width: 100%;
}

@media (max-width: 1279px) {
	.checklist-question__header {
		flex-wrap: wrap;
	}
}

.checklist-question[data-toggled] .checklist-question__header {
	margin-bottom: 2.25rem;
}

@media (min-width: 1280px) {
	.checklist-question[data-toggled] .checklist-question__header {
		margin-bottom: 3.125rem;
	}
}

.checklist-question__header:focus:not(:focus-visible) {
	outline: 0;
}

.checklist-question__header > * {
	pointer-events: none;
}

.checklist-question__title-container {
	flex-basis: 100%;
	flex-shrink: 0;
	margin-bottom: 0;
}

@media (min-width: 768px) and (max-width: 1279px) {
	.checklist-question__title-container {
		padding-right: var(--space-x-large);
	}
}

@media (max-width: 1279px) {
	.checklist-question__title-container {
		margin-bottom: 0.5rem;
	}
}

@media (min-width: 1280px) {
	.checklist-question__title-container {
		flex-basis: auto;
		flex-grow: 1;
		margin-right: var(--space-small);
	}
}

.checklist-question__title {
	display: inline-block;
	text-transform: none;
	width: 100%;
	max-width: 562px;
}

@media (min-width: 1280px) {
	.checklist-question__title {
		display: flex;
	}
}

@media (min-width: 1280px) {
	.checklist-question__title > br {
		display: none;
	}
}

@media (min-width: 1280px) {
	.checklist-question__title__index {
		margin-right: 5.625rem;
		transform: translateY(0.375rem);
	}
}

@media (min-width: 1280px) {
	.checklist-question__status,
	.checklist-question__toggle-icon-container {
		transform: translateY(0.5rem);
	}
}

.checklist-question__status {
	flex-basis: 13.1875rem;
	flex-shrink: 0;
	margin-right: var(--space-xx-small);
	position: relative;
}

@media (min-width: 1280px) {
	.checklist-question__status {
		flex-basis: 19.5rem;
		margin-right: 7.5rem;
	}
}

.checklist-question__status__done,
.checklist-question__status__not-done {
	position: absolute;
	top: 0;
	left: 0;
	transition:
		opacity 192ms linear 160ms,
		transform 480ms cubic-bezier(0.65, 0, 0.05, 1);
	width: 100%;
}

.checklist-question__status__done {
	opacity: 0;
	transform: translateY(-0.5rem);
}

.checklist-question[data-done] .checklist-question__status__done {
	opacity: 1;
	transform: translateY(0);
}

.checklist-question__status__not-done {
	opacity: 0;
	transform: translateY(0.5rem);
}

.checklist-question:not([data-done]) .checklist-question__status__not-done {
	opacity: 1;
	transform: translateY(0);
}

.checklist-question__toggle-icon-container {
	transform: translateY(-0.5rem);
}

@media (max-width: 1279px) {
	.checklist-question__toggle-icon-container {
		flex-grow: 1;
		text-align: right;
	}
}

@media (min-width: 1280px) and (max-width: 1439px) {
	.checklist-question__toggle-icon-container {
		transform: none;
	}
}

.checklist-question__toggle-icon {
	background-color: transparent;
	border-radius: 100%;
	color: currentColor;
	display: inline-block;
	position: relative;
	transition: transform 320ms cubic-bezier(0.65, 0, 0.1, 1);
	width: 2.25rem;
	height: 2.25rem;
}

@media (min-width: 1440px) {
	.checklist-question__toggle-icon {
		width: 3.125rem;
		height: 3.125rem;
	}
}

.checklist-question__toggle-icon > span {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.checklist-question__toggle-icon .icon {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) translateY(1px);
	width: 33.3333%;
	height: 33.3333%;
}

.checklist-question[data-toggled] .checklist-question__toggle-icon {
	transform: rotate(180deg);
}

.checklist-question:not([data-toggled]) .checklist-question__answers {
	overflow: hidden;
	height: 0;
}
