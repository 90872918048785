@media (max-width: 1279px) {
	.filter {
		border: 1px solid currentColor;
	}
}

@media (max-width: 1279px) {
	.filter + .filter {
		border-top-width: 0;
	}
}

@media (min-width: 1280px) {
	.filter + .filter {
		margin-top: var(--space-medium);
	}
}

.filter__toggle {
	display: block;
	padding: 0.625rem 2rem;
	position: relative;
}

@media (min-width: 1280px) {
	.filter__toggle {
		display: none;
	}
}

.filter__checkbox:checked + .filter__toggle {
	border-bottom: 1px solid currentColor;
}

.filter__toggle .icon-check-top,
.filter__toggle .icon-check-down {
	display: none;
	position: absolute;
	top: 50%;
	left: 0.75rem;
	transform: translateY(-50%);
	width: 0.5rem;
	height: 0.5rem;
}

.filter__checkbox:checked + .filter__toggle .icon-check-top,
.filter__checkbox:not(:checked) + .filter__toggle .icon-check-down {
	display: block;
}

@media (max-width: 1279px) {
	.filter__heading {
		display: none;
	}
}

.filter__wrapper {
	list-style: none;
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 0;
}

@media (max-width: 1279px) {
	.filter__wrapper {
		display: none;
	}
}

@media (max-width: 1279px) {
	.filter__checkbox:checked ~ .filter__wrapper {
		display: block;
	}
}

.filter__link {
	color: currentColor;
	display: block;
	text-decoration: none;
}

@media (max-width: 1279px) {
	.filter__link {
		padding: 0.625rem;
	}
}

@media (max-width: 1279px) {
	.filter__link:focus,
	.filter__link:hover,
	.filter__item--is-active .filter__link {
		background-color: var(--color-background-light);
	}
}

.filter__link__label {
	display: inline-block;
	position: relative;
	transform: translateX(0) translateZ(0);
	transition: transform 288ms cubic-bezier(0.715, 0, 0.025, 1);
}

@media (prefers-reduced-motion: reduce) {
	.filter__link__label {
		transition: none;
	}
}

@media (min-width: 1280px) {
	.filter__link__label::before {
		content: "■ ";
		display: inline-block;
		font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
		font-size: 0.8125rem;
		font-style: normal;
		font-weight: 400;
		line-height: 1.54;
		opacity: 0;
		position: absolute;
		top: 0;
		left: -1rem;
		transition: opacity 112ms linear 32ms;
	}
}

@media (prefers-reduced-motion: reduce) {
	.filter__link__label::before {
		content: none;
		display: none;
	}
}

@media (min-width: 1280px) {
	.filter__link:focus .filter__link__label,
	.filter__link:hover .filter__link__label,
	.filter__wrapper:not(:hover):not(:focus) .filter__item--is-active .filter__link .filter__link__label {
		transform: translateX(1rem) translateZ(0);
	}
}

@media (prefers-reduced-motion: reduce) and (min-width: 1280px) {
	.filter__link:focus .filter__link__label,
	.filter__link:hover .filter__link__label,
	.filter__wrapper:not(:hover):not(:focus) .filter__item--is-active .filter__link .filter__link__label {
		text-decoration: underline;
		transform: translateX(0) translateZ(0);
	}
}

@media (min-width: 1280px) {
	.filter__link:focus .filter__link__label::before,
	.filter__link:hover .filter__link__label::before,
	.filter__wrapper:not(:hover):not(:focus) .filter__item--is-active .filter__link .filter__link__label::before {
		opacity: 1;
		transition: opacity 96ms linear;
	}
}
